import styled from '@emotion/styled';

import { colors } from 'utils/colors';
import { parseStyledOptions } from 'utils/styled';

import { ReactComponent as ArrowRightIconRaw } from 'assets/icons/arrow-right-sharp.svg';

export const Root = styled(
  'ul',
  parseStyledOptions({ label: 'Breadcrumbs-Root' })
)`
  color: ${colors.gray};
  display: flex;
  gap: 11px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
`;

export const Item = styled(
  'li',
  parseStyledOptions({ label: 'Breadcrumbs-Item' })
)<{
  $canShrink?: boolean;
}>`
  flex-shrink: ${({ $canShrink }) => ($canShrink ? '1' : '0')};
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
`;

export const Link = styled(
  'a',
  parseStyledOptions({ label: 'Breadcrumbs-Link' })
)<{
  $disabled?: boolean;
  $highlight?: boolean;
  $isAccentGreen?: boolean;
  $isInlineBlock?: boolean;
}>`
  width: 100%;
  overflow: hidden;
  display: ${({ $isInlineBlock }) =>
    $isInlineBlock ? 'inline-block' : 'flex'};
  gap: 11px;
  align-items: center;
  text-overflow: ellipsis;
  ${({ $disabled }) => ($disabled ? 'pointer-events: none;' : '')}
  ${({ $highlight, $isAccentGreen }) => {
    if ($isAccentGreen && $highlight) {
      return `color: ${colors.black}`;
    }

    if ($highlight) {
      return `color: ${colors.darkBlack}`;
    }

    if ($isAccentGreen) {
      return `color: ${colors.grayDark}`;
    }

    return '';
  }}
`;

export const ArrowRightIcon = styled(
  ArrowRightIconRaw,
  parseStyledOptions({ label: 'Breadcrumbs-ArrowRightIcon' })
)``;
