import { Components, selectClasses, inputClasses } from '@mui/material';

import { colors } from 'utils/colors';

// import ChevronIcon from 'static/icon/chevron.svg';

export const MuiSelectTheme: Components['MuiSelect'] = {
  // defaultProps: {
  //   IconComponent: ChevronIcon,
  // },
  styleOverrides: {
    select: {
      ':focus': {
        background: 'transparent',
      },

      [`&.${selectClasses.select}.${inputClasses.input}`]: {
        paddingRight: '46px',
      },
    },
    icon: ({ ownerState }) => ({
      color: ownerState?.['aria-invalid'] === true ? colors.red : colors.black,
      transform: 'rotateZ(180deg)',
      transition: 'transform 0.1s ease-in',
    }),
    iconOpen: {
      transform: 'rotateZ(0deg)',
    },
  },
};
