import React from 'react';
import { Lines1, Lines2, Root, Wrapper } from './RootCustom.styled';
interface RootCustom {
  children?: React.ReactNode;
}
const RootCustom: React.FC<RootCustom> = ({ children }) => {
  return (
    <Root>
      <Lines1 />
      <Lines2 />
      <Wrapper>{children}</Wrapper>
    </Root>
  );
};

export default RootCustom;
