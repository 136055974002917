import { Components } from '@mui/material';

import { colors } from 'utils/colors';

export const MuiInputBaseTheme: Components['MuiInputBase'] = {
  styleOverrides: {
    root: {
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '20px',
      color: colors.black,
    },
    input: ({ ownerState }) => ({
      padding: '11px 16px',
      height: 'auto',
      ...(ownerState.error ? { color: colors.red } : {}),

      '::placeholder': {
        opacity: 1,
      },
    }),
  },
};
