import styled from '@emotion/styled';
import { parseStyledOptions } from '../../utils/styled';
import { colors } from '../../utils/colors';
import { Paragraph } from '../SectionTypography/styled';
import { ButtonTransparent } from '../../styles/Global.styled';
import { Button } from '@mui/material';

export const Root = styled(
  'div',
  parseStyledOptions({ label: 'TxHash-Root' }),
)``;

export const Label = styled(
  Paragraph,
  parseStyledOptions({ label: 'TxHash-Label' }),
)`
    font-size: 12px;
    line-height: 20px;
    color: ${colors.black};
    margin-bottom: 12px;
`;

export const Wrapper = styled(
  'div',
  parseStyledOptions({ label: 'TxHash-Wrapper' }),
)`
    display: flex;
    align-items: center;
`;

export const Hash = styled(
  Paragraph,
  parseStyledOptions({ label: 'TxHash-Hash' }),
)`
    font-size: 12px;
    line-height: 20px;
    color: ${colors.black};
    flex: 1;
    padding: 14px 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    background: ${colors.extraLightGray};
`;

export const CopyButton = styled(
  Button,
  parseStyledOptions({ label: 'TxHash-CopyButton' }),
)`
    padding: 13px;
    border: solid 1px ${colors.black};
    background: ${colors.black};
    min-width: 0;

    &:hover,
    &:active {
        background: ${colors.white};

        svg {
            path {
                fill: ${colors.black};
            }
        }
    }

    svg {
        width: 20px;
        height: 20px;
    }
`;
