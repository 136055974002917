import styled from '@emotion/styled';
import { parseStyledOptions } from '../../utils/styled';
import { colors } from '../../utils/colors';
import { Button, Input, TextField } from '@mui/material';
import { FONT_FAMILY } from '../../utils/consts';

export const Root = styled(
  'div',
  parseStyledOptions({ label: 'InputHEDIAmount-Root' })
)``;

export const TitleWrapper = styled(
  'div',
  parseStyledOptions({ label: 'InputHEDIAmount-TitleWrapper' })
)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

export const Label = styled(
  'p',
  parseStyledOptions({ label: 'InputHEDIAmount-Label' })
)`
  font-size: 12px;
  line-height: 20px;
  color: ${colors.black};
`;

export const MaxBtn = styled(
  Button,
  parseStyledOptions({ label: 'InputHEDIAmount-MaxBtn' })
)`
  font-size: 10px;
  line-height: 16px;
  color: ${colors.black};
  text-transform: uppercase;
  font-weight: 600;
  background: transparent;
  border: solid 1px ${colors.black};
  padding: 6px 30px;

  &:hover {
    color: ${colors.white};
    background-color: ${colors.black};
  }
`;

export const InputWrapper = styled(
  'div',
  parseStyledOptions({ label: 'InputHEDIAmount-InputWrapper' })
)`
  display: flex;
  align-items: center;
  background: ${colors.extraLightGray};
`;

export const SelectBtn = styled(
  Button,
  parseStyledOptions({ label: 'InputHEDIAmount-SelectBtn' })
)`
  padding: 8px;
  background: ${colors.black};
  min-width: 48px;

  svg {
    width: 32px;
    height: 32px;
  }
`;
export const SelectInput = styled(
  TextField,
  parseStyledOptions({ label: 'InputHEDIAmount-Input' })
)`
  flex: 1;
  height: 100%;
  padding: 0;

  input {
    padding: 8px;
    text-align: center;
    font-size: 28px;
    line-height: 32px;
    color: ${colors.black};
    font-family: ${FONT_FAMILY.secondary};

    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &::placeholder {
      color: ${colors.gray};
    }
  }

  & > ::before {
    display: none;
  }
`;
