import { Components } from '@mui/material';

export const MuiMenuTheme: Components['MuiMenu'] = {
  styleOverrides: {
    list: { padding: 0 },
    paper: {
      borderRadius: 0,
      boxShadow: 'none',
    },
  },
};
