import { colors } from 'utils/colors';
import styled from '@emotion/styled';
import { parseStyledOptions } from 'utils/styled';
import { ReactComponent as HackenMobLogo } from 'assets/icons/mob-logo.svg';
import { ReactComponent as HackenMobNavLogo } from 'assets/icons/hacken-logo-nav.svg';
import { FONT_FAMILY } from '../../../utils/consts';
import { ListItem } from '@mui/material';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';

type NavProps = {
  $open: boolean;
};

const commonNavLinkItemStyle = css`
  color: ${colors.black};
  background-color: ${colors.extraLightGray};
  padding: 16px;
  margin-bottom: 16px;
  cursor: pointer;
  transition:
    250ms ease-in-out background-color,
    250ms ease-in-out color;

  &:hover {
    color: ${colors.white};
    background-color: ${colors.blue};
  }

  @media screen and (min-width: 900px) {
    padding: 8px 16px;
    background-color: transparent;
    margin-bottom: 0;
    width: auto;

    &:hover {
      color: ${colors.black};
      background-color: ${colors.white};
    }
  }
`;

export const NavBar = styled(
  'nav',
  parseStyledOptions({ label: 'Header-NavBar' })
)<NavProps>`
  display: flex;
  align-items: center;
  height: 48px;
  padding: 12px 0;

  @media screen and (min-width: 900px) {
    padding: 0;
    height: 74px;
  }
  @media screen and (max-width: 899px) {
    transition: background-color 250ms ease-in-out;
    background-color: ${({ $open }) =>
      $open ? colors.white : colors.accentGreen};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    border-bottom-width: ${({ $open }) => ($open ? '1px' : '0')};
    border-bottom-style: solid;
    border-bottom-color: ${colors.additionalGray};
    justify-content: space-between;
  }
`;
export const HeaderBlock = styled(
  'header',
  parseStyledOptions({ label: 'Header-Header' })
)`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: ${colors.accentGreen};
  border-bottom: 1px solid ${colors.accentLightGreen};

  @media screen and (max-width: 899px) {
    margin-bottom: 47px;
  }
`;
export const Container = styled(
  'div',
  parseStyledOptions({ label: 'Header-Container' })
)`
  margin: 0 auto;
  padding: 0 16px;

  @media screen and (min-width: 1440px) {
    max-width: 1440px;
    padding: 0 96px;
  }
`;
export const LogoLink = styled(
  Link,
  parseStyledOptions({ label: 'Header-LogoLink' })
)`
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 100;
  width: fit-content;
  cursor: pointer;
`;

export const LogoHackenWrapper = styled(
  'span',
  parseStyledOptions({
    label: 'Header-LogoHackenWrapper',
  })
)`
    display: flex;
    align-items: center;
    width: 170px;

    @media screen and (max-width: 899px) {
        display: none;
    }

    @media screen and (min-width: 1025px) {
        width: 220px;
        margin-left: -8px;
    }
`;

export const LogoHackenMob = styled(
  HackenMobLogo,
  parseStyledOptions({
    label: 'Header-LogoHackenMob',
  })
)<NavProps>`
  opacity: ${({ $open }) => ($open ? 0 : 1)};
  margin-left: 16px;
  @media screen and (min-width: 900px) {
    display: none;
  }
`;

export const LogoHackenMobNav = styled(
  HackenMobNavLogo,
  parseStyledOptions({
    label: 'Header-LogoHackenMobNav',
  })
)<NavProps>`
  position: absolute;
  left: 16px;
  transition: 250ms ease-in-out opacity;
  opacity: ${({ $open }) => ($open ? 1 : 0)};

  @media screen and (min-width: 900px) {
    display: none;
  }
`;

export const ToggleButton = styled(
  'button',
  parseStyledOptions({ label: 'Header-ToggleButton' })
)`
  position: absolute;
  right: 16px;
  transform: translateY(-50%);
  top: 50%;
  z-index: 100;
  border: none;
  outline: none;
  background-color: transparent;

  @media screen and (min-width: 900px) {
    display: none;
  }
`;

export const NavTrigger = styled(
  'div',
  parseStyledOptions({ label: 'Header-NavTrigger' })
)<NavProps>`
  width: 24px;
  height: 20px;
  position: relative;
  transform: rotate(0deg);
  cursor: pointer;

  & span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: ${colors.black};
    left: 0;
    transition: 250ms ease-in-out;

    &:nth-of-type(1) {
      top: ${({ $open }) => ($open ? '9px' : '4px')};
      transform: ${({ $open }) => ($open ? 'rotate(45deg)' : 'rotate(0)')};
    }

    &:nth-of-type(2) {
      bottom: ${({ $open }) => ($open ? '9px' : '4px')};
      transform: ${({ $open }) => ($open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;

export const NavWrapper = styled(
  'div',
  parseStyledOptions({ label: 'Header-NavWrapper' })
)<NavProps>`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100vh;

  padding-top: 48px;

  transition:
    250ms ease-in-out opacity,
    250ms ease-in-out visibility;
  opacity: ${({ $open }) => ($open ? 1 : 0)};
  visibility: ${({ $open }) => ($open ? 'visible' : 'hidden')};
  background-color: ${colors.white};

  display: flex;
  flex-direction: column;
  overflow-y: auto;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (min-width: 900px) {
    overflow-y: unset;
    position: static;
    height: unset;
    width: 100%;
    opacity: 1;
    visibility: visible;
    padding-top: 0;
    background-color: transparent;
    margin: 0 10px;
  }
`;

export const NavLinksList = styled(
  'ul',
  parseStyledOptions({
    label: 'Header-NavLinksList',
  })
)`
  flex: 1;
  padding: 24px 16px 0;

  @media screen and (min-width: 900px) {
    padding: 0;
    border-top: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const NavFooter = styled(
  'div',
  parseStyledOptions({
    label: 'Header-NavFooter',
  })
)`
  padding: 16px 0;
  text-align: center;

  font-family: ${FONT_FAMILY.primary};
  font-size: 12px;
  line-height: 20px;
  color: ${colors.grayDark};

  @media screen and (min-width: 900px) {
    display: none;
  }
`;

export const NavLinksItem = styled(
  ListItem,
  parseStyledOptions({ label: 'Header-NavLinksItem' })
)`
  ${commonNavLinkItemStyle};
  padding: 0;

  @media screen and (min-width: 900px) {
    padding: 0;
  }
`;
export const NavText = styled(
  Link,
  parseStyledOptions({ label: 'Header-NavText' })
)`
  font-family: ${FONT_FAMILY.secondary};
  font-size: 24px;
  line-height: 30px;

  @media screen and (min-width: 900px) {
    font-family: ${FONT_FAMILY.primary};
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
  }
`;

export const NavLink = styled(
  NavText,
  parseStyledOptions({ label: 'Header-NavLink' })
)`
  padding: 16px;
  flex: 1;

  @media screen and (min-width: 900px) {
    padding: 8px 16px;
  }
`;
