import styled from '@emotion/styled';

import { parseStyledOptions } from 'utils/styled';
import { colors } from 'utils/colors';

import { FONT_FAMILY } from 'utils/consts';

export const H1 = styled('h1', parseStyledOptions({ label: 'H1' }))`
  font-family: ${FONT_FAMILY.secondary};
  font-weight: 400;
  font-size: 40px;

  line-height: 1.29;

  color: ${colors.black};

  @media screen and (min-width: 768px) {
    font-size: 40px;
    line-height: 1.2;
  }
`;
export const H2 = styled('h2', parseStyledOptions({ label: 'H2' }))`
  font-family: ${FONT_FAMILY.secondary};
  font-weight: 400;
  font-size: 28px;

  line-height: 1.29;

  color: ${colors.black};

  @media screen and (min-width: 768px) {
    font-size: 40px;
    line-height: 1.2;
  }
`;

export const H2Positioned = styled(H2, parseStyledOptions({ label: 'H2' }))`
  margin: 0 auto;
  max-width: 1440px;
  width: 100%;
  padding: 48px 16px;

  @media screen and (min-width: 768px) {
    padding: 96px;
  }
`;

export const H3 = styled('h3', parseStyledOptions({ label: 'H3' }))`
  font-family: ${FONT_FAMILY.secondary};
  font-weight: 400;
  font-size: 28px;

  line-height: 1.29;

  color: ${colors.black};
`;

export const Paragraph = styled(
  'p',
  parseStyledOptions({ label: 'Paragraph' })
)`
  font-family: ${FONT_FAMILY.primary};
  font-weight: 400;
  font-size: 16px;
  line-height: 1.62;

  color: ${colors.black};
`;
export const SubParagraph = styled(
  'p',
  parseStyledOptions({ label: 'Paragraph' })
)`
  font-family: ${FONT_FAMILY.primary};
  font-size: 20px;
  line-height: 32px;
  color: ${colors.black};
  font-weight: 600;
  margin: 0 auto;
  max-width: 1440px;
`;

export const H4 = styled('h4', parseStyledOptions({ label: 'H4' }))`
  font-family: ${FONT_FAMILY.secondary};
  font-weight: 400;
  font-size: 24px;

  line-height: 1.25;

  color: ${colors.black};
`;

export const SecTitle = styled(
  'h1',
  parseStyledOptions({ label: 'ServicesSection-SecTitle' })
)`
  font-size: 40px;
  font-weight: 300;
  font-family: ${FONT_FAMILY.secondary};
  line-height: 48px;
  color: ${colors.black};
`;
