import React, { Fragment } from 'react';
import {
  BenefitsContent,
  BenefitsContentDescription,
  BenefitsContentTitle,
  BenefitsContentWrapper,
  BenefitsImage,
} from './SectionBenefits.styled';
import GrowthImg from '../../assets/images/growth.png';
import { ContentBorder, SectionWrapper } from '../../screens/main/Main.styled';
import CapitalizeImg from '../../assets/images/capitalize.png';
import InvestmentImg from '../../assets/images/investment.png';
import TokenImg from '../../assets/images/token.png';
import DicountImg from '../../assets/images/discount.png';

const benefitsList = [
  {
    title: 'Growth Opportunity',
    description:
      'HEDI is a perpetual options contract on $HAI backed by stableprice tier 1 cybersecurity services. HEDI directly links Hacken B2B revenue and native $HAI token capitalization growth.',
    img: GrowthImg,
  },
  {
    title: 'Price protection',
    description:
      'The invested amount can be reinvested or exchanged for tier 1 web3 cybersecurity services anytime.',
    img: DicountImg,
  },
  {
    title: 'Algorithmic liquidity and capitalization growth',
    description:
      '67% of HEDI sales proceeds automatically repurchase $HAI from the market, with the remaining 33% being deposited into AMM HAI/USDT liquidity pool.',
    img: CapitalizeImg,
  },
  {
    title: 'Special discounts',
    description:
      'Priority queues, special discounts, and free consulting are granted to clients and investors who have prepaid all Hacken services and products.',
    img: InvestmentImg,
  },
  {
    title: 'Exposure in $HAI token',
    description:
      'The invested amount can be swapped into $HAI token in 1 month with a 10% options execution price, which is automatically burned.',
    img: TokenImg,
  },
];
const SectionBenefits: React.FC = () => {
  return (
    <SectionWrapper id={'benefits'}>
      {benefitsList.map((benefit, idx) => (
        <Fragment key={`benefit-${idx}`}>
          {idx !== 0 && <ContentBorder />}
          <BenefitsContentWrapper key={idx}>
            <BenefitsImage src={benefit.img} alt={benefit.title} />
            <BenefitsContent>
              <BenefitsContentTitle>{benefit.title}</BenefitsContentTitle>
              <BenefitsContentDescription>
                {benefit.description}
              </BenefitsContentDescription>
            </BenefitsContent>
          </BenefitsContentWrapper>
        </Fragment>
      ))}
    </SectionWrapper>
  );
};

export default SectionBenefits;
