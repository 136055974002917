import sbjs from 'sourcebuster';

import { createOrganization } from 'queries/organizations';
import { createPerson, PlatformNames } from 'queries/person';
import {
  createDeal,
  sendDealCreatedAnalytics,
  saveDeal,
  DEAL_KEYS_MAP,
} from 'queries/deal';
import { capitalize, capitalizeName } from 'utils/misc';

import { useSessionMeta } from './useSessionMeta';

export type RequestQuoteButtonFormNodeDeps = {
  toggleSuccessScreen?: (v: boolean) => void;
  handleReCaptchaVerify?: () => Promise<string | undefined>;
};

export type RequestQuoteFormValues = {
  name: string;
  platformName: PlatformNames;
  platformNickname: string;
  service: number[];
  email: string;
  details: string;
  policyAgreement: boolean;
  subscription: boolean;
};

export const useRequestQuote = () => {
  const getSessionMeta = useSessionMeta();

  const onSubmit =
    ({
      toggleSuccessScreen,
      handleReCaptchaVerify,
    }: RequestQuoteButtonFormNodeDeps) =>
    async (values: RequestQuoteFormValues) => {
      try {
        await handleReCaptchaVerify?.();

        const [, organizationDomain] = values.email.split('@');
        const organizationName = capitalize(organizationDomain.split('.')[0]);
        const name = capitalizeName(values.name);

        const orgRes = await createOrganization({
          payload: { name: organizationName, text: organizationDomain },
        });

        const personRes = await createPerson({
          payload: {
            orgId: orgRes.data.data.id,
            email: values.email,
            name,
            platformName: values.platformName,
            platformNickname: values.platformNickname,
          },
        });

        const { sessionId, gclid, clientId } = getSessionMeta();

        const dealRes = await createDeal({
          payload: {
            title: `Company: ${organizationName}, Name: ${name}`,
            orgId: orgRes.data.data.id,
            personId: personRes.data.data.id,
            services: values.service,
            comment: values.details,
            utmTerm: sbjs.get.current.trm,
            utmSource: sbjs.get.current.src,
            utmMedium: sbjs.get.current.mdm,
            utmContent: sbjs.get.current.cnt,
            utmCampaign: sbjs.get.current.cmp,
            userIp: sbjs.get.udata.uip,
            userAgent: sbjs.get.udata.uag,
            sessionId,
            gclid,
            clientId,
            pageLocation: sbjs.get.session.cpg,
          },
        });

        if (dealRes.data.success) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'formSubmitSuccess',
            formType: 'request-form',
          });

          try {
            await sendDealCreatedAnalytics({
              payload: {
                id: dealRes.data.data.id,
                createdAt: dealRes.data.data.add_time,
                wwwNeed: dealRes.data.data[DEAL_KEYS_MAP.services],
                email: values.email,
                utmTerm: dealRes.data.data[DEAL_KEYS_MAP.utmTerm],
                utmSource: dealRes.data.data[DEAL_KEYS_MAP.utmSource],
                utmMedium: dealRes.data.data[DEAL_KEYS_MAP.utmMedium],
                utmContent: dealRes.data.data[DEAL_KEYS_MAP.utmContent],
                utmCampaign: dealRes.data.data[DEAL_KEYS_MAP.utmCampaign],
                userIp: dealRes.data.data[DEAL_KEYS_MAP.userIp],
                userAgent: dealRes.data.data[DEAL_KEYS_MAP.userAgent],
                sessionId: dealRes.data.data[DEAL_KEYS_MAP.sessionId],
                gclid: dealRes.data.data[DEAL_KEYS_MAP.gclid],
                clientId: dealRes.data.data[DEAL_KEYS_MAP.clientId],
                orgId: dealRes.data.data.org_id.value,
                personId: dealRes.data.data.person_id.value,
                pageLocation: dealRes.data.data[DEAL_KEYS_MAP.pageLocation],
              },
            });
          } catch (e) {
            console.error(e);
          }
        }

        await saveDeal({
          payload: {
            name,
            nickname: values.platformNickname,
            phone: values.platformNickname,
            messenger: values.platformName,
            selectedServices: values.service.join(),
            email: values.email,
            projectDetails: values.details,
            utmSource: sbjs.get.current.src,
            utmMedium: sbjs.get.current.mdm,
            pageLocation: sbjs.get.session.cpg,
            status: 'failed',
          },
        });

        toggleSuccessScreen?.(true);
      } catch (e) {
        console.error(e);
      }
    };

  return { onSubmit };
};
