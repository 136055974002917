import styled from '@emotion/styled';
import { parseStyledOptions } from '../../utils/styled';
import { MenuItem, Select } from '@mui/material';
import { Paragraph } from '../SectionTypography/styled';
import { colors } from '../../utils/colors';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down.svg';

export const Container = styled(
  'div',
  parseStyledOptions({ label: 'SelectHEDI-Container' })
)``;

export const SelectGroup = styled(
  Select,
  parseStyledOptions({ label: 'SelectHEDI-SelectGroup' })
)`
  width: 100%;

  &::before {
    display: none;
  }

  & .MuiSelect-select {
    padding: 4px 12px;
    border: solid 1px ${colors.black};
    border-bottom-color: ${colors.black};
  }
`;

export const SelectItem = styled(
  MenuItem,
  parseStyledOptions({ label: 'SelectHEDI-SelectItem' })
)`
  & {
    padding: 4px 12px;
    display: block;
    background: ${colors.white} !important;
    border-color: ${colors.additionalGray} !important;
    transition: background 0.2s ease-in-out;

    &:nth-of-type(2n) {
      background: ${colors.extraLightGray} !important;
    }

    &:hover {
      background: ${colors.additionalGray} !important;
    }
  }
`;

export const SelectItemLabel = styled(
  Paragraph,
  parseStyledOptions({ label: 'SelectHEDI-SelectItemLabel' })
)`
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
`;

export const SelectItemPrice = styled(
  Paragraph,
  parseStyledOptions({ label: 'SelectHEDI-SelectItemPrice' })
)`
  font-size: 12px;
  line-height: 20px;
  color: ${colors.gray};
`;

export const ArrowIcon = styled(
  ArrowDown,
  parseStyledOptions({ label: 'SelectHEDI-ArrowIcon' })
)`
  & {
    width: 16px;
    height: 16px;
    fill: ${colors.black};
    position: absolute;
    right: 12px;
    transition: transform 0.2s ease-in-out;
    top: calc(50% - 8px);
  }
`;
