import { axiosPipedrive } from './queryClient';

export const ORGANIZATION_KEYS_MAP = {
  name: 'name',
  text: '210aa0af77abdd3314302ad6ca6ab84c5211644a',
  website: '210aa0af77abdd3314302ad6ca6ab84c5211644a',
} as const;

type CreateOrganizationPayload = Record<
  Exclude<keyof typeof ORGANIZATION_KEYS_MAP, 'website'>,
  string
> & { website?: string };

type Organization = {
  id: number;
  [ORGANIZATION_KEYS_MAP.text]: string;
  [ORGANIZATION_KEYS_MAP.name]: string;
};

export const createOrganization = (data: {
  payload: CreateOrganizationPayload;
}) => {
  return axiosPipedrive.post<{ success: boolean; data: Organization }>(
    `/organizations?api_token=${process.env.REACT_APP_PIPEDRIVE_API_KEY}`,
    {
      [ORGANIZATION_KEYS_MAP.name]: data.payload.name,
      [ORGANIZATION_KEYS_MAP.text]: data.payload.text,
      ...(data.payload.website
        ? { [ORGANIZATION_KEYS_MAP.website]: data.payload.website }
        : {}),
    }
  );
};
