export const FONT_FAMILY_PRIMARY = 'PPMori';

export const FONT_FAMILY_SECONDARY = 'PPNeueMachina';

export const FONT_FAMILY = {
  primary: `${FONT_FAMILY_PRIMARY}, sans-serif`,
  secondary: `${FONT_FAMILY_SECONDARY}, sans-serif`,
};

export const STATIC_HACKEN_IMG =
  'https://wp.hacken.io/wp-content/uploads/2023/02/hacken-safe-web3.png';
