import React from 'react';
import {
  ButtonsWrapper,
  SectionWrapper,
  Title,
  Wrapper,
} from './FooterActions.styled';
import { ButtonTransparent } from '../../styles/Global.styled';
import RequestQuoteBtn from '../RequestQuoteBtn';
import BuyButton from '../BuyButton';

const FooterActions: React.FC = () => {
  return (
    <SectionWrapper>
      <Wrapper>
        <Title>Hacken Engineers Day Index</Title>
        <ButtonsWrapper>
          <RequestQuoteBtn
            hidePartners
            mainBtn={(deps) => (
              <ButtonTransparent onClick={() => deps?.onClick?.()} $white>
                Request a quote
              </ButtonTransparent>
            )}
          />
          <BuyButton />
        </ButtonsWrapper>
      </Wrapper>
    </SectionWrapper>
  );
};

export default FooterActions;
