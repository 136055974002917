import React, { useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import Toast from '../Toast';
import { useLocation } from 'react-router-dom';
interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { hash, pathname } = useLocation();

  useEffect(() => {
    if (!hash.includes('#')) {
      window.scrollTo(0, 0);
    }
  }, [hash, pathname]);
  return (
    <>
      <Header />
      <Toast />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
