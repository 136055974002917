import React, { useCallback, useState } from 'react';
import {
  CloseBtn,
  Content,
  ContentWrap,
  Modal,
  Partners,
  PartnersList,
  PartnersListItem,
  SubTitle,
  SuccessButton,
  SuccessContent,
  SuccessSubText,
  SuccessTitle,
  Text,
  Title,
} from './RequestModal.styled';
import { Formik } from 'formik';
import RequestQuoteForm from '../../forms/RequestQuoteForm';
import { PlatformNames } from '../../../queries/person';
import { requestQuoteValidationSchema } from '../../../utils/validators';
import {
  useRequestQuote,
} from '../../../hooks/useRequestQuote';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as AvalancheLogoIcon } from 'assets/icons/avalanche-caption-logo.svg';
import { ReactComponent as KyberLogoIcon } from 'assets/icons/kyber-network-logo.svg';
import { ReactComponent as PolkastarterLogoIcon } from 'assets/icons/polkastarter-logo.svg';
import { ReactComponent as SandboxLogoIcon } from 'assets/icons/sandbox-logo.svg';
import { ReactComponent as KucoinLogoIcon } from 'assets/icons/kucoin-logo.svg';
import { ReactComponent as DaoMakerLogoIcon } from 'assets/icons/daoMaker-logo.svg';
import { ReactComponent as NearLogoIcon } from 'assets/icons/near-logo.svg';
import { ReactComponent as WhiteBitLogoIcon } from 'assets/icons/white-bit-logo.svg';

import checkmark from 'assets/images/checkmark.png';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface Props {
  showModal: boolean;
  closeModal: () => void;
  title?: React.ReactNode;
  hidePartners?: boolean;
  formName?: string;
}

const REQUEST_QUOTE_STORAGE_KEY_NAME = 'requestedQuote';

const RequestModal: React.FC<Props> = ({
  showModal,
  closeModal,
  title,
  hidePartners,
  formName,
}) => {
  const [, rerender] = useState<number>();
  const { onSubmit } = useRequestQuote();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const showSuccessScreen =
    typeof window === 'undefined'
      ? false
      : sessionStorage.getItem(REQUEST_QUOTE_STORAGE_KEY_NAME) === 'true';

  const toggleShowSuccess = (v: boolean) => {
    sessionStorage.setItem(REQUEST_QUOTE_STORAGE_KEY_NAME, String(v));
    rerender(Math.random());
  };

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    return await executeRecaptcha(
      `${formName ? `${formName}_` : ''}FormSubmit`
    );
  }, [executeRecaptcha]);

  return (
    <Modal
      open={showModal}
      onClose={closeModal}
      slotProps={{ backdrop: { transitionDuration: 50 } }}
    >
      <ContentWrap
        onClick={(e) => {
          if (e.target === e.currentTarget) {
            closeModal();
          }
        }}
      >
        {!showSuccessScreen && (
          <>
            <Content>
              <Title>{title || 'Tell us about your project'}</Title>

              <CloseBtn aria-label='close form' onClick={closeModal}>
                <CloseIcon />
              </CloseBtn>
              <Formik
                onSubmit={onSubmit({
                  toggleSuccessScreen: toggleShowSuccess,
                  handleReCaptchaVerify,
                })}
                component={RequestQuoteForm}
                initialValues={{
                  name: '',
                  platformName: PlatformNames.telegram,
                  platformNickname: '',
                  service: [],
                  email: '',
                  details: '',
                  policyAgreement: false,
                  subscription: false,
                }}
                validationSchema={requestQuoteValidationSchema}
              />
              {/*{formNode?.({ toggleSuccessScreen: toggleShowSuccess, handleReCaptchaVerify })}*/}
            </Content>

            {!hidePartners && (
              <Partners>
                <SubTitle>
                  <span>180+</span>
                  <br />
                  Ecosystem partners
                </SubTitle>

                <PartnersList>
                  <PartnersListItem>
                    <AvalancheLogoIcon />
                  </PartnersListItem>
                  <PartnersListItem $whiteSvgPath>
                    <KyberLogoIcon />
                  </PartnersListItem>
                  <PartnersListItem $whiteSvgPath>
                    <PolkastarterLogoIcon />
                  </PartnersListItem>
                  <PartnersListItem $whiteSvgPath>
                    <SandboxLogoIcon />
                  </PartnersListItem>
                  <PartnersListItem $whiteSvgPath>
                    <KucoinLogoIcon />
                  </PartnersListItem>
                  <PartnersListItem $whiteSvgPath>
                    <DaoMakerLogoIcon />
                  </PartnersListItem>
                  <PartnersListItem $whiteSvgPath>
                    <NearLogoIcon />
                  </PartnersListItem>
                  <PartnersListItem>
                    <WhiteBitLogoIcon />
                  </PartnersListItem>
                </PartnersList>
              </Partners>
            )}
          </>
        )}

        {showSuccessScreen && (
          <SuccessContent>
            <LazyLoadImage
              src={checkmark}
              alt={'check'}
              width={182}
              height={136}
            />
            <SuccessTitle>Thank you for your enquiry!</SuccessTitle>
            <SuccessSubText>
              We’ll get in touch with you shortly.
            </SuccessSubText>
            <SuccessButton variant='contained' onClick={closeModal}>
              <Text>OK</Text>
            </SuccessButton>
          </SuccessContent>
        )}
      </ContentWrap>
    </Modal>
  );
};

export default RequestModal;
