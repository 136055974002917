import { Button } from '@mui/material';
import styled from '@emotion/styled';

import { parseStyledOptions } from 'utils/styled';

export const RequestQuoteBtn = styled(
  Button,
  parseStyledOptions({ label: 'RequestQuoteBtn' })
)`
  padding: 16px 32px;
  width: 267px;
  max-height: 64px;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.6;
`;

export const Text = styled(
  'span',
  parseStyledOptions({ label: 'RequestQuoteBtn--Text' })
)`
  margin-right: 8px;
`;
