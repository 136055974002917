import { colors } from '../../utils/colors';
import styled from '@emotion/styled';
import { parseStyledOptions } from '../../utils/styled';
import { Paragraph as ParagraphCommon } from '../SectionTypography/styled';
import { FONT_FAMILY } from '../../utils/consts';
interface ListItemProps {
  $active?: boolean;
}
export const Container = styled(
  'div',
  parseStyledOptions({ label: 'ServicesSection-Container' })
)`
  max-width: 1440px;
  margin: 0 auto;
`;

export const SectionWrapper = styled(
  'section',
  parseStyledOptions({ label: 'ServicesSection-SectionWrapper' })
)``;

export const SectionContainer = styled(
  'div',
  parseStyledOptions({ label: 'ServicesSection-SectionContainer' })
)`
  border-top: 1px solid ${colors.additionalGray};
  border-bottom: 1px solid ${colors.additionalGray};
`;

export const Wrapper = styled(
  Container,
  parseStyledOptions({ label: 'ServicesHowItWorks-Wrapper' })
)`
  display: flex;
  flex-direction: column;

  padding: 46px 16px 48px;

  @media screen and (min-width: 768px) {
    padding: 80px 96px 48px;
  }

  @media screen and (min-width: 1025px) {
    flex-direction: row;
    justify-content: space-between;
    gap: 84px;
  }
`;

export const DescriptionBlock = styled(
  'div',
  parseStyledOptions({ label: 'ServicesHowItWorks-DescriptionBlock' })
)`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 560px;
  margin: 0 auto 64px;

  @media screen and (min-width: 1025px) {
    margin-bottom: 0;
  }
`;
export const Paragraph = styled(
  ParagraphCommon,
  parseStyledOptions({ label: 'ServicesHowItWorks-Paragraph' })
)`
  text-align: start;
  margin-bottom: 24px;
  max-width: 480px;

  @media screen and (min-width: 480px) {
    text-align: center;
    max-width: 560px;
  }
`;

export const ImageWrapper = styled(
  'div',
  parseStyledOptions({ label: 'ServicesHowItWorks-ImageWrapper' })
)`
  margin-bottom: 22px;
  max-width: 350px;
  max-height: 290px;

  & img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  @media screen and (min-width: 768px) {
    margin-bottom: 57px;
  }
`;

export const StepsContainer = styled(
  'div',
  parseStyledOptions({ label: 'ServicesHowItWorks-StepsContainer' })
)`
  flex: 1;
`;

export const List = styled(
  'ul',
  parseStyledOptions({ label: 'ServicesHowItWorks-List' })
)`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
  gap: 16px;

  @media screen and (min-width: 768px) {
    margin: 0 0 42px;
    padding-left: 0;
    gap: 0;
  }
`;

export const ListItem = styled(
  'li',
  parseStyledOptions({ label: 'ServicesHowItWorks-ListItem' })
)<ListItemProps>`
  display: flex;
  align-items: center;
  padding: 8px 15px;
  //cursor: pointer;
  //transition: border 0.3s ease-in-out;
  //border: solid 1px transparent;

  // ${({ $active }) => $active && `border: solid 1px ${colors.blue};`}
  // & > svg {
  //   width: 32px;
  //   height: 32px;
  //   margin-left: 16px;
  //
  //   path {
  //     transition: fill 0.3s ease-in-out;
  //     fill: ${({ $active }) => ($active ? colors.blue : 'transparent')};
  //   }
  // }
  //
  // &:focus {
  //   border: solid 1px ${colors.blueDark};
  // }
  //
  // &:hover {
  //   border: solid 1px ${colors.blue};
  // }

  @media screen and (min-width: 768px) {
    padding: 12px 24px;
  }
`;

export const StepsDescription = styled(
  'p',
  parseStyledOptions({ label: 'ServicesHowItWorks-StepsDescription' })
)`
  font-family: ${FONT_FAMILY.primary};
  font-weight: 400;
  font-size: 12px;
  line-height: 1.67;

  color: ${colors.grayDark};
  margin-bottom: 24px;

  @media screen and (min-width: 768px) {
    font-size: 16px;
    line-height: 1.62;
    margin-left: 24px;
  }
`;

export const ListItemTitle = styled(
  'h4',
  parseStyledOptions({ label: 'ServicesHowItWorks-ListItemTitle' })
)`
  flex: 1;
  font-family: ${FONT_FAMILY.secondary};
  font-weight: 400;
  font-size: 18px;
  line-height: 1.44;
  color: ${colors.black};

  @media screen and (min-width: 768px) {
    font-size: 24px;
    line-height: 1.25;
  }
`;

export const CircularChart = styled(
  'div',
  parseStyledOptions({ label: 'ServicesHowItWorks-CircularChart' })
)`
  display: flex;
  align-items: center;
  margin-right: 24px;

  & svg {
    display: block;
    width: 40px;
    height: 40px;
  }

  & path {
    fill: none;
    stroke-width: 2;

    &:first-of-type {
      stroke: ${colors.additionalGray};
    }

    &:last-of-type {
      stroke: ${colors.blue};
    }
  }

  & text {
    text-anchor: middle;
    font-weight: 600;
    font-size: 14px;
    fill: ${colors.black};
  }

  @media screen and (min-width: 768px) {
    & svg {
      width: 48px;
      height: 48px;
    }
  }
`;

export const BuyBtnWrapper = styled(
  'div',
  parseStyledOptions({ label: 'ServicesHowItWorks-BuyBtnWrapper' })
)`
  display: flex;
  justify-content: center;

  @media screen and (min-width: 768px) {
    justify-content: flex-start;
    margin-left: 24px;
  }
`;
