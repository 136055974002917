import styled from '@emotion/styled';
import { parseStyledOptions } from '../../utils/styled';
import { colors } from '../../utils/colors';
import { FONT_FAMILY } from '../../utils/consts';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const BenefitsContentWrapper = styled(
  'div',
  parseStyledOptions({ label: 'MainPage-BenefitsContentWrapper' })
)`
  padding: 16px;
  margin: 0 auto;

  @media screen and (min-width: 768px) {
    padding: 42px;
  }

  @media screen and (min-width: 1440px) {
    padding: 48px 96px;
    max-width: 1440px;
    display: flex;
    align-items: center;
  }
`;

export const BenefitsImage = styled(
  LazyLoadImage,
  parseStyledOptions({ label: 'MainPage-BenefitsImage' })
)`
  width: 340px;
  height: 312px;
  margin: 0 auto;
  display: block;
`;
export const BenefitsContent = styled(
  'div',
  parseStyledOptions({ label: 'MainPage-BenefitsContent' })
)`
  @media screen and (min-width: 1024px) {
    flex: 1;
  }
`;

export const BenefitsContentTitle = styled(
  'h3',
  parseStyledOptions({ label: 'MainPage-BenefitsContentTitle' })
)`
  font-size: 40px;
  font-weight: 300;
  font-family: ${FONT_FAMILY.secondary};
  line-height: 48px;
  color: ${colors.black};
  margin-bottom: 16px;
`;
export const BenefitsContentDescription = styled(
  'p',
  parseStyledOptions({ label: 'MainPage-BenefitsContentDescription' })
)`
  font-size: 20px;
  line-height: 32px;
  color: ${colors.black};
  font-weight: 600;
`;
