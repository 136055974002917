import React from 'react';
import { ToastContainer, ToastErrIcon, ToastMessage } from './Toast.styled';
import {
  resolveValue,
  Toaster,
  Toast,
  ToastPosition,
  toast,
} from 'react-hot-toast';
import { keyframes } from 'goober';

const enterAnimation = (factor: number) => `
0% {transform: translate3d(0,${factor * -200}%,0) scale(.6); opacity:.5;}
100% {transform: translate3d(0,0,0) scale(1); opacity:1;}
`;
const exitAnimation = (factor: number) => `
0% {transform: translate3d(0,0,-1px) scale(1); opacity:1;}
100% {transform: translate3d(0,${factor * -150}%,-1px) scale(.6); opacity:0;}
`;
const fadeInAnimation = '0%{opacity:0;} 100%{opacity:1;}';
const fadeOutAnimation = '0%{opacity:1;} 100%{opacity:0;}';

export const prefersReducedMotion = (() => {
  // Cache result
  let shouldReduceMotion: boolean | undefined = undefined;

  return () => {
    if (shouldReduceMotion === undefined && typeof window !== 'undefined') {
      const mediaQuery = matchMedia('(prefers-reduced-motion: reduce)');
      shouldReduceMotion = !mediaQuery || mediaQuery.matches;
    }
    return shouldReduceMotion;
  };
})();
const getAnimationStyle = (
  position: ToastPosition,
  visible: boolean
): React.CSSProperties => {
  const top = position.includes('top');
  const factor = top ? 1 : -1;

  const [enter, exit] = prefersReducedMotion()
    ? [fadeInAnimation, fadeOutAnimation]
    : [enterAnimation(factor), exitAnimation(factor)];

  return {
    animation: visible
      ? `${keyframes(enter)} 0.35s cubic-bezier(.21,1.02,.73,1) forwards`
      : `${keyframes(exit)} 0.4s forwards cubic-bezier(.06,.71,.55,1)`,
  };
};

const ToastWrapper: React.FC<{ t: Toast }> = ({ t }) => {
  const animationStyle: React.CSSProperties = t.height
    ? getAnimationStyle(t.position || 'bottom-right', t.visible)
    : { opacity: 0 };

  return (
    <ToastContainer
      style={{
        ...animationStyle,
      }}
      $type={t.type}
    >
      <ToastMessage $type={t.type}>{resolveValue(t.message, t)}</ToastMessage>
      <ToastErrIcon onClick={() => toast.dismiss(t.id)} />
    </ToastContainer>
  );
};
const ToastCustom: React.FC = () => {
  return (
    <Toaster position={'bottom-right'}>{(t) => <ToastWrapper t={t} />}</Toaster>
  );
};

export default ToastCustom;
