import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { TokenEnum } from '../../types/enum/token';
import { getContractToken } from '../contract';
import { ADDRESS_SETS } from '../config';

const useBalance = (
  address?: string | null,
  options?: UseQueryOptions<string>
) => {
  const { provider, isActive, account } = useWeb3React();

  const query = useQuery<string>({
    queryKey: ['balance', address, account],
    queryFn: async () => {
      if (!provider) throw new Error('No provider');
      if (!account) throw new Error('No account');

      try {
        const balance = address?.length
          ? await getContractToken(address, provider).balanceOf(account)
          : await provider?.getBalance(account);

        if (!balance) {
          throw new Error('No balance');
        }
        return balance.toString();
      } catch (error) {
        throw new Error(error);
      }
    },
    enabled: !!provider && isActive && !!address,
    staleTime: 10 * 1000,
    refetchInterval: 10 * 1000,
    ...options,
  });

  return query;
};

const useTokenBalance = (
  token?: TokenEnum,
  options?: UseQueryOptions<string>
) => {
  const { chainId } = useWeb3React<Web3Provider>();

  const addressSet = chainId ? ADDRESS_SETS[chainId] : null;
  const address =
    typeof token !== 'undefined' ? addressSet?.[token] : 'undefined';

  return useBalance(token === TokenEnum.BNB ? null : address, options);
};
export { useBalance, useTokenBalance };
