export const COLOR_BLACK = '#222223';
export const COLOR_DARK_BLACK = '#1E1E1E';
export const COLOR_WHITE = '#FFFFFF';
export const COLOR_GREEN = '#57FF57';
export const COLOR_ORANGE = '#F99F53';
export const COLOR_RED = '#CA4A6A';
export const COLOR_BLUE = '#0D6CFC';
export const COLOR_VIOLET = '#A91BDB';
export const COLOR_GRAY = '#A7A8AA';
export const COLOR_BLUE_DARK = '#0651C3';
export const COLOR_GRAY_DARK = '#53565A';
export const COLOR_GRAY_MEDIUM = '#75787B';
export const COLOR_GRAY_LIGHT = '#C8C9C7';
export const COLOR_LIGHT_GREEN = '#A8FFB2';
export const COLOR_LIGHT_BLUE = '#ACFFFF';
export const COLOR_BLUE_MEDIUM = '#1B7BDB';
export const COLOR_GREEN_MEDIUM = '#17C917';
export const COLOR_ACCENT_GREEN = '#3EF2D0';
export const COLOR_ACCENT_LIGHT_GREEN = '#B0FFF0';
export const COLOR_ACCENT_MEDIUM_GREEN = '#00FABE';
export const COLOR_ADDITIONAL_ORANGE = '#FFC633';
export const COLOR_ADDITIONAL_GRAY = '#D9D9D6';
export const COLOR_TRANSPARENT_RED = '#F4CACB';
export const COLOR_TRANSPARENT_ORANGE = '#F9E4DC';
export const COLOR_TRANSPARENT_BLUE = '#ACFFFF';
export const COLOR_EXTRA_DARK_GRAY = '#2D2E30';
export const COLOR_EXTRA_LIGHT_GRAY = '#F6F6F6';

export const colors = {
  black: COLOR_BLACK,
  darkBlack: COLOR_DARK_BLACK,
  white: COLOR_WHITE,
  green: COLOR_GREEN,
  orange: COLOR_ORANGE,
  red: COLOR_RED,
  blue: COLOR_BLUE,
  violet: COLOR_VIOLET,
  gray: COLOR_GRAY,
  blueDark: COLOR_BLUE_DARK,
  grayDark: COLOR_GRAY_DARK,
  grayMedium: COLOR_GRAY_MEDIUM,
  grayLight: COLOR_GRAY_LIGHT,
  greenLight: COLOR_LIGHT_GREEN,
  blueLight: COLOR_LIGHT_BLUE,
  blueMedium: COLOR_BLUE_MEDIUM,
  greenMedium: COLOR_GREEN_MEDIUM,
  accentGreen: COLOR_ACCENT_GREEN,
  accentLightGreen: COLOR_ACCENT_LIGHT_GREEN,
  accentMediumGreen: COLOR_ACCENT_MEDIUM_GREEN,
  additionalOrange: COLOR_ADDITIONAL_ORANGE,
  additionalGray: COLOR_ADDITIONAL_GRAY,
  transparentRed: COLOR_TRANSPARENT_RED,
  transparentOrange: COLOR_TRANSPARENT_ORANGE,
  transparentBlue: COLOR_TRANSPARENT_BLUE,
  extraDarkGray: COLOR_EXTRA_DARK_GRAY,
  extraLightGray: COLOR_EXTRA_LIGHT_GRAY,
} as const;
