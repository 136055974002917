import { axiosCMS, axiosPipedrive, axiosPipedriveProxy } from './queryClient';

export enum FormNameVariants {
  quote = 346,
  speakers = 347,
  partnership = 348,
  bizdevMeeting = 349,
}

export const DEAL_KEYS_MAP = {
  title: 'title',
  orgId: 'org_id',
  personId: 'person_id',
  services: '0c813c00cf3ad337f9100d5bc5d66cd1ab1cc5c5',
  service: 'fc318801682f831b922ad2da462ae464c66c38f5',
  comment: '59784441847f17ac147fea07f93d4c85a8603b67',
  utmTerm: '6c0c3a4ac02409ed97ef83005f21261b9406a778',
  utmSource: '972eca55caf9df344f26b0607fabeb2d6755fefc',
  utmMedium: 'e4f54058be154f638f57ba440344eab5c0a11bce',
  utmContent: '5365f9818cc16450d3737918586b04d755fd6ca0',
  utmCampaign: '7707d754fa43369ef3760c20fba8f77eceee54cf',
  userIp: '2ec66eac18528f8cd5bff19183d40294ea9a292e',
  userAgent: 'ef75998bd58f2b1f57765807da86b16bb96d83d6',
  sessionId: '62b958147285a914bef7efacbec9bbe52f4c6741',
  gclid: '481cdbaf6354f89c74535b8bdddcfb1fcc3cc53b',
  clientId: '2b45158355abb466f82af3ac0dbbfe2b5eb7ae62',
  pageLocation: 'dddf66ad6d5f5f2a53c7dbf483bd24b72202b948',
  formName: '3a9d55b27754ab202f5190928a5b6f7bafb2394f',
  leadSource: '48dc05cf77ffab122bed70fec7f35eeaa9592f8b',
  linkMedia: 'b16c186b8dc9d2dc79a4411c24d429c11300ce7d',
  nameMedia: '8ccd8b053eca3592bf8513bbf2b2ec17726aa5b6',
} as const;

type CreateDealPayload = Record<
  Exclude<
    keyof typeof DEAL_KEYS_MAP,
    | 'leadSource'
    | 'formName'
    | 'services'
    | 'orgId'
    | 'service'
    | 'linkMedia'
    | 'nameMedia'
  >,
  string
> & { services: number[]; orgId: number };

type Deal = {
  [DEAL_KEYS_MAP.services]: string;
  [DEAL_KEYS_MAP.clientId]: string;
  [DEAL_KEYS_MAP.userIp]: string;
  [DEAL_KEYS_MAP.utmTerm]: string;
  [DEAL_KEYS_MAP.leadSource]: number;
  [DEAL_KEYS_MAP.sessionId]: string;
  [DEAL_KEYS_MAP.gclid]: string;
  [DEAL_KEYS_MAP.utmSource]: string;
  [DEAL_KEYS_MAP.utmContent]: string;
  [DEAL_KEYS_MAP.utmCampaign]: string;
  [DEAL_KEYS_MAP.comment]: string;
  [DEAL_KEYS_MAP.pageLocation]: string;
  [DEAL_KEYS_MAP.utmMedium]: string;
  [DEAL_KEYS_MAP.userAgent]: string;
  add_time: string;
  cc_email: string;
  creator_id: number;
  id: string;
  org_id: { value: number };
  person_id: { value: number };
  source_name: string;
  title: string;
  update_time: string;
  value: unknown | null;
  visible_to: string;
  was_seen: boolean;
};

export const createDeal = (data: { payload: CreateDealPayload }) => {
  return axiosPipedrive.post<{ success: boolean; data: Deal }>(
    `/deals?api_token=${process.env.REACT_APP_PIPEDRIVE_API_KEY}`,
    {
      '662d2859f86ea11c9d1f94a85548fdbb8d7336c8': 'hacken.io',
      // eslint-disable-next-line camelcase
      pipeline_id: 13,
      [DEAL_KEYS_MAP.leadSource]: 65,
      [DEAL_KEYS_MAP.title]: data.payload.title,
      [DEAL_KEYS_MAP.orgId]: data.payload.orgId,
      [DEAL_KEYS_MAP.personId]: data.payload.personId,
      [DEAL_KEYS_MAP.services]: data.payload.services,
      [DEAL_KEYS_MAP.comment]: data.payload.comment,
      [DEAL_KEYS_MAP.utmTerm]: data.payload.utmTerm,
      [DEAL_KEYS_MAP.utmSource]: data.payload.utmSource,
      [DEAL_KEYS_MAP.utmMedium]: data.payload.utmMedium,
      [DEAL_KEYS_MAP.utmContent]: data.payload.utmContent,
      [DEAL_KEYS_MAP.utmCampaign]: data.payload.utmCampaign,
      [DEAL_KEYS_MAP.userIp]: data.payload.userIp,
      [DEAL_KEYS_MAP.userAgent]: data.payload.userAgent,
      [DEAL_KEYS_MAP.sessionId]: data.payload.sessionId,
      [DEAL_KEYS_MAP.gclid]: data.payload.gclid,
      [DEAL_KEYS_MAP.clientId]: data.payload.clientId,
      [DEAL_KEYS_MAP.pageLocation]: data.payload.pageLocation,
    }
  );
};

type CreatePartnerDealPayload = {
  title: string;
  orgId: number;
  personId: string;
  comment: string;
  service: number;
  utmTerm: string;
  utmSource: string;
  utmMedium: string;
  utmContent: string;
  utmCampaign: string;
  userIp: string;
  userAgent: string;
  sessionId: string;
  gclid: string;
  clientId: string;
};

export const createPartnerDeal = (data: {
  payload: CreatePartnerDealPayload;
}) => {
  return axiosPipedrive.post<{ success: boolean; data: Deal }>(
    `/deals?api_token=${process.env.REACT_APP_PIPEDRIVE_API_KEY}`,
    {
      // eslint-disable-next-line camelcase
      pipeline_id: 33,
      [DEAL_KEYS_MAP.formName]: FormNameVariants.partnership,
      [DEAL_KEYS_MAP.leadSource]: 65,
      title: data.payload.title,
      [DEAL_KEYS_MAP.orgId]: data.payload.orgId,
      [DEAL_KEYS_MAP.personId]: data.payload.personId,
      [DEAL_KEYS_MAP.comment]: data.payload.comment,
      [DEAL_KEYS_MAP.service]: data.payload.service,
      [DEAL_KEYS_MAP.utmTerm]: data.payload.utmTerm,
      [DEAL_KEYS_MAP.utmSource]: data.payload.utmSource,
      [DEAL_KEYS_MAP.utmMedium]: data.payload.utmMedium,
      [DEAL_KEYS_MAP.utmContent]: data.payload.utmContent,
      [DEAL_KEYS_MAP.utmCampaign]: data.payload.utmCampaign,
      [DEAL_KEYS_MAP.userIp]: data.payload.userIp,
      [DEAL_KEYS_MAP.userAgent]: data.payload.userAgent,
      [DEAL_KEYS_MAP.sessionId]: data.payload.sessionId,
      [DEAL_KEYS_MAP.gclid]: data.payload.gclid,
      [DEAL_KEYS_MAP.clientId]: data.payload.clientId,
    }
  );
};

type SendDealCreatedAnalyticsPayload = {
  id: string;
  createdAt: string;
  wwwNeed: string;
  email: string;
  orgId: number;
  utmTerm: string;
  utmSource: string;
  utmMedium: string;
  utmContent: string;
  utmCampaign: string;
  userIp: string;
  userAgent: string;
  sessionId: string;
  gclid: string;
  clientId: string;
  personId: number;
  pageLocation: string;
};

/* eslint-disable camelcase */
export const sendDealCreatedAnalytics = (data: {
  payload: SendDealCreatedAnalyticsPayload;
}) => {
  return axiosPipedriveProxy.post('', {
    '662d2859f86ea11c9d1f94a85548fdbb8d7336c8': 'hacken.io',
    efabaf4a5fca830b6359b27a3698d2ad1c9cf826: 344,
    id: data.payload.id,
    created_at: data.payload.createdAt,
    www_need: data.payload.wwwNeed,
    email: data.payload.email,
    company_id: data.payload.orgId,
    utm_source: data.payload.utmSource,
    utm_medium: data.payload.utmMedium,
    utm_campaign: data.payload.utmCampaign,
    utm_content: data.payload.utmContent,
    utm_term: data.payload.utmTerm,
    gclid: data.payload.gclid,
    client_id: data.payload.clientId,
    session_id: data.payload.sessionId,
    user_ip: data.payload.userIp,
    user_agent: data.payload.userAgent,
    source_name: 'API',
    person_id: data.payload.personId,
    hostname: 'hacken.io',
    page_location: data.payload.pageLocation,
  });
};
/* eslint-enable */

type SaveDealPayload = {
  name: string;
  nickname: string;
  phone: string;
  messenger: string;
  selectedServices: string;
  email: string;
  projectDetails: string;
  utmSource: string;
  utmMedium: string;
  pageLocation: string;
  status: string;
};

export const saveDeal = (data: { payload: SaveDealPayload }) => {
  return axiosCMS.post('/deals', data.payload);
};
