import React from 'react';
import { CopyButton, Hash, Label, Root, Wrapper } from './TxHash.styled';
import { ReactComponent as Copy } from 'assets/icons/copy.svg';
import { toast } from 'react-hot-toast';

interface Props {
  hash: string;
}

const TxHash: React.FC<Props> = ({ hash }) => {
  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(hash)
      .then(() => {
        toast.success('Copied to clipboard');
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  return (
    <Root>
      <Label>Tx Hash:</Label>
      <Wrapper>
        <Hash>{hash}</Hash>
        <CopyButton onClick={copyToClipboard}>
          <Copy />
        </CopyButton>
      </Wrapper>
    </Root>
  );
};

export default TxHash;
