import React from 'react';
import {
  EmptyText,
  Footer,
  SwapButton,
  EmptyTitle,
  EmptyContent,
} from './SwapForm.styled';
import { Link } from 'react-router-dom';

const SwapEmpty: React.FC = () => {
  return (
    <>
      <EmptyContent>
        <EmptyTitle>You don’t have HEDI to swap</EmptyTitle>
        <EmptyText>To purchase HEDI go back to main page</EmptyText>
      </EmptyContent>
      <Footer>
        <Link to={'/'}>
          <SwapButton>Back to main page</SwapButton>
        </Link>
      </Footer>
    </>
  );
};

export default SwapEmpty;
