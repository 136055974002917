import styled from '@emotion/styled';
import { parseStyledOptions } from '../../../utils/styled';
import { colors } from '../../../utils/colors';
import { H1, Paragraph } from '../../SectionTypography/styled';
import { TextField } from '@mui/material';
import { ButtonDefault } from '../../../styles/Global.styled';
import { Link } from 'react-router-dom';

export const Root = styled(
  'div',
  parseStyledOptions({ label: 'TransferForm-Root' })
)`
  max-width: 470px;
  width: 100%;
  background: ${colors.white};
`;
export const Content = styled(
  'div',
  parseStyledOptions({ label: 'TransferForm-Content' })
)`
  border-bottom: solid 1px ${colors.additionalGray};
  padding: 24px;

  @media screen and (min-width: 768px) {
    padding: 24px 48px;
  }
`;

export const Header = styled(
  Content,
  parseStyledOptions({ label: 'TransferForm-Header' })
)`
  padding: 24px;
  @media screen and (min-width: 768px) {
    padding: 48px 48px 24px;
  }
`;

export const Title = styled(
  H1,
  parseStyledOptions({ label: 'TransferForm-Title' })
)`
  font-size: 24px;
  line-height: 30px;
  color: ${colors.black};

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

export const Footer = styled(
  'div',
  parseStyledOptions({ label: 'TransferForm-Header' })
)`
  display: flex;
  justify-content: center;
  padding: 24px;
  border-bottom: none;
  @media screen and (min-width: 768px) {
    padding: 24px 48px;
  }
`;

export const TransferButton = styled(
  ButtonDefault,
  parseStyledOptions({ label: 'TransferForm-TransferButton' })
)<{ $success?: boolean }>`
  font-size: 16px;
  line-height: 26px;
  width: ${({ $success }) => ($success ? 'auto' : '100%')};
`;

export const Label = styled(
  'p',
  parseStyledOptions({ label: 'InputHEDIAmount-Label' })
)`
  font-size: 12px;
  line-height: 20px;
  color: ${colors.black};
  margin-bottom: 12px;
`;

export const AddressInput = styled(
  TextField,
  parseStyledOptions({ label: 'TransferForm-AddressInput' })
)`
    width: 100%;

    input {
        padding: 14px;
        text-align: center;
        font-size: 12px;
        line-height: 20px;
        color: ${colors.black};
        background: ${colors.extraLightGray};

        -moz-appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        &::placeholder {
            color: ${colors.gray};
        }
    }

    & > ::before {
        display: none;
    }
}
`;

export const WarningMessage = styled(
  Paragraph,
  parseStyledOptions({ label: 'TransferForm-WarningMessage' })
)`
  font-size: 12px;
  line-height: 20px;
  color: ${colors.black};
  background: ${colors.transparentOrange};
  padding: 8px 66px;
  text-align: center;
`;
