import { axiosPipedrive } from './queryClient';

export enum PlatformNames {
  telegram = 'telegram',
  whatsApp = 'whatsApp',
  weChat = 'weChat',
  signal = 'signal',
}

export const PERSON_KEYS_MAP = {
  orgId: 'org_id',
  name: 'name',
  email: 'email',
  telegram: 'f85d2d5f8c0adbe624d43b0cbb7d585897d597cf',
  whatsApp: '250adb89b01c12ac1577d866c69eb37875f3607a',
  weChat: '96ffe833a7042541fdd5f8ee489beda1313b4f4b',
  signal: '0548b8b21efe6114d26550271a6d0efeaed397bc',
} as const;

type CreatePersonPayload = Record<
  Exclude<
    keyof typeof PERSON_KEYS_MAP,
    'telegram' | 'whatsApp' | 'weChat' | 'signal' | 'orgId'
  >,
  string
> & {
  orgId: number;
  platformName?: PlatformNames;
  platformNickname?: string;
};

type Person = {
  id: string;
};

export const createPerson = (data: { payload: CreatePersonPayload }) => {
  return axiosPipedrive.post<{ data: Person }>(
    `/persons?api_token=${process.env.REACT_APP_PIPEDRIVE_API_KEY}`,
    {
      [PERSON_KEYS_MAP.orgId]: data.payload.orgId,
      [PERSON_KEYS_MAP.name]: data.payload.name,
      [PERSON_KEYS_MAP.email]: data.payload.email,
      ...(data.payload.platformName && data.payload.platformNickname
        ? {
            [PERSON_KEYS_MAP[data.payload.platformName]]:
              data.payload.platformNickname,
          }
        : {}),
    }
  );
};
