import React from 'react';
import RootCustom from '../../components/layout/RootCustom';
import SwapForm from '../../components/forms/SwapForm';

const SwapScreen: React.FC = () => {
  return (
    <RootCustom>
      <SwapForm />
    </RootCustom>
  );
};

export default SwapScreen;
