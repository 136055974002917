import React from 'react';
import { Content, Footer, TransferButton } from './TransferForm.styled';
import {
  EmptyText,
  ImageWrapper,
  SuccessContent,
  SuccessTitle,
} from '../SwapForm/SwapForm.styled';
import SuccessImg from '../../../assets/images/checkmark.png';
import TxHash from '../../TxHash';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Text } from '../../../screens/transfer/TransferScreen.styled';

interface Props {
  amountHEDI: string;
  txHash: string;
  destinationAddress: string;
}

const TransferSuccess: React.FC<Props> = ({
  amountHEDI,
  txHash,
  destinationAddress,
}) => {
  const isHacken = destinationAddress === process.env.REACT_APP_HACKEN_ADDRESS;

  return (
    <>
      <SuccessContent>
        <ImageWrapper>
          <LazyLoadImage src={SuccessImg} alt={'Swapped'} />
        </ImageWrapper>
        <SuccessTitle>
          You’ve transferred {amountHEDI} HEDI{isHacken ? ' to Hacken' : ''}
        </SuccessTitle>
        <Text $overflow={!isHacken}>
          {isHacken ? 'Our manager will contact you ASAP' :`To ${destinationAddress}`}
        </Text>
      </SuccessContent>
      <Content>
        <TxHash hash={txHash} />
      </Content>
      <Footer>
        <Link to={'/'}>
          <TransferButton $success>Back to main page</TransferButton>
        </Link>
      </Footer>
    </>
  );
};

export default TransferSuccess;
