import { Components } from '@mui/material';

import { colors } from 'utils/colors';

export const MuiCheckboxTheme: Components['MuiCheckbox'] = {
  styleOverrides: {
    root: {
      width: '18px',
      height: '18px',
      borderRadius: '0',
      border: `1px solid ${colors.additionalGray}`,

      '&.Mui-checked': {
        background: colors.blue,
        borderColor: colors.blue,
        color: colors.white,
      },
    },
  },
};
