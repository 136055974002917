import styled from '@emotion/styled';
import { Button } from '@mui/material';
import { parseStyledOptions } from '../../utils/styled';
import { colors } from '../../utils/colors';
import { ReactComponent as MetaMaskLogo } from 'assets/icons/metamask-logo.svg';

export const ConnectBtn = styled(
  Button,
  parseStyledOptions({ label: 'ConnectBtn' })
)<{ $pending?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;

  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  padding: 14px;

  background-color: ${colors.blue};
  color: ${colors.white};

  &:hover {
    border-color: ${colors.blueDark};
  }

  & svg {
    margin-right: 16px;
  }

  ${({ $pending }) =>
    $pending &&
    `   
    &.Mui-disabled {
        background-color: ${colors.blue};
        color: ${colors.white};
    }
    `}
`;

export const ConnectBtnText = styled(
  'span',
  parseStyledOptions({ label: 'BuyForm-ConnectBtnText' })
)``;

export const MetaMaskIcon = styled(
  MetaMaskLogo,
  parseStyledOptions({ label: 'BuyForm-MetaMaskIcon' })
)`
  width: 36px;
  height: 36px;
`;

export const Loader = styled('div', parseStyledOptions({ label: 'Loader' }))`
    width: 24px;
    height: 24px;
    border: 2px solid ${colors.white};
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    margin-left: 16px;
    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
`;
