import axios from 'axios';
import {
  CGPriceResponse,
  GroupHEDIResponse,
  PriceResponse,
} from 'types/actions';
import { TokenEnum } from 'types/enum/token';

export async function fetchLayoutData() {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_WP_API_URL}/wp-json/acf/v3/options/menus`
    );
    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
}

export async function fetchHEDIPriceHAI(token: TokenEnum, address: string) {
  try {
    const response = await axios.get<PriceResponse>(
      `${process.env.REACT_APP_API_URL}/price-offer/${token}/${address}`
    );
    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
}

export async function fetchHEDITokens(address: string) {
  try {
    const response = await axios.get<GroupHEDIResponse[]>(
      `${process.env.REACT_APP_API_URL}/tokens/${address}`
    );
    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
}

export async function fetchHAITokenPrice(): Promise<string> {
  try {
    const response = await axios.get<CGPriceResponse<'hackenai', 'usd'>>(
      `${process.env.REACT_APP_CG_API_URL}/simple/price`,
      {
        params: {
          ids: 'hackenai',
          vs_currencies: 'usd',
        },
        headers: {
          'x-cg-pro-api-key': process.env.REACT_APP_CG_API_KEY,
        },
      }
    );
    // const usdtPrice = process.env.REACT_APP_PRICE_USDT;
    // const priceBN = BigNumber.from(response.data?.hackenai?.usd || 0).mul(
    //   +usdtPrice
    // );
    // const price = ethers.utils.parseUnits(priceBN.toString(), 8).toString();
    // const data = {
    //   price,
    //   deadline: '0',
    //   nonce: '0',
    //   signature: null,
    // };
    // console.log('fetchHAITokenPrice', data);
    if (!response.data?.hackenai?.usd) {
      throw new Error('No price');
    }
    return response.data.hackenai.usd.toString();
  } catch (error: any) {
    throw new Error(error.message);
  }
}
