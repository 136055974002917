import React, { useMemo } from 'react';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { ConnectionType, INPUT_CHAIN_ID } from '../../web3/config';
import { getConnection, tryActivateConnector } from '../../web3';
import {
  ConnectBtn,
  ConnectBtnText,
  Loader,
  MetaMaskIcon,
} from './ConnectButton.styled';
import { isMobile } from 'react-device-detect';

type RenderData = {
  active: boolean;
  onAction: () => void;
  pending: boolean;
};

interface Props {
  disabled?: boolean;
  pending?: boolean;
  onClick?: () => void;
  text?: string;
  actionName?: string;
  children?: (data: RenderData) => React.ReactNode;
}

const ConnectButton: React.FC<Props> = ({
  disabled,
  text,
  onClick,
  actionName,
  pending,
  children,
}) => {
  const { isActive, isActivating, chainId } = useWeb3React<Web3Provider>();

  const disabledByChain = chainId ? chainId !== INPUT_CHAIN_ID : false;

  const onActivate = async () => {
    if (isActive) {
      onClick?.();
      //   check if mobile and ethereum is installed
    } else if (isMobile && !window.ethereum) {
      // redirect to metamask deeplink
      console.log(
        'redirecting to metamask deeplink',
        `${
          process.env.REACT_APP_METAMASK_DEEPLINK
        }/${process.env.REACT_APP_BASE_URL?.replace(/(^\w+:|^)\/\//, '')}`
      );
      window.location.replace(
        `${
          process.env.REACT_APP_METAMASK_DEEPLINK
        }/${process.env.REACT_APP_BASE_URL?.replace(/(^\w+:|^)\/\//, '')}`
      );
    } else {
      const activation = await tryActivateConnector(
        getConnection(ConnectionType.INJECTED).connector
      );
      if (!activation) {
        return;
      }
    }
  };

  const textFull = useMemo(() => {
    if (!isActive) {
      return 'Connect with MetaMask';
    } else if (isActivating || pending) {
      return 'Waiting for Confirmation';
    } else {
      return actionName || 'Confirm';
    }
  }, [actionName, isActivating, isActive, pending]);

  return (
    <>
      {children ? (
        children({
          active: isActive,
          pending: isActivating,
          onAction: onActivate,
        })
      ) : (
        <ConnectBtn
          disabled={
            isActive ? disabled || pending || disabledByChain : isActivating
          }
          onClick={onActivate}
          $pending={pending || isActivating}
        >
          {!isActive && <MetaMaskIcon />}
          <ConnectBtnText>{textFull}</ConnectBtnText>
          {(isActivating || pending) && <Loader />}
        </ConnectBtn>
      )}
    </>
  );
};

export default ConnectButton;
