import { useEffect, useState } from 'react';

type Props = {
  ignoreScroll: boolean;
  scrolledHeight?: number;
};

const useHeaderButtonScroll = ({
  ignoreScroll,
  scrolledHeight = 810,
}: Props) => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (!ignoreScroll) {
        if (window.scrollY >= scrolledHeight) {
          setScrolled(true);
        } else {
          setScrolled(false);
        }
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [ignoreScroll, scrolledHeight]);

  return scrolled;
};

export default useHeaderButtonScroll;
