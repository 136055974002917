import React, { useCallback, useMemo, useState } from 'react';
import {
  Content,
  Footer,
  Header,
  Root,
  SwapFee,
  SwapTotalValue,
  Title,
} from './SwapForm.styled';
import InputAmount from '../../InputAmount';
import { TokenEnum } from '../../../types/enum/token';
import BigNumber from 'bignumber.js';
import SwapEmpty from './SwapEmpty';
import SwapSuccess from './SwapSuccess';
import SelectHEDI from '../../SelectHEDI';
import {
  useHEDIActions,
  useHEDIBurnPercentage,
  useHEDIGroups,
} from '../../../hooks/useHEDI';
import { formatToken } from '../../../utils/formatter';
import ConnectButton from '../../ConnectButton';
import { toast } from 'react-hot-toast';
import useAllowance, {
  useAllowanceMutation,
} from '../../../web3/hooks/useAllowance';
import { constants } from 'ethers';

const SwapForm: React.FC = () => {
  const { data: etdGroups, isFetched } = useHEDIGroups();
  const { data } = useHEDIBurnPercentage();
  const { sell } = useHEDIActions();

  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [etdGroupIdx, setEtdGroupIdx] = useState<number>(0);
  const [etdAmount, setEtdAmount] = useState<string>('0');
  const [pending, setPending] = useState<boolean>(false);
  const [txHash, setTxHash] = useState<string>('');
  const { data: tokenAllowance } = useAllowance({ token: TokenEnum.HEDI });
  const { mutate: mutateTokenAllowance } = useAllowanceMutation(TokenEnum.HEDI);

  const isEmpty = BigNumber(etdGroups?.length || 0).isZero() && isFetched;
  const activeEtdGroup = etdGroups?.[etdGroupIdx];
  const disabled = !etdAmount.length || pending || !etdGroups;

  const swapInfo = useMemo(() => {
    const fee = BigNumber(activeEtdGroup?.haiAmount || 0)
      .times(data || 0)
      .div(100)
      .toFixed(0);
    const haiAmount = BigNumber(activeEtdGroup?.haiAmount || 0)
      .minus(fee)
      .times(etdAmount.length ? +etdAmount : 0)
      .toFixed(0);
    return {
      fee,
      haiAmount,
    };
  }, [activeEtdGroup, etdAmount]);
  const isEnough = useMemo(() => {
    if (!activeEtdGroup) return false;
    return (
      new BigNumber(activeEtdGroup.tokens.length).gte(
        etdAmount.length ? etdAmount : 0
      ) && !BigNumber(activeEtdGroup.tokens.length).isZero()
    );
  }, [activeEtdGroup, etdAmount]);
  const isTokenApproved = useMemo(() => {
    return new BigNumber(tokenAllowance || 0).gt(
      etdAmount.length ? etdAmount : 0
    );
  }, [tokenAllowance, etdAmount]);

  const btnText = useMemo(() => {
    if (isEnough && isTokenApproved) {
      return 'Swap';
    } else if (!isTokenApproved) {
      return 'Approve';
    } else if (!isEnough) {
      return 'Insufficient Funds';
    }
  }, [isEnough, isTokenApproved]);
  const btnDisabled = useMemo(() => {
    if (isEnough && isTokenApproved) {
      return !etdAmount.length || BigNumber(etdAmount).isZero();
    } else if (!isEnough) {
      return true;
    }
    return false;
  }, [isEnough, etdAmount, isTokenApproved]);

  const onSwap = () => {
    if (activeEtdGroup && !disabled) {
      setPending(true);
      const tokenIds = activeEtdGroup.tokens.slice(0, +etdAmount);
      console.log('tokenIds', tokenIds);
      sell
        .mutateAsync({ amount: tokenIds })
        .then((tx) => {
          console.log('Successfully swapped! Tx:', tx);
          setTxHash(tx.hash);
          setShowSuccess(true);
        })
        .catch((e) => {
          console.error('Buy HEDI error:', e);
          if (e.message && e.message.includes('ACTION_REJECTED')) {
            //   TODO: User rejection
          } else if (
            e.message &&
            e.message.includes('TokenExchange: token is locked')
          ) {
            toast.error('Token is locked! Wait for 30 days to unlock.');
          } else {
            toast.error('Transaction failed!');
          }
        })
        .finally(() => {
          setPending(false);
        });
    } else {
      toast.error('Transaction failed!');
    }
  };

  const onApprove = () => {
    setPending(true);
    mutateTokenAllowance(constants.MaxUint256.toString(), {
      onSettled: () => {
        setPending(false);
      },
    });
  };
  const onSubmit = () => {
    if (isTokenApproved) {
      onSwap();
    } else {
      onApprove();
    }
  };

  return (
    <Root>
      {showSuccess ? (
        <SwapSuccess amountHAI={swapInfo.haiAmount} amountHEDI={etdAmount} />
      ) : isEmpty ? (
        <SwapEmpty />
      ) : (
        <>
          <Header>
            <Title>Swap HEDI to HAI</Title>
          </Header>
          <Content>
            <SelectHEDI
              value={etdGroupIdx}
              onChange={setEtdGroupIdx}
              data={etdGroups || []}
            />
            <InputAmount
              token={TokenEnum.HEDI}
              onChange={setEtdAmount}
              max={activeEtdGroup?.tokens.length}
            />
          </Content>
          <Content>
            <>
              <SwapFee>{`${data || '--'}% Fee: ${formatToken(
                swapInfo.fee,
                TokenEnum.HAI,
                { decimals: 0 }
              )} HAI`}</SwapFee>
              <SwapTotalValue>
                You’ll receive:
                <span>
                  {
                    formatToken(swapInfo.haiAmount, TokenEnum.HAI, {
                      decimals: 0,
                    }) as string
                  }{' '}
                  HAI
                </span>
              </SwapTotalValue>
            </>
          </Content>
          <Footer>
            <ConnectButton
              disabled={btnDisabled}
              onClick={onSubmit}
              actionName={btnText}
              pending={pending}
            />
          </Footer>
        </>
      )}
    </Root>
  );
};

export default SwapForm;
