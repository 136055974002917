import { Components } from '@mui/material';

import { colors } from 'utils/colors';

export const MuiButtonTheme: Components['MuiButton'] = {
  defaultProps: {
    variant: 'contained',
    color: 'primary',
  },
  styleOverrides: {
    root: ({ ownerState }) => ({
      borderRadius: '0',
      boxShadow: 'none',
      textTransform: 'none',
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: '32px',
      color: colors.white,

      ...(ownerState.variant === 'contained' &&
        ownerState.color === 'primary' && {
          backgroundColor: colors.blue,
          color: colors.white,

          '&:hover': {
            backgroundColor: colors.blueDark,
          },
          '&:active': {
            backgroundColor: colors.blueMedium,
          },
          '&.Mui-disabled': {
            background: colors.transparentBlue,
            color: colors.gray,
          },
        }),

      ...(ownerState.variant === 'contained' &&
        ownerState.color === 'success' && {
          backgroundColor: colors.accentGreen,
          color: colors.black,

          '&:hover': {
            backgroundColor: colors.accentGreen,
          },
        }),

      ...(ownerState.variant === 'contained' &&
        ownerState.color === 'error' && {
          backgroundColor: colors.red,

          '&:hover': {
            backgroundColor: colors.red,
          },
        }),

      ...(ownerState.variant === 'outlined' &&
        ownerState.color === 'primary' && {
          border: `2px solid ${colors.blue}`,
          backgroundColor: 'transparent',
          color: colors.blue,

          '&:hover': {
            backgroundColor: colors.blueDark,
            border: `2px solid ${colors.blueDark}`,
            color: colors.white,
          },
          '&:active': {
            backgroundColor: colors.blueMedium,
            border: `2px solid ${colors.blueMedium}`,
          },
          '&.Mui-disabled': {
            background: 'transparent',
            border: `2px solid ${colors.grayLight}`,
            color: colors.grayLight,
          },
        }),
      ...(ownerState.variant === 'outlined' &&
        ownerState.color === 'secondary' && {
          border: `1px solid ${colors.black}`,
          backgroundColor: 'transparent',
          color: colors.black,

          '&:hover': {
            backgroundColor: colors.grayDark,
            border: `1px solid ${colors.grayDark}`,
            color: colors.white,
          },
          '&:active': {
            backgroundColor: colors.black,
            border: `1px solid ${colors.black}`,
            color: colors.white,
          },
          '&.Mui-disabled': {
            background: 'transparent',
            border: `1px solid ${colors.grayLight}`,
            color: colors.grayLight,
          },
        }),
    }),
  },
};
