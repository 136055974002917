import styled from '@emotion/styled';
import {
  Box as BoxUnstyled,
  Button,
  IconButton as IconButtonUnstyled,
  Modal as ModalUnstyled,
  modalClasses,
} from '@mui/material';
import { parseStyledOptions } from '../../../utils/styled';
import { colors } from '../../../utils/colors';
import { FONT_FAMILY } from '../../../utils/consts';
import { RequestQuoteBtn } from '../../RequestQuoteBtn/RequestQuoteBtn.styled';

export const Modal = styled(
  ModalUnstyled,
  parseStyledOptions({ label: 'RequestQuoteBtn--Modal' }),
)`
    overflow: auto;
    
    & > .${modalClasses.backdrop} {
        backdrop-filter: blur(5px);
    }
`;

export const ContentWrap = styled(
  BoxUnstyled,
  parseStyledOptions({ label: 'RequestQuoteBtn--ContentWrap' }),
)`
    margin: auto;
    padding: 24px 10px;
    width: 100%;
    outline: none;
    display: flex;
    justify-content: center;

    @media screen and (min-width: 768px) {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 0 45px;
    }
`;

export const Content = styled(
  'div',
  parseStyledOptions({ label: 'RequestQuoteBtn--Content' }),
)`
    padding: 48px 11px 33px;
    flex-basis: 840px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${colors.white};

    ::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 110px;
        width: 100%;
        height: 1px;
        background: ${colors.additionalGray};
        display: none;

        @media screen and (min-width: 768px) {
            bottom: 130px;
            display: initial;
        }
    }

    @media screen and (min-width: 768px) {
        padding: 48px 96px 33px;
    }
`;
export const Partners = styled(
  'div',
  parseStyledOptions({ label: 'RequestQuoteBtn--Partners' }),
)`
    padding: 20px 17px 0;
    flex-basis: 312px;
    flex-shrink: 0;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 48px;
    background: ${colors.black};

    @media screen and (min-width: 1024px) {
        display: flex;
    }
`;

export const Title = styled(
  'h3',
  parseStyledOptions({ label: 'RequestQuoteBtn--Title' }),
)`
    margin-bottom: 24px;
    font-family: ${FONT_FAMILY.secondary};
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: ${colors.black};

    @media screen and (min-width: 768px) {
        margin-bottom: 48px;
        font-size: 28px;
        line-height: 36px;
    }
`;

export const CloseBtn = styled(
  IconButtonUnstyled,
  parseStyledOptions({ label: 'RequestQuoteBtn--CloseBtn' }),
)`
    position: absolute;
    right: 14px;
    top: 14px;
    color: ${colors.black};
    border-radius: 0;

    :hover,
    :focus {
        background: transparent;
    }

    svg {
        width: 20px;
        height: 20px;
    }

    @media screen and (min-width: 768px) {
        right: 30px;
        top: 30px;
    }
`;

export const SubTitle = styled(
  'p',
  parseStyledOptions({ label: 'RequestQuoteBtn--SubTitle' }),
)`
    font-family: ${FONT_FAMILY.secondary};
    font-weight: 400;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    color: ${colors.white};

    span {
        font-size: 40px;
        line-height: 48px;
        color: ${colors.accentGreen};
    }
`;

export const PartnersList = styled(
  'div',
  parseStyledOptions({ label: 'RequestQuoteBtn--PartnersList' }),
)`
    width: 100%;
    max-width: 224px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    row-gap: 8px;
    column-gap: 16px;
`;

export const PartnersListItem = styled(
  'div',
  parseStyledOptions({ label: 'RequestQuoteBtn--PartnersListItem' }),
)<{ $whiteSvgPath?: boolean }>`
    overflow: hidden;
    height: 40px;
    flex-basis: 64px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${colors.white};

    svg {
        transform: scale(1.15);

        ${({ $whiteSvgPath }) =>
                $whiteSvgPath
                        ? `
          path {
            fill: ${colors.white};
          }
        `
                        : ''}
    }
`;

export const SuccessContent = styled(
  'div',
  parseStyledOptions({ label: 'RequestQuoteBtn--SuccessContent' }),
)`
    padding: 48px 0 24px;
    max-width: 840px;
    width: 100%;
    min-height: 400px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: ${colors.white};
`;

export const SuccessTitle = styled(
  'p',
  parseStyledOptions({ label: 'RequestQuoteBtn--SuccessTitle' }),
)`
    margin: 12px auto 32px;
    padding: 0 10px;
    width: 100%;
    display: block;
    text-align: center;
    font-family: ${FONT_FAMILY.secondary};
    font-weight: 400;
    font-size: 28px;
    line-height: 36px;
    color: ${colors.black};

    @media screen and (min-width: 435px) {
        margin-bottom: 4px;
    }
`;

export const SuccessSubText = styled(
  'span',
  parseStyledOptions({ label: 'RequestQuoteBtn--SuccessSubText' }),
)`
    margin: 0 auto 24px;
    padding-bottom: 49px;
    width: 100%;
    display: block;
    border-bottom: 1px solid ${colors.additionalGray};
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: ${colors.black};
`;

export const SuccessButton = styled(
  RequestQuoteBtn,
  parseStyledOptions({ label: 'RequestQuoteBtn--SuccessButton' }),
)`
    width: 260px;
    height: 64px;
    font-size: 16px;
` as typeof Button;

export const Text = styled(
  'span',
  parseStyledOptions({ label: 'RequestQuoteBtn--Text' }),
)`
    margin-right: 8px;
`;
