import React, { lazy, Suspense } from 'react';
import {
  ActionWrapper,
  BuyHEDIWrapper,
  IntroContent,
  IntroDescription,
  IntroRiskSpan,
  IntroSection,
  IntroTitle,
  IntroTitleWrapper,
  IntroWrapper,
  Root,
} from './Main.styled';
import Breadcrumbs from 'components/Breadcrumbs';
const BuyForm = lazy(() => import('components/forms/BuyForm'));
import SectionHowItWorks from '../../components/SectionHowItWorks';
import SectionWhyNeed from '../../components/SectionWhyNeed';
import SectionBenefits from '../../components/SectionBenefits';
import SectionHAIValue from '../../components/SectionHAIValue';
import FooterActions from '../../components/FooterActions';
import BuyButton from '../../components/BuyButton';
import { TokenEnum } from '../../types/enum/token';

const breadcrumbs = [
  {
    text: 'Hacken',
    to: 'https://hacken.io/',
    isActive: true,
  },
  {
    text: 'HEDI',
    to: '/',
  },
];

const MainScreen: React.FC = () => {
  return (
    <Root>
      <IntroSection id={'about'}>
        <IntroWrapper>
          <IntroContent>
            <Breadcrumbs list={breadcrumbs} color={'accentGreen'} />
            <IntroTitleWrapper>
              <IntroTitle>{'Hacken'}</IntroTitle>
              <IntroTitle>{'Engineers'}</IntroTitle>
              <IntroTitle>{'Day Index'}</IntroTitle>
            </IntroTitleWrapper>
            <IntroDescription>
              Price-protected investment opportunity in $HAI token via a
              synthetic asset backed by the services of Web3 security experts.
            </IntroDescription>
            <IntroRiskSpan>
              1 {TokenEnum.HEDI} = {process.env.REACT_APP_PRICE_USDT}{' '}
              {TokenEnum.USDT}
            </IntroRiskSpan>
          </IntroContent>
          <ActionWrapper id={'buy'}>
            <Suspense fallback={''}>
              <BuyForm />
            </Suspense>
          </ActionWrapper>
        </IntroWrapper>
      </IntroSection>
      <SectionBenefits />
      <BuyHEDIWrapper>
        <BuyButton />
      </BuyHEDIWrapper>
      <SectionWhyNeed />
      <SectionHowItWorks />
      <SectionHAIValue />
      <FooterActions />
    </Root>
  );
};

export default MainScreen;
