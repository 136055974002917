export enum TokenEnum {
  HAI = 'HAI',
  USDT = 'USDT',
  BNB = 'BNB',
  HEDI = 'HEDI',
}
export enum WalletStatus {
  connected = 'connected',
  disconnected = 'disconnected',
}
