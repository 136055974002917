import React, { useState } from 'react';
import useToggle from 'react-use/lib/useToggle';

import { ReactComponent as ArrowRight } from 'assets/icons/arrow-nav.svg';

import { RequestQuoteBtn, Text } from './RequestQuoteBtn.styled';
import RequestModal from '../modals/RequestModal';

const RequestQuoteButton: React.FC<Props> = ({
  formName,
  formNode,
  title,
  mainBtnText,
  mainBtn,
  hidePartners,
}) => {
  const [showModal, toggleShowModal] = useToggle(false);

  const closeModal = () => {
    toggleShowModal(false);
  };

  return (
    <>
      {mainBtn?.({ onClick: () => toggleShowModal(!showModal) }) || (
        <RequestQuoteBtn
          variant='contained'
          endIcon={<ArrowRight />}
          onClick={toggleShowModal}
        >
          <Text>{mainBtnText || 'Request a quote'}</Text>
        </RequestQuoteBtn>
      )}

      <RequestModal
        title={title}
        showModal={showModal}
        closeModal={closeModal}
        formName={formName}
        hidePartners={hidePartners}
      />
    </>
  );
};

export type RequestQuoteButtonFormNodeDeps = {
  toggleSuccessScreen?: (v: boolean) => void;
  handleReCaptchaVerify?: () => Promise<string | undefined>;
};

type Props = {
  title?: React.ReactNode;
  mainBtnText?: React.ReactNode;
  mainBtn?: (deps?: { onClick?: () => void }) => React.ReactNode;
  formName?: string;
  formNode?: (d: RequestQuoteButtonFormNodeDeps) => React.ReactNode;
  hidePartners?: boolean;
};

export default RequestQuoteButton;
