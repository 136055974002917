import { Components } from '@mui/material';

import { colors } from 'utils/colors';

export const MuiInputTheme: Components['MuiInput'] = {
  styleOverrides: {
    root: {
      padding: 0,

      '::before': {
        borderColor: colors.black,
      },
      ':hover:not(.Mui-disabled, .Mui-error):before': {
        borderColor: colors.black,
      },
      '::after': {
        display: 'none',
      },
      ':focus-within:not(.Mui-disabled, .Mui-error):before': {
        borderWidth: '1px',
      },

      '&.Mui-error::before': {
        borderColor: colors.red,
      },
    },
  },
};
