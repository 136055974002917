import styled from '@emotion/styled';
import { parseStyledOptions } from '../../../utils/styled';
import { colors } from '../../../utils/colors';
import { H1, H2, Paragraph } from '../../SectionTypography/styled';
import { ButtonDefault } from '../../../styles/Global.styled';
import { Link } from 'react-router-dom';

export const Root = styled(
  'div',
  parseStyledOptions({ label: 'SwapForm-Root' })
)`
  max-width: 470px;
  width: 100%;
  background: ${colors.white};
`;
export const Content = styled(
  'div',
  parseStyledOptions({ label: 'SwapForm-Content' })
)`
  padding: 24px;
  border-bottom: solid 1px ${colors.additionalGray};
  @media screen and (min-width: 768px) {
    padding: 24px 48px;
  }
`;

export const Header = styled(
  Content,
  parseStyledOptions({ label: 'SwapForm-Header' })
)`
  padding: 24px;
  @media screen and (min-width: 768px) {
    padding: 48px 48px 24px;
  }
`;

export const Title = styled(
  H1,
  parseStyledOptions({ label: 'SwapForm-Title' })
)`
  font-size: 24px;
  line-height: 30px;
  color: ${colors.black};

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

export const Footer = styled(
  'div',
  parseStyledOptions({ label: 'SwapForm-Footer' })
)`
  display: flex;
  justify-content: center;
  padding: 24px;
  border-bottom: none;

  button {
    width: 100%;
  }

  @media screen and (min-width: 768px) {
    padding: 24px 48px;
  }
`;
export const SwapButton = styled(
  ButtonDefault,
  parseStyledOptions({ label: 'SwapForm-SwapButton' })
)`
  font-size: 16px;
  line-height: 26px;
`;

export const SwapFee = styled(
  'p',
  parseStyledOptions({ label: 'SwapForm-SwapFee' })
)`
  font-size: 12px;
  line-height: 20px;
  color: ${colors.grayDark};
  margin-bottom: 4px;
  text-align: center;
`;

export const SwapTotalValue = styled(
  Paragraph,
  parseStyledOptions({ label: 'SwapForm-SwapTotalValue' })
)`
  color: ${colors.grayDark};
  text-align: center;

  span {
    color: ${colors.black};
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    margin-left: 10px;
  }
`;
export const EmptyTitle = styled(
  H2,
  parseStyledOptions({ label: 'SwapForm-EmptyTitle' })
)`
  color: ${colors.red};
  text-align: center;
  margin-bottom: 4px;

  @media screen and (min-width: 768px) {
    font-size: 28px;
    line-height: 36px;
  }
`;

export const EmptyText = styled(
  Paragraph,
  parseStyledOptions({ label: 'SwapForm-EmptyText' })
)`
    text-align: center;
`;

export const EmptyContent = styled(
  Content,
  parseStyledOptions({ label: 'SwapForm-EmptyContent' })
)`
  padding: 48px 30px 18px;
`;

export const SuccessContent = styled(
  Content,
  parseStyledOptions({ label: 'SwapForm-SuccessContent' })
)`
    padding: 24px;
    @media screen and (min-width: 768px) {
        padding: 48px 45px 18px;
    }
`;

export const SuccessTitle = styled(
  EmptyTitle,
  parseStyledOptions({ label: 'SwapForm-SuccessTitle' })
)`
  color: ${colors.black};
`;

export const ImageWrapper = styled(
  'div',
  parseStyledOptions({ label: 'SwapForm-ImageWrapper' })
)`
  margin: 0 auto 12px;
  max-width: 182px;
  max-height: 136px;

  & img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;
