import styled from '@emotion/styled';
import { parseStyledOptions } from '../../utils/styled';
import { EmptyText } from '../../components/forms/SwapForm/SwapForm.styled';

export const Text = styled(
  EmptyText,
  parseStyledOptions({ label: 'TransferScreen-Text' })
)<{$overflow?: boolean}>`
    max-width: 100%;
    
    ${({ $overflow }) => $overflow && `
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    `}
`;
