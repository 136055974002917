import { Components } from '@mui/material';

import { colors } from 'utils/colors';

export const MuiLinearProgressTheme: Components['MuiLinearProgress'] = {
  styleOverrides: {
    root: {
      borderRadius: '8px',
      backgroundColor: colors.grayMedium,
    },
    bar: {
      borderRadius: '8px',
      backgroundColor: colors.accentGreen,
    },
  },
};
