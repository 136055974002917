import { useEffect } from 'react';
import { Connector } from '@web3-react/types';
import { getConnection } from '../index';
import { ConnectionType, WALLET_STATUS_KEY } from '../config';
import { WalletStatus } from '../../types/enum/token';

async function connect(connector: Connector) {
  try {
    if (connector.connectEagerly) {
      await connector.connectEagerly();
    } else {
      await connector.activate();
    }
  } catch (error) {
    console.debug(`web3-react eager connection error: ${error}`);
  }
}

export default function useEagerlyConnect() {
  useEffect(() => {
    const status = localStorage.getItem(WALLET_STATUS_KEY);
    if (status === WalletStatus.connected) {
      connect(getConnection(ConnectionType.INJECTED).connector).then();
    }
  }, []);
}
