import React, { useEffect } from 'react';
import RouterApp from './router';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import 'assets/fonts/PPMori/PPMori-SemiBold.woff';
import 'assets/fonts/PPMori/PPMori-Regular.woff';
import 'assets/fonts/PPNeueMachina/PPNeueMachina-InktrapLight.woff';
import 'assets/fonts/PPNeueMachina/PPNeueMachina-InktrapRegular.woff';
import MaterialProvider from './containers/layouts/MaterialProvider';
import sbjs from 'sourcebuster';

const queryClient = new QueryClient();

function App() {
  useEffect(() => {
    sbjs.init();
  }, []);
  return (
    <MaterialProvider>
      <QueryClientProvider client={queryClient}>
        <RouterApp />
      </QueryClientProvider>
    </MaterialProvider>
  );
}

export default App;
