import styled from '@emotion/styled';

import { colors } from 'utils/colors';
import { parseStyledOptions } from 'utils/styled';

import { FONT_FAMILY } from 'utils/consts';

export const FooterBlock = styled(
  'footer',
  parseStyledOptions({ label: 'Footer-FooterBlock' })
)`
  overflow: hidden;
  background-color: ${colors.black};

  @media screen and (min-width: 768px) {
    border-top: 1px solid #2d2e30;
  }
`;
export const TitleWrapper = styled(
  'div',
  parseStyledOptions({ label: 'Footer-TitleWrapper' })
)`
  padding: 64px 0;
  @media screen and (min-width: 1440px) {
    padding: 128px 0;
    border-bottom: 1px solid ${colors.extraDarkGray};
  }
`;
export const FooterTitle = styled(
  'h2',
  parseStyledOptions({ label: 'Footer-Title' })
)`
  margin-bottom: 36px;
  text-align: center;
  font-family: ${FONT_FAMILY.secondary};
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 1.2;
  color: ${colors.white};

  & span {
    color: ${colors.accentGreen};
  }
`;

export const NavBar = styled(
  'nav',
  parseStyledOptions({ label: 'Footer-NavBar' })
)`
  @media screen and (min-width: 768px) {
    max-width: 1440px;
    margin: 0 auto;
  }
`;
export const NavWrapper = styled(
  'div',
  parseStyledOptions({ label: 'Footer-NavWrapper' })
)`
  margin-bottom: 32px;
  @media screen and (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0 42px 0;
  }
  @media screen and (min-width: 1201px) {
    justify-content: space-between;
  }
  @media screen and (min-width: 1440px) {
    padding: 0 96px 0;
  }
`;
export const CommonNavWrapper = styled(
  'div',
  parseStyledOptions({ label: 'Footer-CommonNavWrapper' })
)`
  @media screen and (max-width: 767px) {
    &:first-of-type {
      border-top: 1px solid ${colors.extraDarkGray};
      border-bottom: 1px solid ${colors.extraDarkGray};
    }
  }
  @media screen and (min-width: 768px) {
    padding: 32px 0 0;

    &:nth-of-type(1) {
      min-width: 225px;
      max-width: 316px;
      margin-right: 50px;
    }

    &:nth-of-type(2) {
      min-width: 295px;
      max-width: 316px;
      display: flex;
      margin-right: 50px;
    }
  }
  @media screen and (min-width: 1440px) {
    &:nth-of-type(1) {
      min-width: 36%;
      margin: 0;
      padding-left: 80px;
      border-right: 1px solid ${colors.extraDarkGray};
      border-left: 1px solid ${colors.extraDarkGray};
    }
    &:nth-of-type(2) {
      min-width: 30%;
      margin: 0;
    }
  }
`;
export const MenuItem = styled(
  'div',
  parseStyledOptions({ label: 'Footer-MenuItem' })
)`
  @media screen and (max-width: 767px) {
    border-bottom: 1px solid ${colors.extraDarkGray};
  }
`;
export const MenuItemWrapper = styled(
  'div',
  parseStyledOptions({ label: 'Footer-MenuItemWrapper' })
)`
  @media screen and (min-width: 768px) {
    & > div {
      margin-bottom: 40px;
      margin-right: 28px;
    }
  }
`;
export const ChevronWrapper = styled(
  'div',
  parseStyledOptions({ label: 'Footer-ChevronWrapper' })
)`
  @media screen and (max-width: 767px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 32px;
  }
`;
export const Title = styled(
  'h3',
  parseStyledOptions({ label: 'Footer-Title' })
)`
  font-family: ${FONT_FAMILY.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.57;
  color: ${colors.white};
  @media screen and (max-width: 767px) {
    padding: 12px 0;
  }
  @media screen and (min-width: 768px) {
    max-width: 200px;
    margin-bottom: 24px;
    font-weight: 400;
  }
`;
export const MobBtn = styled(
  'button',
  parseStyledOptions({ label: 'Footer-MobBtn' })
)`
  @media screen and (max-width: 767px) {
    display: flex;
  }
  @media screen and (min-width: 768px) {
    display: none;
  }
`;
export const HiddenNav = styled(
  'div',
  parseStyledOptions({ label: 'Footer-HiddenNav' })
)`
  @media screen and (max-width: 767px) {
    padding: 0 32px;
    //height: 0;
    overflow: hidden;
    transition: height 0.4s;
  }
`;
export const NavList = styled(
  'ul',
  parseStyledOptions({ label: 'Footer-NavList' })
)`
  font-family: ${FONT_FAMILY.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.67;
  @media screen and (min-width: 768px) {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 10px;
    grid-template-rows: repeat(9, auto);
  }
`;
export const NavItem = styled(
  'li',
  parseStyledOptions({ label: 'Footer-NavItem' })
)`
  color: ${colors.gray};
  margin-bottom: 12px;
  & a {
    transition: color 250ms ease-in-out;
    &:hover {
      color: ${colors.white};
    }
  }
`;
export const TermsWrapper = styled(
  'div',
  parseStyledOptions({ label: 'Footer-TermsWrapper' })
)`
  @media screen and (max-width: 767px) {
    margin-top: 32px;
    padding: 0 32px;
  }
  @media screen and (min-width: 768px) {
    max-width: 308px;
    padding: 32px 0 0;
  }
  @media screen and (min-width: 1440px) {
    max-width: 312px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 32px 38px;
    border-left: 1px solid ${colors.extraDarkGray};
    border-right: 1px solid ${colors.extraDarkGray};
  }
`;
export const Terms = styled(
  'div',
  parseStyledOptions({ label: 'Footer-Terms' })
)``;
export const Description = styled(
  'p',
  parseStyledOptions({ label: 'Footer-Description' })
)`
  margin-bottom: 16px;
  font-family: ${FONT_FAMILY.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.57;
  color: ${colors.gray};

  @media screen and (min-width: 768px) {
    font-weight: 400;
    margin-bottom: 24px;
    color: ${colors.grayDark};
  }

  @media screen and (min-width: 1440px) {
    font-size: 12px;
  }
`;
export const PrivacyList = styled(
  'ul',
  parseStyledOptions({ label: 'Footer-PrivacyList' })
)`
  font-family: ${FONT_FAMILY.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.67;
  color: ${colors.gray};
`;
export const PrivacyItem = styled(
  'li',
  parseStyledOptions({ label: 'Footer-PrivacyItem' })
)`
  margin-bottom: 8px;

  &:last-of-type {
    margin-bottom: 32px;
  }

  @media screen and (min-width: 768px) {
    margin-bottom: 16px;
  }
`;
export const NetworksList = styled(
  'ul',
  parseStyledOptions({ label: 'Footer-NetworksList' })
)`
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 12px;

  @media screen and (min-width: 1440px) {
    justify-content: flex-end;
  }
`;
export const NetworkItem = styled(
  'li',
  parseStyledOptions({ label: 'Footer-NetworkItem' })
)`
  width: 48px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${colors.grayDark};

  a {
    flex: 1;
    max-height: 100%;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${colors.white};
  }
`;
export const TwitterCircle = styled(
  'div',
  parseStyledOptions({ label: 'Footer-TwitterCircle' })
)`
  width: 24.3px;
  height: 24.3px;
  border-radius: 50%;
  background-color: ${colors.white};

  svg {
    color: ${colors.black};
    transform: scale(0.65);
  }
`;
export const LogosList = styled(
  'ul',
  parseStyledOptions({ label: 'Footer-LogosList' })
)`
  margin-left: 1px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 30px;
  position: relative;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: -1px;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    height: 1px;
    background: ${colors.extraDarkGray};
  }

  &::after {
    top: initial;
    bottom: 0;
  }

  @media screen and (min-width: 768px) {
    padding: 0 42px 0;
  }
  @media screen and (min-width: 1080px) {
    margin-top: 0;
  }
  @media screen and (min-width: 1440px) {
    padding: 0 96px 0;
  }
`;
export const LogoItem = styled(
  'li',
  parseStyledOptions({ label: 'Footer-LogoItem' })
)`
  min-height: 80px;
  min-width: 180px;
  flex: 1;
  border: 1px solid ${colors.extraDarkGray};
  margin: -1px 0 0 -1px;

  & a {
    padding: 16px 18px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${colors.grayDark};
    transition: 250ms ease-in-out color;

    :hover {
      color: ${colors.grayMedium};
    }
  }

  @media screen and (min-width: 1440px) {
    align-items: center;
    justify-content: center;
    display: flex;
    min-height: 12px;
    padding: 16px 18px;
    border: 1px solid ${colors.extraDarkGray};

    & a {
      & svg {
        transition: opacity 250ms ease-in-out;
      }

      &:hover {
        & svg {
          opacity: 0.7;
        }
      }
    }
  }
`;
