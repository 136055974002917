import React from 'react';
import {
  Section,
  SectionContainer,
  StepContent,
  StepImgWrapper,
  StepItem,
  StepNumber,
  StepText,
  Title,
  ValueItem,
  ValueListWrapper,
  ValueTitle,
  ValueList,
  WrapperSteps,
  ImpactWrapper,
  ImpactItem,
  ImpactImgWrapper,
  ImpactContent,
  SubTitle,
  ImpactDescription,
} from './SectionHAIValue.styled';
import { ReactComponent as BoxIcon } from 'assets/icons/box.svg';
import { ReactComponent as VCIcon } from 'assets/icons/vc.svg';
import { ReactComponent as CircleIcon } from 'assets/icons/arrow-circle.svg';

import Image1 from 'assets/images/how-etd-works/how-etd-works-1.png';
import Image2 from 'assets/images/how-etd-works/how-etd-works-2.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { H2Positioned } from '../SectionTypography/styled';
import TokenImg from '../../assets/images/token.png';
import SwapImg from '../../assets/images/swap.png';
import DicountImg from '../../assets/images/discount.png';
import FireImg from '../../assets/images/fire.png';
import SwapBackImg from '../../assets/images/swap-back.png';
import WalletImg from '../../assets/images/wallets.png';

const haiValueList = [
  {
    text: 'Organic market buying from DEX',
    icon: BoxIcon,
  },
  {
    text: 'Permanent liquidity growth',
    icon: VCIcon,
  },
  {
    text: 'Deflationary mechanisms',
    icon: CircleIcon,
  },
];
const stepsList = [
  {
    text: 'Purchase HEDI at a fixed USDT price to receive each as a unique NFT.',
    img: WalletImg,
  },
  {
    text: 'The HEDI smart contract automatically allocates 2/3 of the received USDT to repurchase $HAI from the market.',
    img: SwapImg,
  },
  {
    text: 'Half of the acquired HAI (1/3 from HEDI cost) with the remaining USDT (1/3 from HEDI cost) are automatically added to the HAI/USDT DEX liquidity pool.',
    img: DicountImg,
  },
  {
    text: 'Half of the acquired HAI (1/3 of HEDI cost) is automatically burned.',
    img: FireImg,
  },
  {
    text: 'Receive your HEDI, with its value backed by the allocation of HAI from the treasury.',
    img: TokenImg,
  },
  {
    text: 'Swap HEDI back to $HAI with a 10% fee after 30 days, or use it for paying Hacken services at any time.',
    img: SwapBackImg,
  },
];
const impactList = [
  {
    text: '67% of HEDI sales proceeds automatically repurchase $HAI from the market, with the remaining 33% being deposited into AMM HAI/USDT liquidity pool.',
    img: Image1,
  },
  {
    text: 'HEDI emerges as a key driver in shaping the growth and sustainability of $HAI within the web3 landscape.',
    img: Image2,
  },
];

const SectionHAIValue: React.FC = () => {
  return (
    <Section id={'hai-values'}>
      <H2Positioned style={{ textAlign: 'center' }}>
        HEDI purchase, minting, and usage models are designed to channel all B2B
        success into HAI value
      </H2Positioned>
      <SectionContainer>
        <ValueListWrapper>
          <ValueList>
            {haiValueList.map((value, idx) => (
              <ValueItem key={`value-${idx}`}>
                <value.icon />
                <ValueTitle>{value.text}</ValueTitle>
              </ValueItem>
            ))}
          </ValueList>
        </ValueListWrapper>
      </SectionContainer>
      <H2Positioned style={{ textAlign: 'center' }}>
        How HEDI smart contract works?
      </H2Positioned>
      <SectionContainer>
        <WrapperSteps>
          {stepsList.map((step, idx) => (
            <StepItem key={`step-${idx}`}>
              <StepImgWrapper>
                <LazyLoadImage src={step.img} alt={`Step ${idx + 1}`} />
              </StepImgWrapper>
              <StepContent>
                <StepNumber>{idx + 1}</StepNumber>
                <StepText>{step.text}</StepText>
              </StepContent>
            </StepItem>
          ))}
        </WrapperSteps>
      </SectionContainer>
      <Title>Unveiling the Impact of HEDI on HAI</Title>
      <SubTitle>
        HEDI serves as a pivotal algorithmic instrument, ensuring continual
        liquidity growth
      </SubTitle>
      <SectionContainer>
        <ImpactWrapper>
          {impactList.map((impact, idx) => (
            <ImpactItem key={`impact-${idx}`}>
              <ImpactImgWrapper>
                <LazyLoadImage src={impact.img} alt={`Impact ${idx + 1}`} />
              </ImpactImgWrapper>
              <ImpactContent>{impact.text}</ImpactContent>
            </ImpactItem>
          ))}
        </ImpactWrapper>
        <ImpactDescription>
          The more HEDI you acquire, the more you contribute to the upward
          trajectory of $HAI, creating a powerful and rewarding cycle of growth
        </ImpactDescription>
      </SectionContainer>
    </Section>
  );
};

export default SectionHAIValue;
