import React from 'react';
import { GroupHEDI } from '../../types/token';
import {
  ArrowIcon,
  Container,
  SelectGroup,
  SelectItem,
  SelectItemLabel,
  SelectItemPrice,
} from './SelectHEDI.styled';
import { formatToken } from '../../utils/formatter';

interface Props {
  data: GroupHEDI[];
  value?: number;
  onChange?: (value: number) => void;
}

const SelectHEDI: React.FC<Props> = ({ data, onChange, value }) => {
  const activeValue = typeof value !== 'undefined' ? data[value] : undefined;

  return (
    <Container>
      <SelectGroup
        value={value}
        displayEmpty
        variant={'standard'}
        sx={{
          mb: '12px',
        }}
        onChange={(event) => {
          const item = event.target.value as number;
          if (onChange) {
            onChange(item);
          }
        }}
        IconComponent={(props) => <ArrowIcon {...props} />}
        renderValue={(val?: number) => (
          <>
            <SelectItemLabel>
              {typeof val !== 'undefined'
                ? data[val]?.tokens.length || '--'
                : '--'}{' '}
              HEDI tokens
            </SelectItemLabel>
            <SelectItemPrice>
              {`Purchased for ${
                activeValue
                  ? formatToken(activeValue.price, activeValue.sourceToken, {
                      decimals: 0,
                    })
                  : '--'
              } ${activeValue?.sourceToken || ''}`}
            </SelectItemPrice>
          </>
        )}
      >
        {data.map((item, index) => (
          <SelectItem value={index} key={index}>
            <SelectItemLabel>{item.tokens.length} HEDI tokens</SelectItemLabel>
            <SelectItemPrice>
              {`Purchased for ${formatToken(item.price, item.sourceToken, {
                decimals: 0,
              })} ${item.sourceToken}`}
            </SelectItemPrice>
          </SelectItem>
        ))}
      </SelectGroup>
    </Container>
  );
};

export default SelectHEDI;
