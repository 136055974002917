import React from 'react';
import Layout from '../components/layout';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainScreen from '../screens/main';
import TransferScreen from '../screens/transfer';
import routes from './routes';
// import HistoryScreen from '../screens/history';
import SwapScreen from '../screens/swap';

const RouterApp: React.FC = () => {
  return (
    <BrowserRouter basename={'/'}>
      <Layout>
        <Routes>
          <Route path={routes.MAIN} element={<MainScreen />} />
          <Route path={routes.TRANSFER} element={<TransferScreen />} />
          {/*<Route path={routes.HISTORY} element={<HistoryScreen />} />*/}
          <Route path={routes.SWAP} element={<SwapScreen />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
};

export default RouterApp;
