import React from 'react';
import { Root, Item, ArrowRightIcon, Link } from './Breadcrumbs.styled';

export type BreadcrumbsType = {
  text: React.ReactNode;
  to: string;
  isActive?: boolean;
};

type Props = {
  color?: 'accentGreen';
  list?: BreadcrumbsType[];
};

const Breadcrumbs: React.FC<Props> = ({ list, color }) => {
  return (
    <Root>
      {list?.map((item, i) => {
        return (
          <Item key={item.to} $canShrink={i === list.length - 1}>
            <Link
              href={item.to}
              tabIndex={item.isActive ? 0 : -1}
              aria-disabled={item.isActive ? undefined : true}
              $disabled={!item.isActive}
              $highlight={!item.isActive}
              $isAccentGreen={color === 'accentGreen'}
              $isInlineBlock={i === list.length - 1}
            >
              {item.text}

              {i !== list.length - 1 && <ArrowRightIcon />}
            </Link>
          </Item>
        );
      })}
    </Root>
  );
};

export default Breadcrumbs;
