import styled from '@emotion/styled';
import { parseStyledOptions } from '../../utils/styled';
import { colors } from '../../utils/colors';
import { ToastType } from 'react-hot-toast';
import { Paragraph } from '../SectionTypography/styled';
import { ReactComponent as Close } from 'assets/icons/close.svg';

export const ToastContainer = styled(
  'div',
  parseStyledOptions({
    label: 'Toast-ToastContainer',
  })
)<{
  $type: ToastType;
}>`
  display: flex;
  padding: 8px 16px;
  max-width: 350px;
  pointer-events: auto;
  background: ${({ $type }) =>
    $type === 'error' ? colors.red : colors.accentMediumGreen};
  svg {
    path {
      stroke: ${({ $type }) =>
        $type === 'error' ? colors.white : colors.black};
    }
  }
`;

export const ToastMessage = styled(
  Paragraph,
  parseStyledOptions({
    label: 'Toast-ToastMessage',
  })
)<{
  $type: ToastType;
}>`
  color: ${({ $type }) => ($type === 'error' ? colors.white : colors.black)};
`;

export const ToastErrIcon = styled(
  Close,
  parseStyledOptions({
    label: 'Toast-ToastErrIcon',
  })
)`
  cursor: pointer;
  margin-left: 16px;
`;
