import { parseStyledOptions } from '../../utils/styled';
import styled from '@emotion/styled';
import { colors } from '../../utils/colors';
import { FONT_FAMILY } from '../../utils/consts';

type HEDIAmountProgressProps = {
  $progress: string; // 0 - 100 %
};

export const Root = styled(
  'div',
  parseStyledOptions({ label: 'MainPage-Root' })
)`
  flex: 1;
`;

export const IntroSection = styled(
  'div',
  parseStyledOptions({ label: 'MainPage-IntroWrapper' })
)`
  background: ${colors.accentGreen};
`;
export const IntroWrapper = styled(
  'section',
  parseStyledOptions({ label: 'MainPage-IntroSection' })
)`
  display: flex;
  padding: 0 16px;
  margin: 0 auto;
  align-items: center;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    padding: 0 42px;
  }

  @media screen and (min-width: 1024px) {
    flex-direction: row;
  }

  @media screen and (min-width: 1440px) {
    padding: 0 96px;
    max-width: 1440px;
    display: flex;
  }
`;

export const IntroContent = styled(
  'div',
  parseStyledOptions({ label: 'MainPage-IntroContent' })
)`
  padding-top: 24px;
  @media screen and (min-width: 1024px) {
    width: 50%;
    padding-top: 36px;
    padding-bottom: 84px;
    padding-right: 32px;
  }
  @media screen and (min-width: 1440px) {
    border-right: solid 1px ${colors.accentLightGreen};
  }
`;
export const IntroTitleWrapper = styled(
  'div',
  parseStyledOptions({ label: 'MainPage-IntroTitleWrapper' })
)`
  margin: 16px 0;

  @media screen and (min-width: 768px) {
    margin: 32px 0;
  }
`;
export const IntroTitle = styled(
  'h1',
  parseStyledOptions({ label: 'MainPage-IntroTitle' })
)`
  font-family: ${FONT_FAMILY.secondary};
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 54px;
  color: ${colors.black};

  @media screen and (min-width: 768px) {
    text-align: center;
    & br {
      display: none;
    }
  }

  @media screen and (min-width: 1024px) {
    font-weight: 300;
    font-size: 64px;
    line-height: 80px;
    text-align: start;

    & br:first-of-type {
      display: block;
    }
  }

  @media screen and (min-width: 1440px) {
    font-weight: 300;
    font-size: 88px;
    line-height: 96px;

    & br:first-of-type {
      display: block;
    }
  }
`;
export const IntroDescription = styled(
  'p',
  parseStyledOptions({ label: 'MainPage-IntroDescription' })
)`
  margin-bottom: 24px;

  font-family: ${FONT_FAMILY.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: ${colors.black};

  @media screen and (min-width: 768px) {
    margin-bottom: 32px;
    max-width: 500px;
    text-align: center;
  }

  @media screen and (min-width: 1024px) {
    max-width: 512px;

    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    text-align: start;
  }
`;
export const IntroRiskSpan = styled(
  'span',
  parseStyledOptions({ label: 'MainPage-IntroRiskSpan' })
)`
  display: inline-flex;

  padding: 8px 16px;
  font-family: ${FONT_FAMILY.primary};
  font-weight: 600;
  font-size: 14px;
  line-height: 1.5;

  color: ${colors.black};
  background-color: ${colors.accentLightGreen};
`;

export const ActionWrapper = styled(
  'div',
  parseStyledOptions({ label: 'MainPage-ActionWrapper' })
)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 24px 0;

  @media screen and (min-width: 1024px) {
    justify-content: flex-end;
    width: 50%;
    padding: 48px 0 48px 32px;
  }
`;
export const AboutHeader = styled(
  'div',
  parseStyledOptions({ label: 'MainPage-AboutHeader' })
)`
  padding: 48px 16px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    padding: 42px;
  }

  @media screen and (min-width: 1440px) {
    padding: 96px;
    max-width: 1440px;
    flex-direction: row;
  }
`;
export const AboutTitle = styled(
  'h2',
  parseStyledOptions({ label: 'MainPage-AboutTitle' })
)`
  font-family: ${FONT_FAMILY.secondary};
  font-weight: 300;
  font-size: 40px;
  line-height: 48px;
  color: ${colors.black};
  text-align: center;

  @media screen and (min-width: 1024px) {
    text-align: start;
    margin-right: 105px;
    max-width: 670px;
  }
`;
export const HEDIAmountWrapper = styled(
  'div',
  parseStyledOptions({ label: 'MainPage-HEDIAmountWrapper' })
)`
  display: flex;
  align-items: center;

  padding: 16px 0;

  @media screen and (min-width: 768px) {
    padding: 0;
  }
`;
export const HEDIAmount = styled(
  'div',
  parseStyledOptions({ label: 'MainPage-HEDIAmount' })
)`
  margin-bottom: 4px;
  font-family: ${FONT_FAMILY.secondary};
  font-weight: 300;
  font-size: 48px;
  line-height: 54px;
  color: ${colors.black};
  text-align: right;
`;
export const HEDIAmountTotal = styled(
  'div',
  parseStyledOptions({ label: 'MainPage-HEDIAmountTotal' })
)`
  font-family: ${FONT_FAMILY.secondary};
  font-weight: 300;
  font-size: 24px;
  line-height: 30px;
  color: ${colors.black};
  text-align: right;
`;
export const HEDIAmountProgress = styled(
  'div',
  parseStyledOptions({ label: 'MainPage-HEDIAmountProgress' })
)<HEDIAmountProgressProps>`
  height: 96px;
  width: 221px;
  background: ${colors.accentLightGreen};
  margin-left: 48px;

  &::before {
    display: block;
    height: 100%;
    width: ${({ $progress }) => `${$progress}%`};
    content: '';
    transition: width 500ms ease-in-out;
    background: ${colors.blueMedium};
  }
`;

export const BuyHEDIWrapper = styled(
  'div',
  parseStyledOptions({ label: 'MainPage-BuyHEDIWrapper' })
)`
  background: ${colors.white};
  border-bottom: solid 1px ${colors.additionalGray};
  padding: 16px;
  display: flex;
  justify-content: center;

  @media screen and (min-width: 768px) {
    padding: 42px;
  }
  @media screen and (min-width: 1440px) {
    padding: 96px;
  }
`;

export const ContentBorder = styled(
  'div',
  parseStyledOptions({ label: 'MainPage-ContentContainer' })
)`
  border-bottom: solid 1px ${colors.additionalGray};
  width: 100%;
`;

export const SectionWrapper = styled(
  'div',
  parseStyledOptions({ label: 'MainPage-SectionWrapper' })
)`
  background: ${colors.white};
  border-bottom: solid 1px ${colors.additionalGray};
`;
export const SectionContentWrapper = styled(
  'div',
  parseStyledOptions({ label: 'MainPage-SectionWrapper' })
)`
  padding: 16px;
  margin: 0 auto;

  @media screen and (min-width: 768px) {
    padding: 42px;
  }

  @media screen and (min-width: 1440px) {
    padding: 96px;
    max-width: 1440px;
    display: flex;
  }
`;

export const SectionTitle = styled(
  'h2',
  parseStyledOptions({ label: 'MainPage-SectionTitle' })
)`
  font-size: 40px;
  font-weight: 300;
  font-family: ${FONT_FAMILY.secondary};
  line-height: 48px;
  color: ${colors.black};
`;
