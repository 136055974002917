import React, { MouseEvent, useEffect, useState } from 'react';
import {
  HeaderConnectBtn,
  HeaderMenu,
  HeaderMenuArrow,
  HeaderMenuBalance,
  HeaderMenuBtn,
  HeaderMenuBtnAddress,
  HeaderMenuBtnArrow,
  HeaderMenuDisconnect,
  HeaderMenuDivider,
  HeaderMenuItem,
  HeaderMenuList,
  HeaderMenuRoot,
  HeaderMenuWrapper,
  MetaMaskIcon,
  Text,
} from './HeaderConnectWallet.styled';
import useHeaderButtonScroll from '../../hooks/useHeaderButtonScroll';
import { TokenEnum } from '../../types/enum/token';
import { ClickAwayListener, Grow } from '@mui/material';
import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import ConnectButton from '../ConnectButton';
import {
  getConnection,
  switchNetwork,
  tryDeactivateConnector,
} from '../../web3';
import { useTokenBalance } from '../../web3/hooks/useBalance';
import { ConnectionType, INPUT_CHAIN_ID } from '../../web3/config';
import { Link } from 'react-router-dom';

const HeaderConnectWallet: React.FC = () => {
  const scrolled = useHeaderButtonScroll({ ignoreScroll: false });

  const { isActive, account, connector, chainId, provider } =
    useWeb3React<Web3Provider>();
  const { data: balance } = useTokenBalance(TokenEnum.HEDI);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  const cuttedAddress = account?.length
    ? `${account.slice(0, 5)}...${account.slice(-4)}`
    : '';

  const handleClickMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onDisconnect = () => {
    tryDeactivateConnector(
      getConnection(ConnectionType.INJECTED).connector
    ).then();
    handleClose();
  };

  useEffect(() => {
    if (chainId && INPUT_CHAIN_ID !== chainId) {
      switchNetwork(INPUT_CHAIN_ID, ConnectionType.INJECTED).then();
    }
  }, [chainId]);

  // useEffect(() => {
  //   if (chainId && provider) {
  //     addAssetToWallet({
  //       params: [TokenEnum.USDT, TokenEnum.HAI].map((token) => ({
  //         type: 'ERC20',
  //         options: {
  //           address: ADDRESS_SETS[chainId][token],
  //           tokenId: ''
  //         },
  //       })),
  //     }).then();
  //   }
  // }, [chainId, provider]);

  return isActive ? (
    <HeaderMenuWrapper>
      <HeaderMenuBalance>{balance} HEDI</HeaderMenuBalance>
      <HeaderMenuBtn
        $open={menuOpen}
        onClick={(event) => (menuOpen ? handleClose() : handleClickMenu(event))}
      >
        <MetaMaskIcon />
        <HeaderMenuBtnAddress>{cuttedAddress}</HeaderMenuBtnAddress>
        <HeaderMenuBtnArrow $open={menuOpen} />
      </HeaderMenuBtn>
      <HeaderMenu open={menuOpen} anchorEl={anchorEl} transition>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'top',
            }}
          >
            <HeaderMenuRoot>
              <ClickAwayListener onClickAway={handleClose}>
                <HeaderMenuList>
                  <HeaderMenuItem>
                    <Link onClick={handleClose} to='/transfer'>
                      <span>Transfer HEDIs</span>
                      <HeaderMenuArrow />
                    </Link>
                  </HeaderMenuItem>
                  <HeaderMenuItem>
                    <Link onClick={handleClose} to='/transfer?to=hacken'>
                      <span>Pay for Hacken services</span>
                      <HeaderMenuArrow />
                    </Link>
                  </HeaderMenuItem>
                  <HeaderMenuItem>
                    <Link onClick={handleClose} to='/swap'>
                      <span>Swap to HAI</span>
                      <HeaderMenuArrow />
                    </Link>
                  </HeaderMenuItem>
                  {/*<HeaderMenuItem>*/}
                  {/*  <Link onClick={handleClose} to='/history'>*/}
                  {/*    <span>Actions History</span>*/}
                  {/*    <HeaderMenuArrow />*/}
                  {/*  </Link>*/}
                  {/*</HeaderMenuItem>*/}
                  <HeaderMenuDivider />
                  <HeaderMenuDisconnect onClick={onDisconnect}>
                    Disconnect
                  </HeaderMenuDisconnect>
                </HeaderMenuList>
              </ClickAwayListener>
            </HeaderMenuRoot>
          </Grow>
        )}
      </HeaderMenu>
    </HeaderMenuWrapper>
  ) : (
    <ConnectButton>
      {({ onAction, pending }) => (
        <HeaderConnectBtn
          variant={'contained'}
          $scrolled={scrolled}
          disabled={pending}
          onClick={onAction}
        >
          <MetaMaskIcon />
          <Text>Connect Wallet</Text>
        </HeaderConnectBtn>
      )}
    </ConnectButton>
  );
};

export default HeaderConnectWallet;
