import { Components, listItemIconClasses } from '@mui/material';

import { colors } from 'utils/colors';

export const MuiMenuItemTheme: Components['MuiMenuItem'] = {
  styleOverrides: {
    root: {
      padding: '10px 16px',
      border: `1px solid ${colors.additionalGray}`,
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '20px',

      ':not(:first-of-type)': {
        marginTop: '-1px',
      },

      '&:hover, &.Mui-focusVisible': {
        background: colors.extraLightGray,
      },

      [`
        &.Mui-selected,
        &.Mui-selected:hover,
        &.Mui-selected.Mui-focusVisible
      `]: {
        background: colors.greenLight,
        borderColor: colors.greenLight,
      },

      ['.' + listItemIconClasses.root]: {
        marginRight: '6px',
        minWidth: '14px',
        visibility: 'hidden',
      },

      [`&.Mui-selected .${listItemIconClasses.root}`]: {
        visibility: 'visible',
      },
    },
  },
};
