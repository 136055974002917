import React from 'react';
import { FormikProps } from 'formik';
import {
  TextField,
  MenuItem,
  FormHelperText,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { ReactComponent as TelegramIcon } from 'assets/icons/telegram-sm.svg';
import { ReactComponent as WhatsAppIcon } from 'assets/icons/whatsApp.svg';
import { ReactComponent as WeChatIcon } from 'assets/icons/weChat.svg';
import { ReactComponent as SignalIcon } from 'assets/icons/signal.svg';

import {
  Form,
  TopFieldList,
  TopFieldItem,
  ServiceFormControl,
  IconButton,
  SelectPlatfromPlaceholder,
  SelectPlatform,
  ListItemIcon,
  PlatformValue,
  SelectService,
  BottomCheckboxesWrap,
  FormControlLabelWithError,
  CheckboxCheckedIcon,
  PolicyLink,
  ButtonSubmit,
} from './RequestQuoteForm.styled';
import { PlatformNames } from 'queries/person';

const RequestQuoteForm: React.FC<Props> = ({
  values,
  errors,
  touched,
  setFieldValue,
  handleSubmit,
  handleReset,
  handleChange,
  handleBlur,
  isSubmitting,
}) => {
  const servicesList = [
    { label: 'Smart Contract Audit', value: Services.SmartContractAudit },
    { label: 'Penetration Testing', value: Services.PenetrationTesting },
    { label: 'Bug Bounty', value: Services.BugBounty },
    { label: 'Partnership', value: Services.Partnership },
    { label: 'Protocol Audit (L1)', value: Services.ProtocolAuditL1 },
    { label: 'Proof of Reserves', value: Services.ProofofReserves },
    { label: 'dApp Audit', value: Services.dAppSecurityAudit },
    { label: 'Wallet Audit', value: Services.WalletAudit },
    {
      label: 'Cross-Chain Bridge Audit',
      value: Services.CrossChainBridgeAudit,
    },
    { label: 'CCSS Audit', value: Services.CCSSAudit },
    { label: 'Tokenomics Audit', value: Services.TokenomicsAudit },
    { label: 'ISO 27001 Audit', value: Services.ISO27001Audit },
  ];

  return (
    <Form onSubmit={handleSubmit} onReset={handleReset} noValidate>
      <TopFieldList>
        <TopFieldItem>
          <TextField
            name='name'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name ? errors.name : undefined}
            placeholder='Your name'
            InputProps={{
              endAdornment: values.name ? (
                <IconButton
                  aria-label='clear'
                  onClick={() => {
                    setFieldValue('name', '');
                  }}
                >
                  <CloseIcon width={20} />
                </IconButton>
              ) : undefined,
            }}
            fullWidth
          />
        </TopFieldItem>

        <TopFieldItem>
          <SelectPlatfromPlaceholder />
          <SelectPlatform
            name='platformName'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.platformName}
            variant='standard'
            MenuProps={{
              anchorOrigin: {
                vertical: 40,
                horizontal: 'center',
              },
            }}
            renderValue={(value: PlatformNames) => {
              const icon = {
                [PlatformNames.telegram]: <TelegramIcon />,
                [PlatformNames.whatsApp]: <WhatsAppIcon />,
                [PlatformNames.weChat]: <WeChatIcon />,
                [PlatformNames.signal]: <SignalIcon />,
              }[value];

              return <PlatformValue>{icon}</PlatformValue>;
            }}
          >
            {[
              { value: PlatformNames.telegram, label: 'Telegram' },
              { value: PlatformNames.whatsApp, label: 'WhatsApp' },
              { value: PlatformNames.weChat, label: 'WeChat' },
              { value: PlatformNames.signal, label: 'Signal' },
            ].map((item) => (
              <MenuItem value={item.value} key={item.value}>
                {item.label}
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
              </MenuItem>
            ))}
          </SelectPlatform>

          <TextField
            name='platformNickname'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.platformNickname}
            error={Boolean(touched.platformNickname && errors.platformNickname)}
            helperText={
              touched.platformNickname && errors.platformNickname
                ? errors.platformNickname
                : undefined
            }
            placeholder={
              values.platformName === PlatformNames.whatsApp
                ? 'Phone Number'
                : 'Username'
            }
            type={
              values.platformName === PlatformNames.whatsApp ? 'tel' : 'text'
            }
            InputProps={{
              endAdornment: values.platformNickname ? (
                <IconButton
                  aria-label='clear'
                  onClick={() => {
                    setFieldValue('platformNickname', '');
                  }}
                >
                  <CloseIcon width={20} />
                </IconButton>
              ) : undefined,
            }}
            fullWidth
          />
        </TopFieldItem>

        <ServiceFormControl>
          <SelectService
            name='service'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.service}
            variant='standard'
            MenuProps={{
              anchorOrigin: {
                vertical: 40,
                horizontal: 'center',
              },
            }}
            renderValue={(selected: typeof values.service) => {
              if (selected.length === 0) {
                return <>Select Service</>;
              }

              if (selected.length > 1) {
                return `${selected.length} services selected`;
              }

              const service = servicesList.find((s) => s.value === selected[0]);

              return service?.label;
            }}
            multiple
            fullWidth
            displayEmpty
            error={Boolean(touched.service && errors.service)}
          >
            {servicesList.map((item) => (
              <MenuItem value={item.value} key={item.value}>
                {item.label}
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
              </MenuItem>
            ))}
          </SelectService>

          {Boolean(touched.service && errors.service) && (
            <FormHelperText error>{errors.service}</FormHelperText>
          )}
        </ServiceFormControl>

        <TopFieldItem>
          <TextField
            name='email'
            type='email'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            helperText={
              touched.email && errors.email ? errors.email : undefined
            }
            placeholder='Corporate email'
            InputProps={{
              endAdornment: values.email ? (
                <IconButton
                  aria-label='clear'
                  onClick={() => {
                    setFieldValue('email', '');
                  }}
                  $colorRed={Boolean(touched.email && errors.email)}
                >
                  <CloseIcon width={20} />
                </IconButton>
              ) : undefined,
            }}
            fullWidth
          />
        </TopFieldItem>
      </TopFieldList>

      <TextField
        name='details'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.details}
        error={Boolean(touched.details && errors.details)}
        helperText={
          touched.details && errors.details ? errors.details : undefined
        }
        // eslint-disable-next-line max-len
        placeholder='Project details (description, links to documentation, smart contract source code, etc.)'
        InputProps={{
          endAdornment: values.details ? (
            <IconButton
              aria-label='clear'
              onClick={() => {
                setFieldValue('details', '');
              }}
              $alignSelfStart
            >
              <CloseIcon width={20} />
            </IconButton>
          ) : undefined,
        }}
        fullWidth
        multiline
        minRows={2}
        maxRows={6}
      />

      <BottomCheckboxesWrap>
        <FormControlLabelWithError
          $error={Boolean(touched.policyAgreement && errors.policyAgreement)}
          control={
            <Checkbox
              name='policyAgreement'
              checked={values.policyAgreement}
              onChange={handleChange}
              onBlur={handleBlur}
              checkedIcon={<CheckboxCheckedIcon />}
              icon={<>{null}</>}
            />
          }
          label={
            <>
              I agree to the{' '}
              <PolicyLink
                href='#'
                onClick={(e) => {
                  e.preventDefault();
                  window.open(
                    (e.target as undefined | HTMLAnchorElement)?.href,
                    '_blank'
                  );
                }}
                $error={Boolean(
                  touched.policyAgreement && errors.policyAgreement
                )}
              >
                {touched.policyAgreement && errors.policyAgreement
                  ? `Terms of Service ${errors.policyAgreement}`
                  : 'Terms of Service'}
              </PolicyLink>
            </>
          }
        />
        <FormControlLabel
          control={
            <Checkbox
              name='subscription'
              checked={values.subscription}
              onChange={handleChange}
              onBlur={handleBlur}
              checkedIcon={<CheckboxCheckedIcon />}
              icon={<>{null}</>}
            />
          }
          label='Get Hacken news and blockchain security tips'
        />
      </BottomCheckboxesWrap>

      <ButtonSubmit type='submit' disabled={isSubmitting}>
        Send request
      </ButtonSubmit>
    </Form>
  );
};

export enum Services {
  CCSSAudit = 397,
  SmartContractAudit = 68,
  PenetrationTesting = 69,
  TokenomicsAudit = 398,
  ISO27001Audit = 399,
  BugBounty = 70,
  Partnership = 71,
  ProtocolAuditL1 = 118,
  ProofofReserves = 341,
  dAppSecurityAudit = 343,
  WalletAudit = 533,
  CrossChainBridgeAudit = 534,
}

export type RequestQuoteFormValues = {
  name: string;
  platformName: PlatformNames;
  platformNickname: string;
  service: number[];
  email: string;
  details: string;
  policyAgreement: boolean;
  subscription: boolean;
};

type Props = FormikProps<RequestQuoteFormValues>;

export default RequestQuoteForm;
