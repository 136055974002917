import {
  Abi_BEP20__factory,
  Abi_HEDI__factory,
  Abi_TokenExchange__factory,
} from './contracts';
import { Web3Provider } from '@ethersproject/providers';

export function getContractToken(address: string, provider: Web3Provider) {
  return Abi_BEP20__factory.connect(address, provider.getSigner());
}

export function getTokenExchangeContract(
  address: string,
  provider: Web3Provider
) {
  return Abi_TokenExchange__factory.connect(address, provider.getSigner());
}

export function getHEDIContract(address: string, provider: Web3Provider) {
  return Abi_HEDI__factory.connect(address, provider.getSigner());
}
