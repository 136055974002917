import React from 'react';
import { Label, Root, Title, Tab } from './TabSelect.styled';

export type TabItem = {
  label: string;
  title?: string;
};

export interface TabSelectProps {
  data: TabItem[];
  activeIndex?: number;
  onSelected?: (index: number) => void;
}

const TabSelect: React.FC<TabSelectProps> = ({
  data,
  activeIndex,
  onSelected,
}) => {
  return (
    <Root>
      {data.map((item, index) => (
        <Tab
          $hasTitle={!!item.title}
          $active={activeIndex === index}
          onClick={() => onSelected?.(index)}
          key={index}
        >
          {item.title && <Title>{item.title}</Title>}
          <Label>{item.label}</Label>
        </Tab>
      ))}
    </Root>
  );
};

export default TabSelect;
