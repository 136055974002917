import { ADDRESS_SETS } from './config';
import { Web3Provider } from '@ethersproject/providers';
import {
  getContractToken,
  getHEDIContract,
  getTokenExchangeContract,
} from './contract';
import {
  AddAssetParams,
  ApproveHEDIParams,
  ApproveParams,
  BurnParams,
  BuyFnParams,
  GetHEDIfromTxParams,
} from 'types/actions';
import { BigNumber as BigNumberEthers, Overrides } from 'ethers';
import { TokenEnum } from 'types/enum/token';
import BigNumber from 'bignumber.js';
import { BigNumber as EtherBigNumber } from '@ethersproject/bignumber/lib/bignumber';

const calcSendParams = async (
  gasLimit: BigNumberEthers,
  provider: Web3Provider
): Promise<Overrides> => {
  try {
    const gasPrice = await provider.getGasPrice();

    return {
      gasPrice: BigNumber(gasPrice.toString()).times(1.2).toFixed(0),
      gasLimit,
    };
  } catch (e) {
    console.error('getGasPrice error', e);
    return {
      gasLimit,
    };
  }
};

export async function approve({
  chainId,
  provider,
  token,
  spenderAddress,
  allowance,
}: ApproveParams) {
  const address = ADDRESS_SETS[chainId][token];
  const contract = getContractToken(address, provider);
  try {
    return await contract.approve(spenderAddress, allowance);
  } catch (e) {
    throw new Error(e);
  }
}

export async function approveHEDI({
  chainId,
  provider,
  spenderAddress,
}: ApproveHEDIParams) {
  const address = ADDRESS_SETS[chainId].HEDI;
  const contract = getHEDIContract(address, provider);
  try {
    return await contract.setApprovalForAll(spenderAddress, true);
  } catch (e) {
    throw new Error(e);
  }
}

export async function buyHEDI({ chainId, provider, params }: BuyFnParams) {
  const address = ADDRESS_SETS[chainId].exchange;
  const contract = getTokenExchangeContract(address, provider);

  const { price, signature, deadline, sourceToken, amount, priceHAI, nonce } =
    params;

  const estimateGas = await contract.estimateGas.buy(
    sourceToken,
    price,
    priceHAI,
    deadline,
    nonce,
    signature,
    amount
  );
  const sendParams = await calcSendParams(estimateGas, provider);

  try {
    return await contract.buy(
      sourceToken,
      price,
      priceHAI,
      deadline,
      nonce,
      signature,
      amount,
      sendParams
    );
  } catch (e) {
    throw new Error(e);
  }
}

export async function getBurnPercentage({ chainId, provider }: BurnParams) {
  const address = ADDRESS_SETS[chainId].exchange;
  const contract = getTokenExchangeContract(address, provider);
  try {
    const result = await contract.burnPercentage();
    return result;
  } catch (e) {
    throw new Error(e);
  }
}

export function getHEDIfromTx({
  chainId,
  provider,
  receipt,
}: GetHEDIfromTxParams) {
  const address = ADDRESS_SETS[chainId][TokenEnum.HEDI];
  const contract = getHEDIContract(address, provider);
  const logs = receipt.logs.filter(
    (log) => log.address.toLowerCase() === address.toLowerCase()
  );
  const tokenIds = logs.map((log) => {
    const decodedLog = contract.interface.parseLog(log);
    return (decodedLog.args.tokenId as EtherBigNumber).toString(); // Adjust according to your event's structure
  });
  return tokenIds;
}

export async function addAssetToWallet({ params }: AddAssetParams) {
  //   Request wallet to add asset using method `wallet_watchAsset`
  //   https://docs.metamask.io/guide/rpc-api.html#wallet-addethereumchain
  try {
    // @ts-expect-error provider is not typed
    await window.ethereum?.sendAsync?.(
      params.map((p) => ({ params: p, method: 'wallet_watchAsset' })),
      (error: any, response: any) => {
        if (error) {
          console.log('addAssetToWallet error', error);
        }
        console.log('addAssetToWallet response', response);
      }
    );
  } catch (e) {
    console.log('addAssetToWallet error', e);
  }
}
