import { TokenEnum } from '../types/enum/token';
import { Connector } from '@web3-react/types';
import { Web3ReactHooks } from '@web3-react/core';
import { buildInjectedConnector } from './connectors/injected';

export const MAINNET_CHAIN_ID = 56;
export const TEST_CHAIN_ID = 97;

export enum Chain {
  TEST,
  MAIN,
}

const mainChain =
  process.env.REACT_APP_NETWORK === 'mainnet' ? Chain.MAIN : Chain.TEST;

export interface Connection {
  connector: Connector;
  hooks: Web3ReactHooks;
  type: ConnectionType;
}

export enum ConnectionType {
  // COINBASE_WALLET = 'COINBASE_WALLET',
  INJECTED = 'INJECTED',
  // NETWORK = 'NETWORK',
  // WALLET_CONNECT = 'WALLET_CONNECT',
}

export const WALLET_STATUS_KEY = 'WALLET_STATUS_KEY';

export type SetItem = {
  [key in string]: string;
};

export type Config = {
  chain: Chain;
  rpc: {
    testnet: string;
    mainnet: string;
  };
};

export type ChainInfo = {
  explorer: string;
  label: string;
  nativeCurrency: {
    name: string;
    symbol: string;
    decimals: 18;
  };
  rpcUrl: string;
};

export const CurrentConfig: Config = {
  chain: mainChain,
  rpc: {
    testnet: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
    mainnet:
      'https://nd-053-870-226.p2pify.com/acb07a1a925a5b91df2ecd164252475a',
  },
};
export const CHAIN_INFO: { [key: string]: ChainInfo } = {
  [MAINNET_CHAIN_ID]: {
    explorer: 'https://bscscan.com/',
    label: 'Binance Smart Chain',
    nativeCurrency: { name: 'Binance', symbol: 'BNB', decimals: 18 },
    rpcUrl: CurrentConfig.rpc.mainnet,
  },
  [TEST_CHAIN_ID]: {
    explorer: 'https://testnet.bscscan.com/',
    label: 'Binance Testnet',
    nativeCurrency: { name: 'Binance', symbol: 'BNB', decimals: 18 },
    rpcUrl: CurrentConfig.rpc.testnet,
  },
};

export const ADDRESS_SETS: { [key: number]: SetItem } = {
  [TEST_CHAIN_ID]: {
    exchange: '0x612FbB5d8f68dbc8cF7BFBA2f354Ac8aC6E74115',
    [TokenEnum.HAI]: '0x7D1954BDc61b9389696bFAdAe395E4A7d83eA6d7',
    [TokenEnum.HEDI]: '0xB1Ca46163Af4D783417b9624Dc79B94aCA5738cB',
    [TokenEnum.USDT]: '0x65edf87E522Dc1A8D12f682aA0a44d79cB6de1CF',
  },
  [MAINNET_CHAIN_ID]: {
    exchange: '0xdfF474A967cC66AA3579bECe97324442baDd0C90',
    [TokenEnum.HAI]: '0xaA9E582e5751d703F85912903bacADdFed26484C',
    [TokenEnum.HEDI]: '0x31b912a648cc33536e6276A30bDa2943B4bb3231',
    [TokenEnum.USDT]: '0x55d398326f99059fF775485246999027B3197955',
  },
};
export const INPUT_CHAIN_URL = mainChain
  ? CurrentConfig.rpc.mainnet
  : CurrentConfig.rpc.testnet;

export const PRIORITIZED_CONNECTORS: { [key in ConnectionType]: Connection } = {
  [ConnectionType.INJECTED]: buildInjectedConnector(),
  // [ConnectionType.COINBASE_WALLET]: buildCoinbaseConnector(),
};
export const INPUT_CHAIN_ID = mainChain ? MAINNET_CHAIN_ID : TEST_CHAIN_ID;
