import React from 'react';
import {
  Select as SelectUnstyled,
  selectClasses,
  SelectProps,
  IconButton as IconButtonUnstyled,
  ListItemIcon as ListItemIconUnstyled,
  Button as ButtonUnstyled,
  FormControlLabel as FormControlLabelUnstyled,
  inputClasses,
  inputBaseClasses,
  formControlLabelClasses,
  checkboxClasses,
} from '@mui/material';
import { Form as FormUnstyled } from 'formik';
import styled from '@emotion/styled';

import { parseStyledOptions } from 'utils/styled';
import { colors } from 'utils/colors';

import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';

export const Form = styled(
  FormUnstyled,
  parseStyledOptions({ label: 'RequestQuoteForm--Form' })
)`
  padding: 0 14px;
  width: 100%;

  @media screen and (min-width: 768px) {
    padding: 0;
  }
`;

export const TopFieldList = styled(
  'div',
  parseStyledOptions({ label: 'RequestQuoteForm--TopFieldList' })
)`
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: space-between;

  @media screen and (min-width: 768px) {
    margin-bottom: 42px;
    gap: 18px;
  }
`;

export const TopFieldItem = styled(
  'div',
  parseStyledOptions({ label: 'RequestQuoteForm--TopFieldItem' })
)`
  min-height: 42px;
  flex: 1;
  flex-basis: 220px;
  display: flex;
  gap: 16px;
  position: relative;

  @media screen and (min-width: 1050px) {
    max-width: 290px;
  }
`;

export const ServiceFormControl = styled(
  TopFieldItem,
  parseStyledOptions({ label: 'RequestQuoteForm--ServiceFormControl' })
)`
  flex-direction: column;
  gap: 0;
`;

export const IconButton = styled(
  IconButtonUnstyled,
  parseStyledOptions({ label: 'RequestQuoteForm--IconButton' })
)<{ $alignSelfStart?: boolean; $colorRed?: boolean }>`
  margin-right: 10px;
  border-radius: 0;

  ${({ $alignSelfStart }) => ($alignSelfStart ? 'align-self: flex-start;' : '')}
  ${({ $colorRed }) => ($colorRed ? `color: ${colors.red};` : '')}
    &:hover,
    &:focus {
    background: transparent;
  }
`;

export const SelectPlatfromPlaceholder = styled(
  'div',
  parseStyledOptions({ label: 'RequestQuoteForm--SelectPlatfromPlaceholder' })
)`
  min-width: 35px;
`;

export const SelectBase = styled(
  SelectUnstyled,
  parseStyledOptions({ label: 'RequestQuoteForm--SelectBase' })
)``;

export const SelectPlatfromBase = styled(
  SelectBase,
  parseStyledOptions({ label: 'RequestQuoteForm--SelectPlatfromBase' })
)`
  position: absolute;
  height: 100%;
  max-height: 42px;
  width: 100%;

  ${'.' +
  selectClasses.select}.${inputClasses.input}.${inputBaseClasses.input} {
    width: auto;
    height: 100%;
    padding-left: 0;
    padding-top: 0;
    padding-right: 20px;
    padding-bottom: 0;
  }

  ::before {
    display: none;
  }

  .${selectClasses.icon} {
    left: 22px;
  }
`;

export const SelectPlatform = styled(
  (props: SelectProps) => (
    <SelectPlatfromBase
      {...props}
      MenuProps={{
        ...props.MenuProps,
        classes: {
          ...props.MenuProps?.classes,
          paper: `${props.MenuProps?.classes?.paper} ${props.className}`,
        },
      }}
    />
  ),
  parseStyledOptions({ label: 'RequestQuoteForm--Select' })
)``;

export const PlatformValue = styled(
  'span',
  parseStyledOptions({ label: 'RequestQuoteForm--PlatformValue' })
)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg path {
    fill: ${colors.black};
  }
`;

export const SelectServiceBase = styled(
  SelectBase,
  parseStyledOptions({ label: 'RequestQuoteForm--SelectServiceBase' })
)`
  max-height: 42px;

  ::before {
    width: calc(100% - 30px);
  }
`;

export const SelectService = SelectPlatform.withComponent(
  (props: SelectProps) => (
    <SelectServiceBase
      {...props}
      MenuProps={{
        ...props.MenuProps,
        classes: {
          ...props.MenuProps?.classes,
          paper: `${props.MenuProps?.classes?.paper} ${props.className}`,
        },
      }}
    />
  )
);

export const ListItemIcon = styled(
  ListItemIconUnstyled,
  parseStyledOptions({ label: 'RequestQuoteForm--ListItemIcon' })
)`
  margin-left: auto;
  margin-right: 16px;
  min-width: 14px;
`;

export const BottomCheckboxesWrap = styled(
  'div',
  parseStyledOptions({ label: 'RequestQuoteForm--BottomCheckboxesWrap' })
)`
  margin: 28px 0 25px;
  min-height: 58px;
  display: flex;
  flex-direction: column;
  gap: 18px;

  @media screen and (min-width: 768px) {
    margin: 48px 0 65px;
  }
`;

export const FormControlLabelWithError = styled(
  FormControlLabelUnstyled,
  parseStyledOptions({ label: 'RequestQuoteForm--FormControlLabel' })
)<{ $error?: boolean }>`
  ${({ $error }) =>
    $error
      ? `
    .${checkboxClasses.root} {
      border-color: ${colors.red};
    }
    .${formControlLabelClasses.label} {
      color: ${colors.red};
    }
  `
      : ''}
`;

export const ButtonSubmit = styled(
  ButtonUnstyled,
  parseStyledOptions({ label: 'RequestQuoteForm--ButtonSubmit' })
)`
  margin: 0 auto;
  min-width: 260px;
  min-height: 64px;
  display: flex;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
`;

export const CheckboxCheckedIcon = styled(
  CheckIcon,
  parseStyledOptions({ label: 'RequestQuoteForm--CheckboxCheckedIcon' })
)`
  min-width: 14px;
`;

export const PolicyLink = styled(
  'a',
  parseStyledOptions({ label: 'RequestQuoteForm--PolicyLink' })
)<{ $error?: boolean }>`
  color: ${({ $error }) => ($error ? colors.red : colors.blue)};

  ${({ $error }) => ($error ? 'font-weight: 600;' : '')}
  &:hover,
    &:focus {
    text-decoration: underline;
    outline: 0;
  }
`;
