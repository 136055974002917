import React from 'react';
import { ThemeProvider, GlobalStyles, CssBaseline } from '@mui/material';

import { MUI_THEME } from './theme';
import { fontsStyled, globalStyles } from '../../../styles/Global.styled';

const MaterialProvider: React.FC<Props> = ({ children }) => (
  <ThemeProvider theme={MUI_THEME}>
    <CssBaseline />
    <GlobalStyles styles={fontsStyled} />
    <GlobalStyles styles={globalStyles} />
    {children}
  </ThemeProvider>
);

type Props = {
  children: React.ReactNode;
};

export default MaterialProvider;
