import { css } from '@emotion/react';
import {
  FONT_FAMILY,
  FONT_FAMILY_PRIMARY,
  FONT_FAMILY_SECONDARY,
} from '../utils/consts';
import PPMoriRegular from 'assets/fonts/PPMori/PPMori-Regular.woff';
import PPMoriSemiBold from 'assets/fonts/PPMori/PPMori-SemiBold.woff';
import PPNeueMachinaInktrapLight from 'assets/fonts/PPNeueMachina/PPNeueMachina-InktrapLight.woff';
import PPNeueMachinaInktrapRegular from 'assets/fonts/PPNeueMachina/PPNeueMachina-InktrapRegular.woff';
import styled from '@emotion/styled';
import { parseStyledOptions } from '../utils/styled';
import { Button } from '@mui/material';
import { colors } from '../utils/colors';

interface ButtonDefaultProps {
  $svgPrefix?: boolean;
}

interface ButtonTransparentProps {
  $white?: boolean;
}

export const fontsStyled = css`
  @font-face {
    font-family: ${FONT_FAMILY_PRIMARY};
    src: url(${PPMoriRegular}) format('woff');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: ${FONT_FAMILY_PRIMARY};
    src: url(${PPMoriSemiBold}) format('woff');
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: ${FONT_FAMILY_SECONDARY};
    src: url(${PPNeueMachinaInktrapLight}) format('woff');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: ${FONT_FAMILY_SECONDARY};
    src: url(${PPNeueMachinaInktrapRegular}) format('woff');
    font-weight: 400;
    font-style: normal;
  }
`;

export const globalStyles = css`
  html,
  body {
    font-family: ${FONT_FAMILY.primary}, sans-serif;
    margin: 0;
    padding: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    box-sizing: border-box;
  }

  html {
    height: 100%;
    scroll-behavior: smooth;
  }

  body {
    display: flex;
    line-height: 1;
    min-height: 100%;
  }

  input,
  textarea,
  select {
    font-family: inherit;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }

  li,
  ul,
  ol {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  button {
    cursor: pointer;
    border: none;
    padding: 0;
    width: auto;
    overflow: visible;

    background: transparent;

    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;

    /* Normalize \`line-height\`. Cannot be changed from \`normal\` in Firefox 4+. */
    line-height: normal;

    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    /* Corrects inability to style clickable \`input\` types in iOS */
    -webkit-appearance: none;

    /* Remove excess padding and border in Firefox 4+ */

    &::-moz-focus-inner {
      border: 0;
      padding: 0;
    }
  }

  #root {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .grecaptcha-badge {
    visibility: hidden;
  }
`;

export const ButtonDefault = styled(
  Button,
  parseStyledOptions({ label: 'Button' })
)<ButtonDefaultProps>`
  display: inline-flex;
  align-items: center;
  padding: 16px 32px;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;

  svg {
    ${({ $svgPrefix }) => $svgPrefix && 'margin-right: 16px;'}
    ${({ $svgPrefix }) => !$svgPrefix && 'margin-left: 16px;'}
        width: 24px;
    height: 24px;
  }

  @media screen and (min-width: 768px) {
  }
`;
export const ButtonTransparent = styled(
  Button,
  parseStyledOptions({ label: 'ButtonTransparent' })
)<ButtonTransparentProps>`
  text-align: center;
  padding: 18px 45px;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  display: inline-flex;

  border: solid 1px ${({ $white }) => ($white ? colors.white : colors.black)};
  background: transparent;
  color: ${({ $white }) => ($white ? colors.white : colors.black)};

  &:hover,
  &:active {
    background: ${({ $white }) => ($white ? colors.white : colors.black)};
    color: ${({ $white }) => ($white ? colors.black : colors.white)};
  }
`;

export const Container = styled(
  'div',
  parseStyledOptions({ label: 'Container' })
)`
  max-width: 1440px;
  margin: 0 auto;
`;
export const Wrapper = styled(
  Container,
  parseStyledOptions({ label: 'Wrapper' })
)`
  @media screen and (min-width: 768px) {
    padding: 0 96px;
  }
`;
