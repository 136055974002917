import { CreateStyled } from '@emotion/styled';

export const shouldForwardPropFallback = (propName: string) =>
  !propName.startsWith('$');

export const parseStyledOptions = (
  options: Parameters<CreateStyled>[1]
): Parameters<CreateStyled>[1] => {
  return {
    ...(options || {}),
    label:
      options?.label && process.env.NODE_ENV === 'production'
        ? undefined
        : options?.label,
    shouldForwardProp: options?.shouldForwardProp || shouldForwardPropFallback,
  };
};
