import styled from '@emotion/styled';
import { parseStyledOptions } from '../../utils/styled';
import { colors } from '../../utils/colors';
import { Container } from '../SectionHowItWorks/SectionHowItWorks.styled';
import { FONT_FAMILY } from '../../utils/consts';
import { H2Positioned, SubParagraph } from '../SectionTypography/styled';

export const Title = styled(
  H2Positioned,
  parseStyledOptions({ label: 'SectionWhyNeed-Title' })
)`
  padding: 48px 16px 0;

  @media screen and (min-width: 768px) {
    padding: 96px 96px 0;
  }
`;
export const SubTitle = styled(
  SubParagraph,
  parseStyledOptions({ label: 'SectionWhyNeed-SubTitle' })
)`
  padding: 0 16px 48px;

  @media screen and (min-width: 768px) {
    padding: 0 96px 96px;
  }
`;
export const SectionContainer = styled(
  'div',
  parseStyledOptions({ label: 'SectionWhyNeed-SectionContainer' })
)`
  border-top: 1px solid ${colors.additionalGray};
  border-bottom: 1px solid ${colors.additionalGray};
`;
export const Wrapper = styled(
  Container,
  parseStyledOptions({ label: 'SectionWhyNeed-Wrapper' })
)`
  padding: 46px 16px 48px;

  @media screen and (min-width: 768px) {
    padding: 80px 96px 48px;
  }
`;

export const WhyNeedList = styled(
  'ul',
  parseStyledOptions({ label: 'MainPage-WhyNeedContent' })
)`
  @media screen and (min-width: 768px) {
    padding: 0 48px;
  }
  @media screen and (min-width: 1024px) {
    flex: 1;
  }
`;
export const WhyNeedItem = styled(
  'li',
  parseStyledOptions({ label: 'MainPage-WhyNeedContent' })
)`
  &:not(:last-child)::after {
    content: '';
    display: block;
    border-bottom: solid 1px ${colors.additionalGray};
    width: calc(100% - 48px);
    margin-left: 48px;
  }
`;

export const WhyNeedItemContent = styled(
  'div',
  parseStyledOptions({ label: 'MainPage-WhyNeedItemContent' })
)`
  display: flex;
  padding: 25px 0 16px;

  svg {
    min-width: 32px;
    width: 32px;
    height: 32px;
    margin-right: 16px;
  }

  span {
    font-size: 28px;
    line-height: 36px;
    color: ${colors.black};
    font-weight: 300;
    font-family: ${FONT_FAMILY.secondary};
  }
`;

export const WhyNeedDescription = styled(
  SubParagraph,
  parseStyledOptions({ label: 'MainPage-WhyNeedDescription' })
)`
  font-weight: 500;
  padding: 0 0 24px 48px;
`;
