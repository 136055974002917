import styled from '@emotion/styled';
import { parseStyledOptions } from '../../../utils/styled';
import { colors } from '../../../utils/colors';
import { Container } from '../../../styles/Global.styled';

export const Root = styled(
  'section',
  parseStyledOptions({ label: 'RootCustom-Root' })
)`
  background: ${colors.accentGreen};
  min-height: 100vh;

  @media (min-width: 768px) {
    padding: 68px 96px;
  }

  @media (min-width: 900px) {
    min-height: 672px;
    height: calc(100vh - 75px);
  }
`;

export const Wrapper = styled(
  Container,
  parseStyledOptions({ label: 'RootCustom-Wrapper' })
)`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 16px;
`;
export const Lines1 = styled(
  'div',
  parseStyledOptions({ label: 'HistoryScreen-Lines1' })
)`
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 20%;
    height: 85%;
    border-right: solid 1px ${colors.accentLightGreen};
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 15%;
    width: 90%;
    left: 0;
    border-top: solid 1px ${colors.accentLightGreen};
  }
`;
export const Lines2 = styled(
  'div',
  parseStyledOptions({ label: 'HistoryScreen-Lines1' })
)`
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 10%;
    height: 100%;
    border-left: solid 1px ${colors.accentLightGreen};
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 15%;
    right: 0;
    width: 10%;
    border-bottom: solid 1px ${colors.accentLightGreen};

    @media (min-width: 900px) {
      top: calc(15% + 75px);
    }
  }
`;
