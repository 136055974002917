import React, { useEffect } from 'react';
import {
  Container,
  HeaderBlock,
  NavBar,
  LogoLink,
  LogoHackenMob,
  LogoHackenMobNav,
  LogoHackenWrapper,
  ToggleButton,
  NavTrigger,
  NavWrapper,
  NavLinksList,
  NavLinksItem,
  NavFooter,
  NavLink,
} from './Header.styled';
import { ReactComponent as HackenLogo } from 'assets/icons/hacken-logo.svg';
import { useToggle } from 'react-use';
import HeaderConnectWallet from '../../HeaderConnectWallet';
import { useLocation } from 'react-router-dom';

const Header: React.FC = () => {
  const { hash } = useLocation();
  const [isNavOpen, toggleIsNavOpen] = useToggle(false);

  useEffect(() => {
    const hashAnchor = hash.includes('#') ? hash.replace('#', '') : '';
    if (hashAnchor.length && document.getElementById(hashAnchor)) {
      setTimeout(() => {
        const yOffset =
          document.querySelector('header')?.getBoundingClientRect().height || 0;

        const y =
          (document.getElementById(hashAnchor)?.getBoundingClientRect().top ||
            0) +
          window.pageYOffset -
          yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      });
    }
  }, [hash]);

  return (
    <HeaderBlock>
      <Container>
        <NavBar $open={isNavOpen}>
          <LogoLink to={'https://hacken.io'}>
            <LogoHackenMob $open={isNavOpen} />
            <LogoHackenMobNav $open={isNavOpen} />
            <LogoHackenWrapper>
              <HackenLogo />
            </LogoHackenWrapper>
          </LogoLink>
          <NavWrapper $open={isNavOpen}>
            <NavLinksList>
              <NavLinksItem>
                <NavLink onClick={toggleIsNavOpen} to={'/#about'}>
                  About
                </NavLink>
              </NavLinksItem>
              <NavLinksItem>
                <NavLink onClick={toggleIsNavOpen} to={'/#benefits'}>
                  Benefits
                </NavLink>
              </NavLinksItem>
              <NavLinksItem>
                <NavLink onClick={toggleIsNavOpen} to={'/#how-it-works'}>
                  How it Works
                </NavLink>
              </NavLinksItem>
              <NavLinksItem>
                <NavLink onClick={toggleIsNavOpen} to={'/#hai-values'}>
                  HAI Value
                </NavLink>
              </NavLinksItem>
            </NavLinksList>
            <NavFooter>© 2023 Hacken.io All rights reserved.</NavFooter>
          </NavWrapper>
          <HeaderConnectWallet />
          <ToggleButton onClick={toggleIsNavOpen} aria-label={'burger menu'}>
            <NavTrigger $open={isNavOpen}>
              <span />
              <span />
            </NavTrigger>
          </ToggleButton>
        </NavBar>
      </Container>
    </HeaderBlock>
  );
};

export default Header;
