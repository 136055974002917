import React from 'react';
import { CircularChart } from './SectionHowItWorks.styled';

type CircleChartProps = {
  percentage: number;
  index: number;
};

const CircleChart = ({ percentage, index }: CircleChartProps) => {
  return (
    <CircularChart>
      <svg viewBox='0 0 36 36' className='circular-chart'>
        <path
          className='circle-bg'
          d='M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831'
        />
        <path
          className='circle'
          strokeDasharray={`${percentage}, 100`}
          d='M18 2.0845
        a 15.9155 15.9155 0 0 1 0 31.831
        a 15.9155 15.9155 0 0 1 0 -31.831'
        />
        <text x='18' y='22.5'>
          {index}
        </text>
      </svg>
    </CircularChart>
  );
};

export default CircleChart;
