import React from 'react';

import { ButtonDefault } from '../../styles/Global.styled';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-nav.svg';

const BuyButton: React.FC = () => {
  const onScroll = () => {
    const element = document.getElementById('buy');
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };
  return (
    <ButtonDefault onClick={onScroll}>
      <span>Buy HEDI</span>
      <ArrowIcon />
    </ButtonDefault>
  );
};

export default BuyButton;
