import { parseStyledOptions } from '../../utils/styled';
import { Button, MenuItem, MenuList, Popper } from '@mui/material';
import styled from '@emotion/styled';
import { colors } from '../../utils/colors';
import { ReactComponent as MetaMaskLogo } from 'assets/icons/metamask-logo.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down.svg';
import { ReactComponent as ArrowNav } from 'assets/icons/arrow-nav.svg';

interface HeaderMenuBtnProps {
  $open?: boolean;
}

export const HeaderConnectBtn = styled(
  Button,
  parseStyledOptions({
    label: 'Header-HeaderConnectBtn',
  })
)<{ $scrolled: boolean }>`
  display: flex;
  align-items: center;

  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  padding: 14px 9.5px;

  max-width: 170px;
  width: 100%;
  height: 52px;

  background-color: ${({ $scrolled }) =>
    $scrolled ? colors.blue : 'transparent'};
  color: ${({ $scrolled }) => ($scrolled ? colors.white : colors.black)};
  border-width: 1px;
  border-style: solid;
  border-radius: 0;
  border-color: ${({ $scrolled }) => ($scrolled ? colors.blue : colors.black)};

  &:hover {
    color: ${colors.white};
    border-color: ${colors.blueDark};
  }

  //@media screen and (max-width: 899px) {
  //  display: none;
  //}

  @media screen and (max-width: 899px) {
    height: 48px;
    margin-right: 56px;
    border: none;
  }
  @media screen and (min-width: 1025px) {
    max-width: 170px;
  }
`;

export const Text = styled(
  'span',
  parseStyledOptions({ label: 'HeaderConnectBtn--Text' })
)`
  margin-left: 8px;
`;

export const MetaMaskIcon = styled(
  MetaMaskLogo,
  parseStyledOptions({ label: 'HeaderConnectBtn--MetaMaskIcon' })
)`
  width: 24px;
  height: 24px;
`;

export const HeaderMenuWrapper = styled(
  'div',
  parseStyledOptions({ label: 'Header-HeaderMenuWrapper' })
)`
  display: flex;
  align-items: center;
  border: solid 1px ${colors.black};
  width: auto;
  @media screen and (max-width: 899px) {
    margin-right: 56px;
    border: none;
  }
`;

export const HeaderMenuBalance = styled(
  'div',
  parseStyledOptions({ label: 'Header-HeaderMenuBalance' })
)`
  padding: 11px 16px;
  border-right: solid 1px ${colors.black};
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  color: ${colors.black};
  white-space: nowrap;
  @media screen and (max-width: 899px) {
    padding: 10px;
    border: none;
  }
`;

export const HeaderMenuBtn = styled(
  Button,
  parseStyledOptions({
    label: 'Header-HeaderMenuBtn',
  })
)<HeaderMenuBtnProps>`
  display: flex;
  align-items: center;
  padding: 11px 16px;
  border-radius: 0;
  border: none;
  background-color: ${({ $open }) => ($open ? colors.white : 'transparent')};
  color: ${colors.black};

  &:hover {
    background-color: ${colors.white};
  }
`;

export const HeaderMenuBtnAddress = styled(
  'span',
  parseStyledOptions({ label: 'Header-HeaderMenuBtnAddress' })
)`
  flex: 1;
  margin: 0 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
`;

export const HeaderMenuBtnArrow = styled(
  ArrowDown,
  parseStyledOptions({ label: 'Header-HeaderMenuBtnArrow' })
)<{ $open?: boolean }>`
  width: 16px;
  height: 16px;
  transition: transform 200ms ease-in-out;
  transform: ${({ $open }) => ($open ? 'rotate(180deg)' : 'rotate(0)')};
`;

export const HeaderMenu = styled(
  Popper,
  parseStyledOptions({ label: 'Header-HeaderMenu' })
)`
  margin-top: 14px !important;
`;
export const HeaderMenuRoot = styled(
  'div',
  parseStyledOptions({ label: 'Header-HeaderMenuRoot' })
)`
  padding: 24px 36px;
  background: ${colors.white};
`;
export const HeaderMenuList = styled(
  MenuList,
  parseStyledOptions({ label: 'Header-HeaderMenuList' })
)`
  padding: 0;
`;
export const HeaderMenuItem = styled(
  MenuItem,
  parseStyledOptions({ label: 'Header-HeaderMenuItem' })
)`
  margin: 0 0 16px;
  padding: 0;
  border: none;
  min-width: 248px;

  a {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    border: none;
    background: ${colors.extraLightGray};
    color: ${colors.black};
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;

    svg path {
      fill: transparent;
      transition: fill 0.3s ease-in-out;
    }

    &:hover {
      background: ${colors.blue};
      color: ${colors.white};

      svg path {
        fill: ${colors.white};
      }
    }
  }
`;
export const HeaderMenuArrow = styled(
  ArrowNav,
  parseStyledOptions({ label: 'Header-HeaderMenuArrow' })
)`
  width: 16px;
  height: 16px;
`;

export const HeaderMenuDivider = styled(
  'div',
  parseStyledOptions({ label: 'Header-HeaderMenuDivider' })
)`
  margin-bottom: 16px;
  border-bottom: solid 1px ${colors.additionalGray};
`;

export const HeaderMenuDisconnect = styled(
  MenuItem,
  parseStyledOptions({ label: 'Header-HeaderMenuDisconnect' })
)`
  padding: 8px 16px;
  background: transparent;
  color: ${colors.red};
  border: solid 1px ${colors.red};
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  transition: all 250ms ease-in-out;
  min-width: 248px;

  &:hover {
    background: ${colors.red};
    color: ${colors.white};
  }
`;
