import React, { useState } from 'react';
import { H2Positioned } from '../SectionTypography/styled';
import {
  BuyBtnWrapper,
  DescriptionBlock,
  ImageWrapper,
  List,
  ListItem,
  ListItemTitle,
  Paragraph,
  SectionContainer,
  SectionWrapper,
  StepsContainer,
  StepsDescription,
  Wrapper,
} from './SectionHowItWorks.styled';
import CircleChart from './CircleChart';
import BuyHEDIImg from 'assets/images/buy-etd.png';
import DefiImg from 'assets/images/defi.png';
import ExchangeImg from 'assets/images/exchange.png';
import WalletsImg from 'assets/images/wallets.png';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-nav.svg';
import BuyButton from '../BuyButton';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const stepsList = [
  {
    img: WalletsImg,
    title: 'Buy HEDI with USDT or HAI',
    description:
      'Buy HEDI with either USDT or HAI. After purchase confirmation, the HEDI will immediately appear in your MetaMask wallet.',
  },
  {
    img: WalletsImg,
    title: 'Use HEDI to pay for Hacken services',
    description:
      'You are able to purchase HEDI with USDT or HAI. After the purchase confirmation the HEDI will immediately appears on your Metamask wallet.',
  },
  {
    img: DefiImg,
    title: 'Transfer HEDI to your partner',
    description:
      'You are able to purchase HEDI with USDT or HAI. After the purchase confirmation the HEDI will immediately appears on your Metamask wallet.',
  },
  {
    img: ExchangeImg,
    title: 'Swap HEDI back to HAI',
    description:
      'You are able to purchase HEDI with USDT or HAI. After the purchase confirmation the HEDI will immediately appears on your Metamask wallet.',
  },
];
const SectionHowItWorks: React.FC = () => {
  const activeStepItem = stepsList[0];

  return (
    <SectionWrapper id={'how-it-works'}>
      <H2Positioned>
        Accelerating Growth with HEDI: A Win-Win Scenario
      </H2Positioned>
      <SectionContainer>
        <Wrapper>
          <StepsContainer>
            <List>
              {stepsList.map((step, idx) => (
                <ListItem key={idx}>
                  <CircleChart
                    percentage={((idx + 1) * 100) / stepsList.length}
                    index={idx + 1}
                  />
                  <ListItemTitle>{step.title}</ListItemTitle>
                  <ArrowIcon />
                </ListItem>
              ))}
            </List>
            <BuyBtnWrapper>
              <BuyButton />
            </BuyBtnWrapper>
          </StepsContainer>
          <DescriptionBlock>
            <ImageWrapper>
              <LazyLoadImage
                src={activeStepItem.img}
                alt={activeStepItem.title}
              />
            </ImageWrapper>
            <Paragraph>{activeStepItem.description}</Paragraph>
          </DescriptionBlock>
        </Wrapper>
      </SectionContainer>
    </SectionWrapper>
  );
};

export default SectionHowItWorks;
