import React, { useEffect, useState } from 'react';
import {
  InputWrapper,
  Label,
  MaxBtn,
  Root,
  SelectBtn,
  SelectInput,
  TitleWrapper,
} from './InputAmount.styled';
import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { formatToken, parseToken } from '../../utils/formatter';
import { TokenEnum } from '../../types/enum/token';
import BigNumber from 'bignumber.js';

export interface InputAmountProps {
  onChange?: (value: string) => void;
  max?: number;
  token: TokenEnum;
  value?: string;
}

const InputAmount: React.FC<InputAmountProps> = ({
  onChange,
  max,
  token,
  value,
}) => {
  const balance = '1000000000000000000000000';
  const [amount, setAmount] = useState<string>(value || '');

  const onMax = () => {
    if (max && BigNumber(balance).gt(parseToken(`${max || 0}`, token))) {
      setAmount(`${max}`);
    } else {
      setAmount(
        formatToken(balance, token, {
          format: {
            groupSeparator: '',
          },
        }) as string
      );
    }
  };

  const onIncrease = () => {
    if (+amount === max) return;
    setAmount((state) =>
      BigNumber(state.length ? state : 0)
        .plus(1)
        .toString()
    );
  };
  const onDecrease = () => {
    setAmount((state) => {
      const bn = BigNumber(state.length ? state : '0');
      if (bn.isZero()) return '0';
      return bn.minus(1).toString();
    });
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const numeric = Number(e.target.value);
    const newAmount = numeric ? numeric.toString() : '';
    if (!max) {
      setAmount(newAmount);
    } else {
      setAmount(numeric <= max ? newAmount : max.toString());
    }
  };

  useEffect(() => {
    onChange && onChange(amount);
  }, [amount]);

  useEffect(() => {
    if (max && +amount > max) {
      setAmount(max.toString());
    }
  }, [max]);

  useEffect(() => {
    if (value && value !== amount) {
      setAmount(value);
    }
  }, [value]);

  return (
    <Root>
      <TitleWrapper>
        <Label>Select HEDI amount</Label>
        <MaxBtn onClick={onMax}>Max</MaxBtn>
      </TitleWrapper>
      <InputWrapper>
        <SelectBtn onClick={onDecrease}>
          <MinusIcon />
        </SelectBtn>
        <SelectInput
          placeholder={'0'}
          value={amount}
          onChange={onInputChange}
          InputProps={{
            inputProps: {
              pattern: '[0-9]*',
              min: 0,
              max,
            },
          }}
        />
        <SelectBtn onClick={onIncrease}>
          <PlusIcon />
        </SelectBtn>
      </InputWrapper>
    </Root>
  );
};

export default InputAmount;
