import { colors } from '../../utils/colors';
import styled from '@emotion/styled';
import { parseStyledOptions } from '../../utils/styled';
import { Container } from '../../styles/Global.styled';
import { H2 } from '../SectionTypography/styled';

export const SectionWrapper = styled(
  'section',
  parseStyledOptions({ label: 'FooterActions-SectionWrapper' })
)`
  background: ${colors.black};
  position: relative;

  &::after {
    content: '';
    display: block;
    border-right: solid 1px ${colors.extraDarkGray};
    height: 100%;
    position: absolute;
    top: 0;
    right: 15%;
  }

  &::before {
    content: '';
    display: block;
    border-bottom: solid 1px ${colors.extraDarkGray};
    width: 100%;
    position: absolute;
    top: 15%;
  }
`;

export const Wrapper = styled(
  Container,
  parseStyledOptions({ label: 'FooterActions-Wrapper' })
)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 16px;

  @media screen and (min-width: 768px) {
    padding: 128px 96px;
  }
`;
export const Title = styled(
  H2,
  parseStyledOptions({ label: 'FooterActions-Title' })
)`
  color: ${colors.white};
  margin-bottom: 48px;
`;
export const ButtonsWrapper = styled(
  'div',
  parseStyledOptions({ label: 'FooterActions-ButtonsWrapper' })
)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    gap: 48px;
  }
`;
