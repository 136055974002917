import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AddressInput,
  Content,
  Footer,
  Header,
  Label,
  Root,
  Title,
  WarningMessage,
} from './TransferForm.styled';
import TabSelect from '../../TabSelect';
import InputAmount from '../../InputAmount';
import { TokenEnum } from '../../../types/enum/token';
import TransferSuccess from './TransferSuccess';
import ConnectButton from '../../ConnectButton';
import { useHEDIGroups, useHEDIActions } from '../../../hooks/useHEDI';
import { ethers } from 'ethers';
import SelectHEDI from '../../SelectHEDI';
import BigNumber from 'bignumber.js';
import { toast } from 'react-hot-toast';

interface TransferFormProps {
  initialDestination?: number;
}

const data = [
  {
    label: 'To Hacken',
    value: process.env.REACT_APP_HACKEN_ADDRESS,
  },
  {
    label: 'To Third-Party',
  },
];
const TransferScreen: React.FC<TransferFormProps> = ({
  initialDestination = 0,
}) => {
  const { transfer } = useHEDIActions();
  const { data: etdGroups } = useHEDIGroups();

  const [etdGroupIdx, setEtdGroupIdx] = useState<number>(0);
  const [etdAmount, setEtdAmount] = useState<string>('0');
  const [activeDestination, setActiveDestination] =
    useState<number>(initialDestination);
  const [destinationAddress, setDestinationAddress] = useState<string>('');
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [txHash, setTxHash] = useState<string>('');
  const [pending, setPending] = useState<boolean>(false);

  const activeEtdGroup = etdGroups?.[etdGroupIdx];

  const activeDestinationAddress = activeDestination
    ? destinationAddress
    : data[activeDestination].value || '';

  const isEnough = useMemo(() => {
    if (!activeEtdGroup) return false;
    return (
      new BigNumber(activeEtdGroup.tokens.length).gte(
        etdAmount.length ? etdAmount : 0
      ) && !BigNumber(activeEtdGroup.tokens.length).isZero()
    );
  }, [activeEtdGroup, etdAmount]);
  const btnDisabled = useMemo(() => {
    if (
      isEnough &&
      etdGroups &&
      ethers.utils.isAddress(activeDestinationAddress)
    ) {
      return !etdAmount.length || BigNumber(etdAmount).isZero();
    } else {
      return true;
    }
  }, [isEnough, etdAmount, activeDestinationAddress, etdGroups]);
  const btnText = isEnough ? 'Transfer' : 'Insufficient Funds';

  const onSubmit = () => {
    if (activeEtdGroup && !btnDisabled) {
      setPending(true);
      transfer
        .mutateAsync({
          to: activeDestinationAddress,
          amount: activeEtdGroup.tokens.slice(0, +etdAmount),
        })
        .then((tx) => {
          setTxHash(tx.hash);
          setShowSuccess(true);
        })
        .catch((e) => {
          console.error('Buy HEDI error:', e);
          if (e.message && e.message.includes('ACTION_REJECTED')) {
            //   TODO: User rejection
          } else {
            toast.error('Transaction failed!');
          }
        })
        .finally(() => {
          setPending(false);
        });
    } else {
      toast.error('Transaction failed!');
    }
  };

  useEffect(() => {
    setActiveDestination(initialDestination);
  }, [initialDestination]);

  return (
    <Root>
      {showSuccess ? (
        <TransferSuccess
          amountHEDI={etdAmount}
          destinationAddress={activeDestinationAddress}
          txHash={txHash}
        />
      ) : (
        <>
          <Header>
            <Title>Transfer HEDI</Title>
          </Header>
          <Content>
            <TabSelect
              data={data}
              activeIndex={activeDestination}
              onSelected={setActiveDestination}
            />
          </Content>
          <Content>
            <SelectHEDI
              value={etdGroupIdx}
              onChange={setEtdGroupIdx}
              data={etdGroups || []}
            />
            <InputAmount
              token={TokenEnum.HEDI}
              max={activeEtdGroup?.tokens.length}
              onChange={setEtdAmount}
            />
          </Content>
          <Content>
            {activeDestination ? (
              <>
                <Label>Enter BSC address</Label>
                <AddressInput
                  value={destinationAddress}
                  onChange={(event) =>
                    setDestinationAddress(event.target.value)
                  }
                  placeholder={'Paste it here'}
                />
              </>
            ) : (
              <WarningMessage>
                Please don’t transfer tokens before specifying all details with
                manager
              </WarningMessage>
            )}
          </Content>
          <Footer>
            <ConnectButton
              disabled={btnDisabled}
              onClick={onSubmit}
              actionName={btnText}
              pending={pending}
            />
          </Footer>
        </>
      )}
    </Root>
  );
};

export default TransferScreen;
