import React from 'react';
import {
  ChevronWrapper,
  CommonNavWrapper,
  Description,
  FooterBlock,
  HiddenNav,
  LogoItem,
  LogosList,
  MenuItem,
  MenuItemWrapper,
  MobBtn,
  NavBar,
  NavItem,
  NavList,
  NavWrapper,
  NetworkItem,
  NetworksList,
  PrivacyItem,
  PrivacyList,
  Terms,
  TermsWrapper,
  Title,
  TwitterCircle,
} from './Footer.styled';
import { useMediaQuery } from '@mui/material';
import { useCollapse } from 'react-collapsed';

import { ReactComponent as PlusIcon } from 'assets/icons/plus-icon.svg';
import { ReactComponent as HackenAiLogo } from 'assets/icons/hacken-ai-logo.svg';
import { ReactComponent as TrustArmyLogo } from 'assets/icons/trust-army-logo.svg';
import { ReactComponent as ExtractorLogo } from 'assets/icons/extractor-logo.svg';
import { ReactComponent as HackenProofLogo } from 'assets/icons/hacken-proof-logo.svg';
import { ReactComponent as TwitterIcon } from 'assets/icons/twitter-logo.svg';
import { ReactComponent as LinkedinIcon } from 'assets/icons/linkedin-icon.svg';
import { ReactComponent as TelegramIcon } from 'assets/icons/telegram-icon.svg';
import { ReactComponent as DiscordIcon } from 'assets/icons/discord-icon.svg';
import { linkIsExternal } from '../../../utils/misc';
import useLayoutData from '../../../hooks/useLayoutData';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  const isSmallScreen = useMediaQuery('(max-width:767px)');
  const { data: layoutData } = useLayoutData();

  const solutions = layoutData?.acf?.['smart_contract_audit_solutions'];
  const services = layoutData?.acf?.services;
  const caseStudies = layoutData?.acf?.['case_studies'];
  const resources = layoutData?.acf?.resources;
  const company = layoutData?.acf?.company;
  const termsAndPrivacy = layoutData?.acf?.['terms_and_privacy'];
  const copyright = layoutData?.acf?.copyright;

  const {
    getCollapseProps: getCollapseProps1,
    getToggleProps: getToggleProps1,
  } = useCollapse({
    defaultExpanded: true,
  });
  const {
    getCollapseProps: getCollapseProps2,
    getToggleProps: getToggleProps2,
  } = useCollapse({
    defaultExpanded: true,
  });
  const {
    getCollapseProps: getCollapseProps3,
    getToggleProps: getToggleProps3,
  } = useCollapse({
    defaultExpanded: true,
  });
  const {
    getCollapseProps: getCollapseProps4,
    getToggleProps: getToggleProps4,
  } = useCollapse({
    defaultExpanded: true,
  });
  const {
    getCollapseProps: getCollapseProps5,
    getToggleProps: getToggleProps5,
  } = useCollapse({
    defaultExpanded: true,
  });

  return (
    <FooterBlock>
      <NavBar>
        <NavWrapper>
          <CommonNavWrapper>
            <ChevronWrapper {...(isSmallScreen ? getToggleProps1() : {})}>
              <Title>{solutions?.name}</Title>
              <MobBtn aria-label='show Smart contract audit solutions'>
                <PlusIcon />
              </MobBtn>
            </ChevronWrapper>
            <HiddenNav {...getCollapseProps1()}>
              <NavList>
                {(solutions?.list || []).map((item, index) => (
                  <NavItem key={`${item.link.url}-${index}`}>
                    <Link to={`https://hacken.io${item.link.url}` || '/'}>
                      {item.link.title}
                    </Link>
                  </NavItem>
                ))}
              </NavList>
            </HiddenNav>
          </CommonNavWrapper>

          <CommonNavWrapper>
            <MenuItemWrapper>
              <MenuItem>
                <ChevronWrapper {...(isSmallScreen ? getToggleProps2() : {})}>
                  <Title>{services?.name || 'Services'}</Title>
                  <MobBtn aria-label='show Services'>
                    <PlusIcon />
                  </MobBtn>
                </ChevronWrapper>
                <HiddenNav {...getCollapseProps2()}>
                  <NavList>
                    {(services?.list || []).map((item, index) => (
                      <NavItem key={`${item.link.url}-${index}`}>
                        <Link to={`https://hacken.io${item.link.url}` || '/'}>
                          {item.link.title}
                        </Link>
                      </NavItem>
                    ))}
                  </NavList>
                </HiddenNav>
              </MenuItem>

              <MenuItem>
                <ChevronWrapper {...(isSmallScreen ? getToggleProps3() : {})}>
                  <Title>{caseStudies?.name || 'Case Studies'}</Title>
                  <MobBtn aria-label='show Case Studies'>
                    <PlusIcon />
                  </MobBtn>
                </ChevronWrapper>
                <HiddenNav {...getCollapseProps3()}>
                  <NavList>
                    {(caseStudies?.list || []).map((item, index) => (
                      <NavItem key={`${item.link.url}-${index}`}>
                        {linkIsExternal(item.link.url || '/') ? (
                          <Link
                            to={item.link.url || '/'}
                            target='_blank'
                            rel='noreferrer'
                          >
                            {item.link.title}
                          </Link>
                        ) : (
                          <Link to={`https://hacken.io${item.link.url}` || '/'}>
                            {item.link.title}
                          </Link>
                        )}
                      </NavItem>
                    ))}
                  </NavList>
                </HiddenNav>
              </MenuItem>
            </MenuItemWrapper>

            <MenuItemWrapper>
              <MenuItem>
                <ChevronWrapper {...(isSmallScreen ? getToggleProps4() : {})}>
                  <Title>{resources?.name || 'Resources'}</Title>
                  <MobBtn aria-label='show Resources'>
                    <PlusIcon />
                  </MobBtn>
                </ChevronWrapper>

                <HiddenNav {...getCollapseProps4()}>
                  <NavList>
                    {(resources?.list || []).map((item, index) => (
                      <NavItem key={`${item.link.url}-${index}`}>
                        <Link to={`https://hacken.io${item.link.url}` || '/'}>
                          {item.link.title}
                        </Link>
                      </NavItem>
                    ))}
                  </NavList>
                </HiddenNav>
              </MenuItem>

              <MenuItem>
                <ChevronWrapper {...(isSmallScreen ? getToggleProps5() : {})}>
                  <Title>{company?.name || 'Company'}</Title>
                  <MobBtn aria-label='show Company'>
                    <PlusIcon />
                  </MobBtn>
                </ChevronWrapper>

                <HiddenNav {...getCollapseProps5()}>
                  <NavList>
                    {(company?.list || []).map((item, index) => (
                      <NavItem key={`${item.link.url}-${index}`}>
                        {linkIsExternal(item.link.url || '#') ? (
                          <Link
                            to={item.link.url || '/'}
                            target='_blank'
                            rel='noreferrer'
                          >
                            {item.link.title}
                          </Link>
                        ) : (
                          <Link to={`https://hacken.io${item.link.url}` || '/'}>
                            {item.link.title}
                          </Link>
                        )}
                      </NavItem>
                    ))}
                  </NavList>
                </HiddenNav>
              </MenuItem>
            </MenuItemWrapper>
          </CommonNavWrapper>

          <TermsWrapper>
            <Terms>
              <Description>{copyright}</Description>
              <PrivacyList>
                {(termsAndPrivacy?.list || []).map((item, index) => (
                  <PrivacyItem key={`${item.link.url}-${index}`}>
                    <Link
                      to={item.link.url || '/'}
                      target='_blank'
                      rel='noopener noreferrer nofollow'
                    >
                      {item.link.title}
                    </Link>
                  </PrivacyItem>
                ))}
              </PrivacyList>
            </Terms>

            <NetworksList>
              <NetworkItem>
                <Link
                  to={layoutData?.acf['twitter-link'] || '/'}
                  target='_blank'
                  rel='noreferrer'
                  aria-label={'hacken twitter'}
                >
                  <TwitterCircle>
                    <TwitterIcon />
                  </TwitterCircle>
                </Link>
              </NetworkItem>
              <NetworkItem>
                <Link
                  to={layoutData?.acf['telegram-link'] || '/'}
                  target='_blank'
                  rel='noreferrer'
                  aria-label={'hacken telegram'}
                >
                  <TelegramIcon />
                </Link>
              </NetworkItem>
              <NetworkItem>
                <Link
                  to={layoutData?.acf['discord-link'] || '/'}
                  target='_blank'
                  rel='noreferrer'
                  aria-label={'hacken discord'}
                >
                  <DiscordIcon />
                </Link>
              </NetworkItem>
              <NetworkItem>
                <Link
                  to={layoutData?.acf['telegram-link_copy'] || '/'}
                  rel='noreferrer'
                  target='_blank'
                  aria-label={'hacken linkedin'}
                >
                  <LinkedinIcon />
                </Link>
              </NetworkItem>
            </NetworksList>
          </TermsWrapper>
        </NavWrapper>

        <LogosList>
          <LogoItem>
            <Link
              to='https://hacken.ai/'
              rel='noreferrer'
              target='_blank'
              aria-label={'HackenAi link'}
            >
              <HackenAiLogo />
            </Link>
          </LogoItem>
          <LogoItem>
            <Link
              to='https://trustarmy.com/'
              rel='noreferrer'
              target='_blank'
              aria-label={'TrustArmy link'}
            >
              <TrustArmyLogo />
            </Link>
          </LogoItem>
          <LogoItem>
            <Link
              to='https://extractor.hacken.io/'
              rel='noreferrer'
              target='_blank'
              aria-label={'Extractor link'}
            >
              <ExtractorLogo />
            </Link>
          </LogoItem>
          <LogoItem>
            <Link
              to='https://hackenproof.com/'
              rel='noreferrer'
              target='_blank'
              aria-label={'HackenProof link'}
            >
              <HackenProofLogo />
            </Link>
          </LogoItem>
        </LogosList>
      </NavBar>
    </FooterBlock>
  );
};

export default Footer;
