import React from 'react';
import {
  EmptyText,
  Footer,
  ImageWrapper,
  SuccessContent,
  SuccessTitle,
  SwapButton,
} from './SwapForm.styled';
import { formatToken } from '../../../utils/formatter';
import { TokenEnum } from '../../../types/enum/token';
import SuccessImg from '../../../assets/images/checkmark.png';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface Props {
  amountHAI: string; // wei
  amountHEDI: string;
}

const SwapSuccess: React.FC<Props> = ({ amountHAI, amountHEDI }) => {
  return (
    <>
      <SuccessContent>
        <ImageWrapper>
          <LazyLoadImage src={SuccessImg} alt={'Swapped'} />
        </ImageWrapper>
        <SuccessTitle>You’ve Swapped {amountHEDI} HEDI</SuccessTitle>
        <EmptyText>
          {
            formatToken(amountHAI, TokenEnum.HAI, {
              decimals: 0,
            }) as string
          }{' '}
          HAI were added to your wallet
        </EmptyText>
      </SuccessContent>
      <Footer>
        <Link to={'/'}>
          <SwapButton>Back to main page</SwapButton>
        </Link>
      </Footer>
    </>
  );
};

export default SwapSuccess;
