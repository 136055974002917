import styled from '@emotion/styled';
import { parseStyledOptions } from '../../utils/styled';
import { colors } from '../../utils/colors';
import {
  H2,
  H2Positioned,
  Paragraph,
  SecTitle,
  SubParagraph,
} from '../SectionTypography/styled';
import { Container } from '../SectionHowItWorks/SectionHowItWorks.styled';
import { Wrapper as WrapperCommon } from 'styles/Global.styled';
import { FONT_FAMILY } from '../../utils/consts';

export const Section = styled(
  'section',
  parseStyledOptions({ label: 'SectionHAIValue-Section' })
)`
  background: ${colors.accentGreen};
`;
export const Title = styled(
  H2Positioned,
  parseStyledOptions({ label: 'SectionHAIValue-H2' })
)`
  padding: 48px 16px 0;
  text-align: center;

  @media screen and (min-width: 768px) {
    padding: 96px 96px 0;
  }
`;
export const SubTitle = styled(
  SubParagraph,
  parseStyledOptions({ label: 'SectionHAIValue-SubTitle' })
)`
  text-align: center;
  padding: 0 16px 48px;

  @media screen and (min-width: 768px) {
    padding: 0 96px 96px;
  }
`;

export const SectionContainer = styled(
  'div',
  parseStyledOptions({ label: 'SectionHAIValue-SectionContainer' })
)`
  border-top: 1px solid ${colors.accentLightGreen};
  border-bottom: 1px solid ${colors.accentLightGreen};
`;

export const Wrapper = styled(
  Container,
  parseStyledOptions({ label: 'SectionHAIValue-Wrapper' })
)`
  display: flex;
  padding: 40px 16px 48px;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  @media screen and (min-width: 768px) {
    padding: 48px 96px;
  }

  @media screen and (min-width: 1025px) {
    gap: 20px;
    flex-direction: row;
    max-width: 1440px;
    margin: 0 auto;
  }
`;
export const ValueListWrapper = styled(
  WrapperCommon,
  parseStyledOptions({ label: 'SectionHAIValue-ValueListWrapper' })
)`
  padding: 52px 32px 48px 32px;

  @media screen and (min-width: 768px) {
    padding: 52px 96px 48px 96px;
  }
`;
export const ValueList = styled(
  'ul',
  parseStyledOptions({ label: 'SectionHAIValue-ValueList' })
)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;

  @media screen and (min-width: 480px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;
export const ValueItem = styled(
  'li',
  parseStyledOptions({ label: 'SectionHAIValue-ValueItem' })
)`
  @media screen and (min-width: 480px) {
    margin-bottom: 0;
    width: calc(100% / 2 - 10px);
  }

  @media screen and (min-width: 900px) {
    width: calc(100% / 3 - 15px);
  }
`;

export const ValueTitle = styled(
  'p',
  parseStyledOptions({ label: 'SectionHAIValue-ValueTitle' })
)`
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
  margin-top: 16px;
  color: ${colors.black};
`;

export const WrapperSteps = styled(
  Wrapper,
  parseStyledOptions({ label: 'SectionHAIValue-WrapperSteps' })
)`
  gap: 20px;
  align-items: center;

  @media screen and (min-width: 768px) {
    gap: 26px 48px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

export const StepItem = styled(
  'div',
  parseStyledOptions({ label: 'SectionHAIValue-StepItem' })
)`
  // 3 per row
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  @media screen and (min-width: 768px) {
    flex: 1 0 calc(33% - 48px);
  }
`;
export const StepImgWrapper = styled(
  'div',
  parseStyledOptions({ label: 'SectionHAIValue-StepImgWrapper' })
)`
  min-width: 150px;
  max-width: 150px;
  height: 160px;

  @media screen and (min-width: 1024px) {
    min-width: 192px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
export const StepContent = styled(
  'div',
  parseStyledOptions({ label: 'SectionHAIValue-StepContent' })
)`
  flex: 1;
`;
export const StepNumber = styled(
  SecTitle.withComponent('p'),
  parseStyledOptions({ label: 'SectionHAIValue-StepTitle' })
)`
  margin-bottom: 16px;
`;
export const StepText = styled(
  Paragraph,
  parseStyledOptions({ label: 'SectionHAIValue-StepTitle' })
)``;

export const LearnSection = styled(
  SectionContainer,
  parseStyledOptions({ label: 'SectionHAIValue-LearnSection' })
)`
  border-top: none;

  & > div {
    justify-content: center;
  }
`;

export const ImpactWrapper = styled(
  Wrapper,
  parseStyledOptions({ label: 'SectionHAIValue-ImpactWrapper' })
)`
  @media screen and (min-width: 768px) {
    flex-direction: row;
    gap: 16px;
  }
`;

export const ImpactItem = styled(
  'div',
  parseStyledOptions({ label: 'SectionHAIValue-ImpactItem' })
)`
  display: flex;
  align-items: center;
`;

export const ImpactImgWrapper = styled(
  'div',
  parseStyledOptions({ label: 'SectionHAIValue-ImpactImgWrapper' })
)`
  min-width: 180px;
  max-width: 180px;
  margin-right: 16px;
  @media screen and (min-width: 1024px) {
    min-width: 265px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const ImpactContent = styled(
  'p',
  parseStyledOptions({ label: 'SectionHAIValue-ImpactContent' })
)`
  font-size: 18px;
  line-height: 26px;
  font-family: ${FONT_FAMILY.secondary};
  font-weight: 300;
  color: ${colors.black};
`;

export const ImpactDescription = styled(
  SubTitle,
  parseStyledOptions({ label: 'SectionHAIValue-ImpactDescription' })
)`
  font-size: 28px;
  line-height: 40px;
`;
