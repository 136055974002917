import { Components } from '@mui/material';

import { colors } from 'utils/colors';

export const MuiFormControlLabelTheme: Components['MuiFormControlLabel'] = {
  styleOverrides: {
    root: {
      margin: 0,
      gap: '8px',
    },
    label: {
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '20px',
      color: colors.black,
    },
  },
};
