import {
  ADDRESS_SETS,
  CHAIN_INFO,
  ConnectionType,
  MAINNET_CHAIN_ID,
  PRIORITIZED_CONNECTORS,
  TEST_CHAIN_ID,
  WALLET_STATUS_KEY,
} from './config';
import { AddEthereumChainParameter, Connector } from '@web3-react/types';
import { TokenEnum, WalletStatus } from '../types/enum/token';

export const chainIdtoHEX = (chainId: number) => {
  switch (chainId) {
    case MAINNET_CHAIN_ID:
      return '0x38';
    case TEST_CHAIN_ID:
      return '0x61';
    default:
      return '0x38';
  }
};

export function getConnection(c: Connector | ConnectionType) {
  if (c instanceof Connector) {
    const connection = Object.values(PRIORITIZED_CONNECTORS).find(
      (connection) => connection.connector === c
    );
    if (!connection) {
      throw Error('Unsupported Connector');
    }
    return connection;
  } else {
    switch (c) {
      case ConnectionType.INJECTED:
        return PRIORITIZED_CONNECTORS[ConnectionType.INJECTED];
      // case ConnectionType.COINBASE_WALLET:
      //   return PRIORITIZED_CONNECTORS[ConnectionType.COINBASE_WALLET];
      // case ConnectionType.WALLET_CONNECT:
      //   return PRIORITIZED_CONNECTORS[ConnectionType.WALLET_CONNECT]
      // case ConnectionType.GNOSIS_SAFE:
      //   return PRIORITIZED_CONNECTORS[ConnectionType.GNOSIS_SAFE]
      // case ConnectionType.NETWORK:
      //   return PRIORITIZED_CONNECTORS[ConnectionType.NETWORK]
    }
  }
}

export const switchNetwork = async (
  chainId: number,
  connectionType: ConnectionType | null
) => {
  if (!connectionType) {
    return;
  }

  const { connector } = getConnection(connectionType);

  // if (connectionType === ConnectionType.WALLET_CONNECT || connectionType === ConnectionType.NETWORK) {
  //   await connector.activate(chainId)
  //   return
  // }

  const chainInfo = CHAIN_INFO[chainId];
  const addChainParameter: AddEthereumChainParameter = {
    chainId,
    chainName: chainInfo.label,
    rpcUrls: [chainInfo.rpcUrl],
    nativeCurrency: chainInfo.nativeCurrency,
    blockExplorerUrls: [chainInfo.explorer],
  };
  try {
    await connector.activate(addChainParameter);
  } catch (error) {
    console.error(error);
  }
};

export const tryActivateConnector = async (
  connector: Connector
): Promise<ConnectionType | undefined> => {
  try {
    await connector.activate();
    localStorage.setItem(WALLET_STATUS_KEY, WalletStatus.connected);
    const connectionType = getConnection(connector).type;
    return connectionType;
  } catch (error) {
    console.error(error);
    return;
  }
};

export const tryDeactivateConnector = async (
  connector: Connector
): Promise<null | undefined> => {
  connector.deactivate?.();
  connector.resetState();
  localStorage.setItem(WALLET_STATUS_KEY, WalletStatus.disconnected);
  return null;
};

export const getTokenByAddress = (address?: string, chainId?: number) => {
  if (!address || !chainId) {
    return;
  }
  const set = ADDRESS_SETS[chainId];
  const token = Object.keys(set).find(
    (key) => set[key].toLowerCase() === address.toLowerCase()
  );
  return token as TokenEnum;
};
