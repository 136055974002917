import React from 'react';
import { Web3ReactProvider } from '@web3-react/core';
import { PRIORITIZED_CONNECTORS } from '../config';
import useEagerlyConnect from '../hooks/useConnect';

interface Web3ContextProviderProps {
  children: any;
}

const Web3ContextProvider: React.FC<Web3ContextProviderProps> = ({
  children,
}) => {
  useEagerlyConnect();
  return (
    <Web3ReactProvider
      connectors={Object.values(PRIORITIZED_CONNECTORS).map((connector) => [
        connector.connector,
        connector.hooks,
      ])}
    >
      {children}
    </Web3ReactProvider>
  );
};

export default Web3ContextProvider;
