import { Components } from '@mui/material';

import { colors } from 'utils/colors';

export const MuiFormHelperTextTheme: Components['MuiFormHelperText'] = {
  styleOverrides: {
    root: ({ ownerState }) => ({
      marginTop: '6px',
      padding: '0 16px',
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '18px',
      color: ownerState.error ? colors.red : colors.black,
    }),
  },
};
