import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetchLayoutData } from '../api';
import { MainLayoutData } from '../types';

export const DEFAULT_LAYOUT_DATA: MainLayoutData = {
  acf: {
    'header-menu': [],
    'twitter-link': 'https://twitter.com/hackenclub',
    'telegram-link': 'https://t.me/hackenai',
    'discord-link': 'https://discord.gg/R2rP5sr5kX',
    'telegram-link_copy': 'https://www.linkedin.com/company/hacken/',
    smart_contract_audit_solutions: {
      name: 'Smart Contract Audit Solutions',
      list: [
        {
          link: {
            title: 'Solidity',
            url: 'https://hacken.io/services/blockchain-security/solidity-smart-contract-security-audit/',
          },
        },
        {
          link: {
            title: 'Rust',
            url: 'https://hacken.io/services/blockchain-security/rust-smart-contract-security-audit/',
          },
        },
        {
          link: {
            title: 'Move',
            url: 'https://hacken.io/services/blockchain-security/move-smart-contract-audit/',
          },
        },
        {
          link: {
            title: 'Solana',
            url: 'https://hacken.io/services/blockchain-security/solana-smart-contract-security-audit/',
          },
        },
        {
          link: {
            title: 'Near',
            url: 'https://hacken.io/services/blockchain-security/near-smart-contract-security-audit/',
          },
        },
        {
          link: {
            title: 'Polkadot',
            url: 'https://hacken.io/services/blockchain-security/polkadot-smart-contract-security-audit/',
          },
        },
        {
          link: {
            title: 'Cosmos',
            url: 'https://hacken.io/services/blockchain-security/cosmos-smart-contract-security-audit/',
          },
        },
        {
          link: {
            title: 'Optimism',
            url: 'https://hacken.io/services/blockchain-security/optimism-smart-contract-security-audit/',
          },
        },
        {
          link: {
            title: 'Ethereum (EVM)',
            url: 'https://hacken.io/services/blockchain-security/ethereum-smart-contract-security-audit/',
          },
        },
        {
          link: {
            title: 'Avalanche',
            url: 'https://hacken.io/services/blockchain-security/avalanche-smart-contract-security-audit/',
          },
        },
        {
          link: {
            title: 'BSC',
            url: 'https://hacken.io/services/blockchain-security/bsc-smart-contract-audit/',
          },
        },
        {
          link: {
            title: 'Fantom',
            url: 'https://hacken.io/services/blockchain-security/fantom-smart-contract-security-audit/',
          },
        },
        {
          link: {
            title: 'Polygon',
            url: 'https://hacken.io/services/blockchain-security/polygon-smart-contract-security-audit/',
          },
        },
        {
          link: {
            title: 'Aptos',
            url: 'https://hacken.io/services/blockchain-security/aptos-smart-contract-audit/',
          },
        },
        {
          link: {
            title: 'zkSync',
            url: 'https://hacken.io/services/blockchain-security/zksync-smart-contract-audit/',
          },
        },
        {
          link: {
            title: 'Arbitrum',
            url: 'https://hacken.io/services/blockchain-security/arbitrum-smart-contract-audit/',
          },
        },
        {
          link: {
            title: 'Radix',
            url: 'https://hacken.io/services/blockchain-security/radix-smart-contract-audit',
          },
        },
      ],
    },
    services: {
      name: 'Services',
      list: [
        {
          link: {
            title: 'Smart Contract Audit',
            url: 'https://hacken.io/services/blockchain-security/smart-contract-security-audit/',
          },
        },
        {
          link: {
            title: 'Blockchain Protocol Audit',
            url: 'https://hacken.io/services/blockchain-security/blockchain-protocol-security/',
          },
        },
        {
          link: {
            title: 'Blockchain Penetration Testing',
            url: 'https://hacken.io/services/penetrationtesting/',
          },
        },
        {
          link: {
            title: 'dApp Audit',
            url: 'https://hacken.io/services/dapp-audit/',
          },
        },
        {
          link: {
            title: 'Bug Bounty',
            url: 'https://hacken.io/services/bug-bounty/',
          },
        },
        {
          link: {
            title: 'Proof of Reserves',
            url: 'https://hacken.io/services/proof-of-reserves-audit/',
          },
        },
      ],
    },
    case_studies: {
      name: 'Case Studies',
      list: [
        {
          link: {
            title: 'Audit reports',
            url: 'https://hacken.io/audits',
          },
        },
        {
          link: {
            title: 'All Case Studies',
            url: 'https://hacken.io/category/case-studies',
          },
        },
      ],
    },
    resources: {
      name: 'Resources',
      list: [
        {
          link: {
            title: 'Discover',
            url: 'https://hacken.io/category/discover/',
          },
        },
        {
          link: {
            title: 'Insights',
            url: 'https://hacken.io/category/insights/',
          },
        },
        {
          link: {
            title: 'Industry news',
            url: 'https://hacken.io/category/industry-news-and-insights/',
          },
        },
        {
          link: {
            title: 'Network',
            url: 'https://hacken.io/category/network/',
          },
        },
        {
          link: {
            title: 'Hacken news',
            url: 'https://hacken.io/category/hacken-news/',
          },
        },
      ],
    },
    company: {
      name: 'Company',
      list: [
        {
          link: {
            title: 'About',
            url: 'https://hacken.io/about/',
          },
        },
        {
          link: {
            title: 'Careers',
            url: 'https://hacken.peopleforce.io/careers',
          },
        },
        {
          link: {
            title: 'Brand Assets',
            url: 'https://hackenio.cc/brand-assets',
          },
        },
      ],
    },
    terms_and_privacy: {
      name: '',
      list: [
        {
          link: {
            title: 'Terms and Conditions',
            url: 'https://hackenio.cc/terms-of-service',
          },
        },
        {
          link: {
            title: 'Privacy Policy',
            url: 'https://hackenio.cc/privacy-notice',
          },
        },
      ],
    },
    copyright: '© 2023 Hacken.io All rights reserved.',
  },
};
export default function useLayoutData(
  options?: UseQueryOptions<MainLayoutData>
) {
  return useQuery<MainLayoutData>({
    queryKey: ['layoutData'],
    queryFn: () => fetchLayoutData(),
    initialData: DEFAULT_LAYOUT_DATA,
    placeholderData: DEFAULT_LAYOUT_DATA,
    ...options,
  });
}
