import { useWeb3React } from '@web3-react/core';
import { getContractToken, getHEDIContract } from '../contract';
import { ADDRESS_SETS } from '../config';
import { TokenEnum } from '../../types/enum/token';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { approve, approveHEDI } from '../actions';

export function useAllowanceMutation(token: TokenEnum) {
  const { chainId, account, provider } = useWeb3React();
  const queryClient = useQueryClient();

  const addressSet = chainId ? ADDRESS_SETS[chainId] : null;
  const address = addressSet?.[token];
  const spenderAddress = chainId ? ADDRESS_SETS[chainId]?.['exchange'] : null;
  return useMutation({
    mutationFn: async (allowance: string) => {
      if (!provider) throw new Error('No provider');
      if (!address) throw new Error('No token address');
      if (!spenderAddress) throw new Error('No spender address');
      if (!chainId) throw new Error('No spender chain Id');

      try {
        const tx =
          token === TokenEnum.HEDI
            ? await approveHEDI({
                chainId,
                provider,
                spenderAddress,
              })
            : await approve({
                chainId,
                provider,
                token,
                allowance,
                spenderAddress,
              });
        await tx.wait();
        return allowance;
      } catch (error: any) {
        console.error('Error useAllowanceMutation mutate:', error);
        throw new Error(`${error.message}`);
      }
    },
    onSuccess: (allowance: string) => {
      queryClient.setQueryData(
        ['allowance', address, spenderAddress, account],
        allowance
      );
    },
  });
}

export default function useAllowance(
  { token, spender = 'exchange' }: { token: TokenEnum; spender?: 'exchange' },
  options?: UseQueryOptions<string>
) {
  const { chainId, provider, account } = useWeb3React();
  const queryClient = useQueryClient();

  const addressSet = chainId ? ADDRESS_SETS[chainId] : null;
  const address = addressSet?.[token];
  const spenderAddress = chainId ? ADDRESS_SETS[chainId]?.[spender] : null;
  return useQuery<string>({
    queryKey: ['allowance', address, spenderAddress, account],
    queryFn: async () => {
      if (!provider) throw new Error('No provider');
      if (!address) throw new Error('No token address');
      if (!spenderAddress) throw new Error('No spender address');
      if (!account) throw new Error('No account');
      try {
        let allowance;
        if (token === TokenEnum.HEDI) {
          const isApproved = await getHEDIContract(
            address,
            provider
          ).isApprovedForAll(account, spenderAddress);
          if (isApproved) {
            return (
              (await queryClient.getQueryData(['balance', address, account])) ||
              '0'
            );
          }
          return '0';
        } else {
          allowance = await getContractToken(address, provider).allowance(
            account,
            spenderAddress
          );
        }

        if (!allowance) {
          throw new Error('No allowance');
        }
        return allowance.toString();
      } catch (error) {
        throw new Error(error);
      }
    },
    enabled: !!provider && !!account && !!address,
    refetchOnMount: true,
    ...options,
  });
}
