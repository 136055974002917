import axios from 'axios';

const axiosCMS = axios.create({
  baseURL: process.env.REACT_APP_CMS_API_URL,
  headers: {
    'api-key': process.env.REACT_APP_CMS_API_KEY,
    'Content-Type': 'application/json',
  },
});
const axiosPipedrive = axios.create({
  baseURL: process.env.REACT_APP_PIPEDRIVE_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const axiosPipedriveProxy = axios.create({
  baseURL: process.env.REACT_APP_PIPEDRIVE_PROXY_API_URL,
  headers: {
    Authorization: process.env.REACT_APP_PIPEDRIVE_PROXY_API_KEY,
    'Content-Type': 'application/json',
  },
});

export { axiosCMS, axiosPipedrive, axiosPipedriveProxy };
