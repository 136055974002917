import React from 'react';
import {
  SectionContainer,
  SubTitle,
  Title,
  Wrapper,
} from './SectionWhyNeed.styled';
import {
  WhyNeedItem,
  WhyNeedItemContent,
  WhyNeedList,
  WhyNeedDescription,
} from './SectionWhyNeed.styled';
import { ReactComponent as BoostIcon } from 'assets/icons/boost.svg';
import { ReactComponent as ChainIcon } from 'assets/icons/web3.svg';
import { ReactComponent as ChainCIcon } from 'assets/icons/chain-connect.svg';
import { ReactComponent as HomeIcon } from 'assets/icons/home.svg';
import { ReactComponent as VCIcon } from 'assets/icons/vc.svg';

const needsList = [
  {
    text: 'VC Firms',
    icon: VCIcon,
    description:
      'Stable Investment, Fixed Services. Invest confidently in a stable, low-risk environment with HEDI, gaining exposure to volatile $HAI. Guarantee fixed services through re-investment in your portfolio companies for stable returns.',
  },
  {
    text: 'Web3 Accelerators',
    icon: HomeIcon,
    description:
      'Profit or Growth Fuel. Embrace HEDIs dual potential. Accelerators can profit from $HAI growth or strategically reinvest in incubated projects as Hacken Services, enhancing growth opportunities.',
  },
  {
    text: 'L1 Protocols',
    icon: ChainCIcon,
    description:
      'Boost DAPP Security or Secure Profits. Leverage HEDI to enhance DAPP security through Hacken Services. Alternatively, secure fixed profits by guiding affiliated projects to benefit from guaranteed discounts and priority service.',
  },
  {
    text: 'Web3 Companies Requiring Numerous Audits',
    icon: ChainIcon,
    description:
      'Prepaid Security Instrument. Use HEDI as a prepaid instrument for web3 security services. Prepay for Hacken Services and, at any time, access the needed services or opt for fixed profits, offering flexibility and assurance.',
  },
  {
    text: 'Believers in Web3 Security Industry',
    icon: BoostIcon,
    description:
      'Low-Risk, Maximum Flexibility. HEDI is a low-risk instrument for believers in the Web3 Security Industry.\nBacked by Hacken Services, it offers exposure to HAI token dynamics with the flexibility to resell or reinvest in web3 projects at any time.',
  },
];
const SectionWhyNeed: React.FC = () => {
  return (
    <>
      <Title>Unlocking Opportunities with HEDI</Title>
      <SubTitle>
        HEDI is an instrument to transform +1,000 Hacken clients and partners
        into a network that financially benefits from Hacken web3 security
        business growth.
      </SubTitle>
      <SectionContainer>
        <Wrapper>
          <WhyNeedList>
            {needsList.map((need, idx) => (
              <WhyNeedItem key={idx}>
                <WhyNeedItemContent>
                  <need.icon />
                  <span>{need.text}</span>
                </WhyNeedItemContent>
                <WhyNeedDescription>{need.description}</WhyNeedDescription>
              </WhyNeedItem>
            ))}
          </WhyNeedList>
        </Wrapper>
      </SectionContainer>
    </>
  );
};

export default SectionWhyNeed;
