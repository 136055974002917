import styled from '@emotion/styled';
import { parseStyledOptions } from '../../utils/styled';
import { colors } from '../../utils/colors';
import { Button } from '@mui/material';

interface TabProps {
  $hasTitle?: boolean;
  $active?: boolean;
}

export const Root = styled(
  'div',
  parseStyledOptions({ label: 'TabSelect-Root' })
)`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Tab = styled(
  Button,
  parseStyledOptions({ label: 'TabSelect-Tab' })
)<TabProps>`
  padding: ${({ $hasTitle }) => ($hasTitle ? '8px 16px' : '12px 16px')};
  text-align: center;
  font-weight: 600;
  flex: 1;
  flex-direction: column;
  color: ${({ $active }) => ($active ? colors.white : colors.black)};
  background: ${({ $active }) => ($active ? colors.black : 'transparent')};
  border: solid 1px ${colors.black};

  &:hover {
    background: ${({ $active }) =>
      $active ? colors.black : colors.extraLightGray};
  }
  &:active {
    background: ${({ $active }) =>
      $active ? colors.black : colors.extraLightGray};
  }
`;

export const Label = styled(
  'p',
  parseStyledOptions({ label: 'TabSelect-Label' })
)`
  font-size: 12px;
  line-height: 12px;
`;

export const Title = styled(
  'p',
  parseStyledOptions({ label: 'TabSelect-SubLabel' })
)`
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 4px;
`;
