import * as yup from 'yup';
import { PlatformNames } from 'queries/person';
import { EMAIL_DOMAINS_BLACK_LIST } from './emailsBlackList';
export const corporateEmailValidationSchema = yup
  .string()
  .test(function (value: string) {
    const [, emailDomain] = value.split('@');

    if (EMAIL_DOMAINS_BLACK_LIST.includes(emailDomain))
      return this.createError({
        message: 'Please enter your corporate email',
        path: 'email',
      });
    if (emailDomain && !emailDomain.includes('.')) {
      return this.createError({
        message: 'Please enter valid email!',
        path: 'email',
      });
    }
    return true;
  })
  .email('Please enter valid email!')
  .required('Please enter your corporate email');

export const requestQuoteValidationSchema = yup.object({
  name: yup.string().required('Please enter your name'),
  platformName: yup.string(),
  platformNickname: yup.string().test(function (value) {
    const platformName = this.resolve(yup.ref('platformName'));

    if (platformName === PlatformNames.whatsApp && !value) {
      return this.createError({
        message: 'Please enter your phone number',
        path: 'platformNickname',
      });
    }

    if (!value) {
      return this.createError({
        message: 'Please enter your username',
        path: 'platformNickname',
      });
    }

    return true;
  }),

  service: yup.array().min(1, 'Please select a service'),
  email: corporateEmailValidationSchema,
  details: yup.string().required('Please enter your project details'),
  policyAgreement: yup.boolean().oneOf([true], '(Required!)'),
});
