import { BigNumberish, ethers } from 'ethers';
import BigNumber from 'bignumber.js';
import { TokenEnum } from '../types/enum/token';
import { type } from '@testing-library/user-event/dist/type';
import RoundingMode = BigNumber.RoundingMode;

const defaultFormat: BigNumber.Format = {
  decimalSeparator: '.',
  groupSeparator: ' ',
  groupSize: 3,
  secondaryGroupSize: 0,
  fractionGroupSeparator: ' ',
  fractionGroupSize: 0,
};

export const HAI_DECIMALS = 8;

export const tokenName = (token: TokenEnum) => {
  switch (token) {
    case TokenEnum.HAI:
      return 'HAI';
    case TokenEnum.USDT:
      return 'USDT';
    case TokenEnum.BNB:
      return 'BNB';
    case TokenEnum.HEDI:
      return 'HEDI';
  }
};

export const parseToken = (amount: string | undefined, token: TokenEnum) => {
  switch (token) {
    case TokenEnum.HAI:
      return ethers.utils.parseUnits(amount || '0', HAI_DECIMALS).toString();
    default:
      return ethers.utils.parseUnits(amount || '0').toString();
  }
};

export const formatToken = (
  amount: BigNumberish | undefined | null,
  token: TokenEnum,
  options?: {
    format?: BigNumber.Format;
    decimals?: number;
    round?: RoundingMode;
    returnBN?: boolean;
  }
) => {
  const format = {
    ...defaultFormat,
    ...options?.format,
  };
  let formattedUnits = '0';
  switch (token) {
    case TokenEnum.HAI:
      formattedUnits = ethers.utils.formatUnits(amount || 0, HAI_DECIMALS);
      break;
    case TokenEnum.USDT:
    case TokenEnum.BNB:
    case TokenEnum.HEDI:
      formattedUnits = ethers.utils.formatUnits(amount || 0);
      break;
  }

  const bn = new BigNumber(formattedUnits);

  return options?.returnBN
    ? bn
    : bn
        .decimalPlaces(
          typeof options?.decimals === 'undefined' ? 6 : options?.decimals,
          options?.round
        )
        .toFormat(format);
};
