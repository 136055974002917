import { TokenEnum } from '../types/enum/token';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { fetchHEDIPriceHAI, fetchHEDITokens, fetchHAITokenPrice } from '../api';
import { useWeb3React } from '@web3-react/core';
import { useEffect } from 'react';
import { BigNumberish, ethers } from 'ethers';
import { ADDRESS_SETS } from '../web3/config';
import { getHEDIContract, getTokenExchangeContract } from '../web3/contract';
import { getTokenByAddress } from '../web3';
import { GroupHEDI } from '../types/token';
import { BigNumber } from 'bignumber.js';
import { getBurnPercentage } from '../web3/actions';

export function useHEDIPrice(
  token: TokenEnum.USDT | TokenEnum.HAI,
  enabled: boolean
) {
  const { isActive, account } = useWeb3React();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (account) {
      queryClient
        .prefetchQuery({
          queryKey: ['price-offer', token, account],
          queryFn: () => fetchHEDIPriceHAI(token, account || ''),
        })
        .then();
    }
  }, [account]);

  return useQuery({
    queryKey: ['price-offer', token, account],
    queryFn: () => fetchHEDIPriceHAI(token, account || ''),
    staleTime: 1000 * 60 * 10,
    gcTime: 1000 * 60 * 14,
    refetchInterval: 1000 * 60 * 14 + 1,
    enabled: isActive && enabled,
    refetchOnWindowFocus: true,
  });
}

export function useHEDIActions() {
  const queryClient = useQueryClient();
  const { account, provider, chainId } = useWeb3React();
  const addressSet = chainId ? ADDRESS_SETS[chainId] : null;
  const addressToken = addressSet?.HEDI;
  const addressTE = addressSet?.exchange;
  const transfer = useMutation({
    mutationFn: async ({
      to,
      amount,
    }: {
      to: string;
      amount: BigNumberish[];
    }) => {
      if (!provider) throw new Error('No provider');
      if (!addressToken) throw new Error('No token address');
      if (!account) throw new Error('No account');
      try {
        const contract = getHEDIContract(addressToken, provider);

        // Calc gas price
        const gasPrice = await provider.getGasPrice();
        const gasLimit = await contract.estimateGas.safeTransferFromMultiple(
          account,
          to,
          amount
        );
        const tx = await contract.safeTransferFromMultiple(
          account,
          to,
          amount,
          {
            gasPrice: gasPrice.mul(120).div(100),
            gasLimit: gasLimit.mul(120).div(100),
          }
        );
        await tx.wait();

        return tx;
      } catch (error: Error | any) {
        console.error('Error transfer mutate:', error);
        throw new Error(`Error transfer: ${error.message}`);
      }
    },
    onSuccess: () => {
      queryClient
        .invalidateQueries({ queryKey: ['etd-groups', account] })
        .then();
      queryClient
        .invalidateQueries({ queryKey: ['balance', addressToken, account] })
        .then();
    },
  });
  const sell = useMutation({
    mutationFn: async ({ amount }: { amount: BigNumberish[] }) => {
      if (!provider) throw new Error('No provider');
      if (!addressTE) throw new Error('No token address');
      if (!account) throw new Error('No account');
      try {
        const contract = getTokenExchangeContract(addressTE, provider);

        // Calc gas price
        const gasPrice = await provider.getGasPrice();
        const gasLimit = await contract.estimateGas.sellMultiple(amount);
        const tx = await contract.sellMultiple(amount, {
          gasPrice: gasPrice.mul(120).div(100),
          gasLimit: gasLimit.mul(120).div(100),
        });
        await tx.wait();

        return tx;
      } catch (error: Error | any) {
        console.error('Error sell mutate:', error);
        throw new Error(`Error sell: ${error.message}`);
      }
    },
    onSuccess: () => {
      queryClient
        .invalidateQueries({ queryKey: ['etd-groups', account] })
        .then();
      queryClient
        .invalidateQueries({ queryKey: ['balance', addressToken, account] })
        .then();
    },
  });

  return {
    transfer,
    sell,
  };
}

export function useHEDIGroups() {
  const { account, isActive, chainId } = useWeb3React();
  const groups = useQuery({
    queryKey: ['etd-groups', account],
    queryFn: async () => {
      if (!account) throw new Error('No account');
      try {
        const response = await fetchHEDITokens(account);
        return response;
      } catch (error: Error | any) {
        console.error('Error transfer mutate:', error);
        throw new Error(`Error transfer: ${error.message}`);
      }
    },
    select: (data) => {
      return data.map((group) => {
        const sourceToken = getTokenByAddress(group.sourceToken, chainId);
        return {
          ...group,
          sourceToken,
        } as GroupHEDI;
      });
    },
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    enabled: isActive,
  });

  return groups;
}

export function useHEDIPriceHAI(options?: Omit<UseQueryOptions, 'queryKey'>) {
  return useQuery({
    queryKey: ['price-hai'],
    queryFn: () => fetchHAITokenPrice(),
    staleTime: 1000 * 60 * 10,
    refetchInterval: 1000 * 60 * 10,
    select: (data) => {
      const usdtPrice = process.env.REACT_APP_PRICE_USDT;
      const priceBN = BigNumber(usdtPrice || 0).div(data);

      return ethers.utils.parseUnits(priceBN.toFixed(0), 8).toString();
    },
  });
}

export function useHEDIBurnPercentage(
  options?: Omit<UseQueryOptions, 'queryKey'>
) {
  const { chainId, provider, isActive } = useWeb3React();
  return useQuery({
    queryKey: ['burn-percentage'],
    queryFn: async () => {
      if (!chainId) throw new Error('No chainId');
      if (!provider) throw new Error('No provider');
      return await getBurnPercentage({ chainId, provider });
    },
    select: (data) => {
      return +data.toString() / 100;
    },
    enabled: isActive,
    staleTime: 1000 * 60 * 60 * 24,
  });
}
