import { useCookie } from 'react-use';
import sbjs from 'sourcebuster';

export const useSessionMeta = () => {
  const [_gaCustom] = useCookie('_ga_NG2E4L2YKW');
  const [_ga] = useCookie('_ga');

  return () => {
    const sessionId = !_gaCustom ? '' : _gaCustom.slice(6);
    const gclid = !sbjs.get.current_add.ep
      ? ''
      : !new URL(sbjs.get.current_add.ep).searchParams.get('gclid')
        ? ''
        : new URL(sbjs.get.current_add.ep).searchParams.get('gclid') || '';
    const clientId = !_ga ? '' : _ga.slice(6);

    return { sessionId, gclid, clientId };
  };
};
