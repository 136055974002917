import React, { useMemo } from 'react';
import RootCustom from '../../components/layout/RootCustom';
import TransferForm from '../../components/forms/TransferForm';
import { useSearchParams } from 'react-router-dom';

const TransferScreen: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const initial = useMemo(() => {
    const data = searchParams.get('to');
    if (data) {
      return data === 'hacken' ? 0 : 1;
    }
    return 1;
  }, [searchParams]);

  return (
    <RootCustom>
      <TransferForm initialDestination={initial} />
    </RootCustom>
  );
};

export default TransferScreen;
