import { createTheme } from '@mui/material';

import { FONT_FAMILY } from 'utils/consts';

import { MuiButtonTheme } from './button.theme';
import { MuiButtonBaseTheme } from './buttonBase.theme';
import { MuiLinearProgressTheme } from './progress.theme';
import { MuiPaginationTheme } from './pagination.theme';
import { MuiPaginationItemTheme } from './paginationItem.theme';
import { MuiInputBaseTheme } from './inputBase.theme';
import { MuiInputTheme } from './input.theme';
import { MuiFormHelperTextTheme } from './formHelperText.theme';
import { MuiFormControlLabelTheme } from './formControlLabel.theme';
import { MuiCheckboxTheme } from './checkbox.theme';
import { MuiSelectTheme } from './select.theme';
import { MuiMenuTheme } from './menu.theme';
import { MuiMenuItemTheme } from './menuItem.theme';

export const MUI_THEME = createTheme({
  typography: {
    fontFamily: FONT_FAMILY.primary,
  },
  components: {
    MuiButtonBase: MuiButtonBaseTheme,
    MuiButton: MuiButtonTheme,
    MuiLinearProgress: MuiLinearProgressTheme,
    MuiPagination: MuiPaginationTheme,
    MuiPaginationItem: MuiPaginationItemTheme,
    MuiInputBase: MuiInputBaseTheme,
    MuiInput: MuiInputTheme,
    MuiFormHelperText: MuiFormHelperTextTheme,
    MuiFormControlLabel: MuiFormControlLabelTheme,
    MuiCheckbox: MuiCheckboxTheme,
    MuiSelect: MuiSelectTheme,
    MuiMenu: MuiMenuTheme,
    MuiMenuItem: MuiMenuItemTheme,
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
  },
});
