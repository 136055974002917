import { Components } from '@mui/material';

import { colors } from 'utils/colors';

export const MuiPaginationItemTheme: Components['MuiPaginationItem'] = {
  styleOverrides: {
    root: {
      margin: '0',
      width: '47px',
      height: '41px',
      border: `1px solid ${colors.black}`,
      borderRadius: '0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: '600',
      fontSize: '16px',
      lineHeight: '26px',

      '&.Mui-selected': {
        backgroundColor: colors.black,
        color: '#fff',

        '&:hover': {
          backgroundColor: colors.black,
        },
      },

      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
};
